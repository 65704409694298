// Import es6 polyfills
// import 'babel-polyfill' // done in index
// Import global styles
import './assets/scss/main.scss'
import 'vue-porg/dist/vue-porg.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import PorgPlugin from 'vue-porg'
import store from './store'
import client from '@/api/client'
import { mapState } from 'vuex'
import { i18n, setLocale, getLanguage } from '@/i18n'

Vue.use(PorgPlugin, {
  router,
  store,
  axios: client,
  default2FAChallenge: 'U2FChallenge'
})

Vue.mixin({
  methods: {
    hasRole (role) {
      return this.loggedUser && this.loggedUser.roles && this.loggedUser.roles.includes('admin')
    },
    setLocale,
    getLanguage
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  computed: {
    ...mapState([
      'loggedUser'
    ])
  },
  render (createElement) {
    return createElement(App, {})
  }
})
