<template>
  <div class="modal card">
    <div class="card-row">
      <div class="error-modal card-row__text">
        <i class="icon">
          <svg
            class="error-modal-svg error-icon"
            width="66"
            height="66"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#F9690E"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                class="circle"
                cx="33"
                cy="33"
                r="32"
              />
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  class="line"
                  d="M22.393 43.607l21.214-21.214"
                />
                <path
                  class="line"
                  d="M22.393 22.393l21.214 21.214"
                />
              </g>
            </g>
          </svg>
        </i>

        <h1 class="error-modal__header">
          {{ title }}
        </h1>
        <p class="error-modal__paragraph">
          {{ body }}
        </p>
        <div class="error-modal__actions">
          <button
            class="button blue filled"
            @click.prevent="isModalOpen = false"
          >
            {{ close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ErrorModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    close: {
      type: String,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.error-modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.error-modal__header {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $light-gray;
}

.error-modal__message {
  font-size: 14px;
  margin: 1.5rem 0;
}

.error-modal__actions {
  margin-top: 1.5rem;
  width: 100%;
  button {
    width: 100%;
  }
}
.error-modal-svg {

  .circle {
    animation: error-circle 1s forwards;
    stroke-dasharray: 201;
    stroke-dashoffset: -201;
    transform: rotate(-90deg);
    transform-origin: 50%;
    stroke: $orange;
  }
  @keyframes error-circle {
    0% {
      stroke-dashoffset: -201;
      transform: rotate(180deg);
    }
    100% {
      stroke-dashoffset: 0;
      transform: rotate(0deg);
    }
  }
  .line {
    animation: error-line-first 1s forwards;
    animation-delay: 0.1s;
    &:last-of-type {
      animation: error-line-second 1s forwards;
    }

    stroke-dasharray: 100%;
    stroke-dashoffset: -100%;
    stroke: $orange;
  }
  @keyframes error-line-first {
    0% {
      stroke-dashoffset: -100%;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes error-line-second {
    0% {
      stroke-dashoffset: 100%;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}

</style>
