<template>
  <div
    v-if="!nonEditable"
    ref="datePicker"
    :name="name"
  >
    <div
      @click.stop="toggle"
    >
      <slot
        name="trigger"
        :on-blur="onBlur"
        :disabled="disabled"
        :open-date-picker="openDatePicker"
        :close-date-picker="closeDatePicker"
      >
        <input
          ref="input"
          :value="value"
          :name="name"
          :placeholder="inputPlaceholder"
          :disabled="disabled"
          type="text"
          class="f-field__input"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          @focus="openDatePicker"
          @blur="onBlur"
          @click="openDatePicker"
          @input="$emit('input', $event.target.value)"
          @keyup.enter.prevent="closeDatePicker"
        >
        <label
          v-if="label"
          :for="name"
          :class="{ 'f-field__label--lg-margin': variant === 'outlined' }"
          class="f-field__label"
        >
          {{ label }}
        </label>
      </slot>
    </div>

    <div
      class="date-picker"
      :name="name"
      :class="{ 'open': open }"
    >
      <div class="upper-arrow" />
      <div class="days">
        <header class="date-picker-header">
          <button
            class="pagination left"
            @click.prevent="prevYear"
          />
          <div
            v-if="year"
            class="year-wrapper"
          >
            <h4
              v-if="!isYearBeingEdited"
              :class="{ 'year--editable': isYearBeingEdited }"
              class="year"
              @click.prevent="editYear"
            >
              {{ year }}
            </h4>
            <input
              v-if="isYearBeingEdited"
              v-model="yearBeingEdited"
              type="text"
              class="f-field__input"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              @blur="setEditedYear"
              @keydown.enter.prevent="setEditedYear"
            >
          </div>
          <button
            class="pagination right"
            @click.prevent="nextYear"
          />
        </header>
        <header class="date-picker-header">
          <button
            class="pagination left"
            @click.prevent="prevMonth"
          />
          <h4>{{ month }}</h4>
          <button
            class="pagination right"
            @click.prevent="nextMonth"
          />
        </header>
        <div class="calendar">
          <header>
            <span>{{ $t('weekdays.initials.sunday') }}</span>
            <span>{{ $t('weekdays.initials.monday') }}</span>
            <span>{{ $t('weekdays.initials.tuesday') }}</span>
            <span>{{ $t('weekdays.initials.wednesday') }}</span>
            <span>{{ $t('weekdays.initials.thursday') }}</span>
            <span>{{ $t('weekdays.initials.friday') }}</span>
            <span>{{ $t('weekdays.initials.saturday') }}</span>
          </header>
          <div class="main">
            <div
              v-for="week in weeks"
              :key="generateWeekId(week)"
              class="row"
            >
              <button
                v-for="day in week"
                :key="generateDayId(day)"
                :class="{
                  selected: day.selectedDayEdges && day.isInSelectedMonth,
                  today: day.isToday,
                  disabled: !day.isInSelectedMonth || day.ignoreDay || (day.isBeforeToday && !before),
                  between: !day.ignoreDay && day.selectedDayBetween && day.isInSelectedMonth
                }"
                @click.prevent="select(day)"
                @mouseover="hoveredDate = day.date"
                @mouseleave="hoveredDate = undefined"
              >
                {{ day.dayOfTheMonth }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="time"
        class="hours"
      >
        <div
          v-if="!rangeMode"
          class="hour"
        >
          <label for="time">
            {{ $t('time') }}:
          </label>
          <input
            id="time"
            v-model="selectedTimeStart"
            class="time-picker"
            type="time"
          >
        </div>
        <template v-else-if="rangeMode && time">
          <div class="hour">
            <label for="timeStart">
              {{ $t('timeStart') }}:
            </label>
            <input
              id="timeStart"
              v-model="selectedTimeStart"
              class="time-picker"
              type="time"
            >
          </div>
          <div class="hour">
            <label for="timeEnd">
              {{ $t('timeEnd') }}:
            </label>
            <input
              id="timeEnd"
              v-model="selectedTimeEnd"
              class="time-picker"
              type="time"
            >
          </div>
        </template>
      </div>
      <button
        v-if="time"
        class="btn btn--primary"
        @click="emitResult"
      >
        {{ $t('save') }}
      </button>
    </div>
  </div>
  <div
    v-else
    class="f-field"
  >
    <p>
      {{ value }}
    </p>
    <label
      v-if="label"
      class="f-field__label f-field__label--lg-margin"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { createFocusTrap } from 'focus-trap'
import dayjs from 'dayjs'

const ignoreDay = (ignore, dayTimespan) => {
  const dayMoment = dayjs(dayTimespan)
  for (const i of ignore) {
    if (typeof i === 'number') {
      const ignoreDay = dayjs(i)
      if (ignoreDay.isSame(dayMoment, 'day')) {
        return true
      }
      continue
    }

    if (typeof i === 'object') {
      const ignoreDayStart = dayjs(i.start)
      const ignoreDayEnd = dayjs(i.end)
      if (dayMoment.isBetween(ignoreDayStart, ignoreDayEnd, 'day', '[]')) {
        return true
      }
    }
  }
  return false
}

const initWeeks = (ignore, nowTimespan, selectedTimespan, startTimespan, endTimespan) => {
  const weeks = []
  const nowDate = dayjs(nowTimespan)
  const SelectedDate = dayjs(selectedTimespan)
  let startDate
  let endDate
  if (startTimespan) {
    startDate = dayjs(startTimespan)
    endDate = endTimespan ? dayjs(endTimespan) : dayjs(startTimespan)
  }
  const startMonth = SelectedDate.startOf('month')
  const firstMonthWeekDay = startMonth.day()
  // Subtract the number of days two sunday
  let currentDay = startMonth.subtract(firstMonthWeekDay, 'days')
  for (let n = 0; n < 6; ++n) {
    weeks[n] = []
    for (let m = 0; m < 7; ++m) {
      let selectedDay = false
      let selectedDayEdges = false
      let selectedDayBetween = false
      selectedDayEdges = currentDay.isSame(startDate, 'day') || currentDay.isSame(endDate, 'day')
      if (endDate) {
        selectedDayBetween = currentDay.isBetween(startDate, endDate, 'day', '()')
        selectedDay = selectedDayEdges || selectedDayBetween
      }
      const isBeforeToday = currentDay.isBefore(nowDate, 'day')
      const isAfterToday = currentDay.isAfter(nowDate, 'day')
      const isToday = currentDay.isSame(nowDate, 'day')
      weeks[n][m] = {
        dayOfTheMonth: currentDay.date(),
        selectedDay,
        selectedDayEdges,
        selectedDayBetween,
        ignoreDay: ignoreDay(ignore, currentDay.valueOf()),
        isInSelectedMonth: currentDay.isSame(SelectedDate, 'month'),
        isToday,
        isBeforeToday,
        isAfterToday,
        date: currentDay.valueOf()
      }
      currentDay = currentDay.add(1, 'days')
    }
  }
  return weeks
}

export const DATE_PICKER_FORMAT = 'DD/MM/YYYY'

export default {
  name: 'DatePicker',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      validator: (val) => {
        if (typeof val === 'number') {
          return true
        } else {
          if (typeof val.start === 'number' && typeof val.end === 'number') {
            return true
          }
        }
      },
      default: Date.now().valueOf()
    },
    ignore: {
      type: Array,
      default: function () {
        return []
      }
    },
    format: {
      type: String,
      default: DATE_PICKER_FORMAT
    },
    nonEditable: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rangeMode: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    },
    // Disables days before current day
    before: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      now: undefined,
      startDate: null,
      endDate: null,
      currentMonth: undefined,
      weeks: undefined,
      open: false,
      hoveredDate: undefined,
      yearBeingEdited: '',
      isYearBeingEdited: false
    }
  },
  computed: {
    inputPlaceholder () {
      return this.hoveredDate ? dayjs(this.hoveredDate).format(this.format) : this.placeholder
    },
    monthAndYear () {
      if (this.currentMonth) {
        return dayjs(this.currentMonth).locale(this.$i18n.locale).format('MMMM YYYY')
      } else {
        return null
      }
    },
    year () {
      return dayjs(this.currentMonth).locale(this.$i18n.locale).format('YYYY')
    },
    month () {
      return dayjs(this.currentMonth).locale(this.$i18n.locale).format('MMMM')
    },
    selectedTimeStart: {
      get: function () {
        return dayjs(this.startDate).format('HH:mm')
      },
      set: function (value) {
        this.timeSetStart = dayjs(value, 'HH:mm')
        const [hour, minute] = value.split(':')
        this.startDate = dayjs(this.startDate).set('hour', hour).set('minute', minute).valueOf()
      }
    },
    selectedTimeEnd: {
      get: function () {
        return dayjs(this.endDate).format('HH:mm')
      },
      set: function (value) {
        this.timeSetEnd = dayjs(value, 'HH:mm')
        this.endDate = dayjs(this.endDate).set({ hour: this.timeSetEnd.get('hour'), minute: this.timeSetEnd.get('minute') }).valueOf()
      }
    }

  },
  watch: {
    value: function (newValue) {
      this.init()
    },
    open (newVal) {
      this.$emit('open', newVal)
    },
    isOpen () {
      if (this.isOpen) {
        this.openDatePicker()
      } else {
        this.trap.deactivate()
      }
    },
    disabled (newValue) {
      if (newValue) {
        this.trap.deactivate()
      }
    }
  },
  mounted () {
    this.trap = createFocusTrap(this.$refs.datePicker, {
      clickOutsideDeactivates: true,
      onActivate: () => {
        this.open = true
      },
      onDeactivate: () => {
        this.closeDatePicker()
      }
    })
    this.now = dayjs().valueOf()
    this.currentMonth = this.now
    for (const i of this.ignore) {
      let valid = false
      if (typeof i === 'number') {
        if ((new Date(i)).getTime() > 0) {
          valid = true
        }
      }
      if (typeof i === 'object') {
        if (i.start && i.end) {
          if ((new Date(i.start)).getTime() > 0 && (new Date(i.end)).getTime() > 0) {
            valid = true
          }
        }
      }
      if (!valid) {
        throw new Error('Invalid element in ignore array')
      }
    }
    this.init()
    this.yearBeingEdited = this.year
  },
  methods: {
    toggle () {
      if (this.open) {
        this.trap.deactivate()
      } else {
        this.trap.activate()
      }
    },
    openDatePicker () {
      this.resetEditableYear()
      this.open = true
      const field = document.querySelector(`.f-field[name='${this.name}']`)
      if (field) {
        const element = document.querySelector(`#date-picker[name='${this.name}']`)
        element.style = `top: ${field.offsetHeight}px`
      }
      this.init()
    },
    closeDatePicker () {
      this.open = false
    },
    nextMonth () {
      if (!this.isYearBeingEdited) {
        this.currentMonth = dayjs(this.currentMonth).add(1, 'month').valueOf()
        this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
      } else {
        this.resetEditableYear()
      }
    },
    prevMonth () {
      if (!this.isYearBeingEdited) {
        this.currentMonth = dayjs(this.currentMonth).subtract(1, 'month').valueOf()
        this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
      } else {
        this.resetEditableYear()
      }
    },
    nextYear () {
      if (!this.isYearBeingEdited) {
        this.currentMonth = dayjs(this.currentMonth).add(1, 'year').valueOf()
        this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
        this.yearBeingEdited = this.year
      } else {
        this.resetEditableYear()
      }
    },
    prevYear () {
      if (!this.isYearBeingEdited) {
        this.currentMonth = dayjs(this.currentMonth).subtract(1, 'year').valueOf()
        this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
        this.yearBeingEdited = this.year
      } else {
        this.resetEditableYear()
      }
    },
    setEditedYear () {
      if (this.isYearBeingEdited) {
        if (this.isValidYear(this.yearBeingEdited)) {
          const current = dayjs(this.currentMonth).locale(this.$i18n.locale).format('YYYY')
          this.currentMonth = dayjs(this.currentMonth).add(Number.parseInt(this.yearBeingEdited - current), 'year').valueOf()
          this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
        } else {
          this.yearBeingEdited = this.year
        }
      }
      this.closeEditableYear()
    },
    isValidYear (year) {
      if (!year || isNaN(year) || year < 1750 || year > 2200) {
        return false
      }

      return dayjs(year).isValid()
    },
    closeEditableYear () {
      this.isYearBeingEdited = false
    },
    resetEditableYear () {
      this.yearBeingEdited = this.year
      this.closeEditableYear()
    },
    editYear () {
      this.yearBeingEdited = this.year
      this.isYearBeingEdited = true
    },
    select (day) {
      if (this.isYearBeingEdited) {
        this.resetEditableYear()
        return
      }
      if (this.rangeMode) {
        if (!this.startDate || (this.startDate && this.endDate)) {
          this.endDate = null
          this.startDate = dayjs(day.date).valueOf()
          this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
        } else {
          // Allow users to insert start and end out of order
          if (dayjs(this.startDate).isAfter(day.date)) {
            this.endDate = this.startDate
            this.startDate = day.date
          } else {
            this.endDate = dayjs(day.date).valueOf()
          }
          this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
          this.emitResult()
        }
      } else {
        this.startDate = dayjs(day.date).valueOf()
        this.currentMonth = this.selectedDate
        this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate)
        this.$emit('input', dayjs(this.startDate).valueOf())
        if (!this.time) {
          this.trap.deactivate()
        }
      }
    },
    emitResult () {
      if (!this.rangeMode) {
        this.$emit('input', dayjs(this.startDate).valueOf())
      } else {
        this.$emit('input', { start: dayjs(this.startDate).valueOf(), end: dayjs(this.endDate).valueOf() })
      }
      this.trap.deactivate()
    },
    init () {
      this.updateSelectedDate()
      this.weeks = initWeeks(this.ignore, this.now, this.currentMonth, this.startDate, this.endDate)
    },
    generateWeekId (week) {
      return week[0].date
    },
    generateDayId (day) {
      return day.date
    },
    updateSelectedDate () {
      if (this.value === null) {
        this.startDate = null
        this.endDate = null
        this.currentMonth = dayjs().valueOf()
        return
      }
      if (typeof this.value === 'number') {
        const date = dayjs(this.value, this.format, true)
        if (date.isValid()) {
          this.startDate = date.valueOf()
          this.currentMonth = this.startDate
          this.yearBeingEdited = this.year
        }
      } else {
        this.startDate = this.value?.start
        this.currentMonth = this.startDate ?? dayjs().valueOf()
        if (this.rangeMode) {
          this.endDate = this.value?.end
        }
      }
    },
    onBlur () {
      if (this.$refs.input) {
        const date = dayjs(this.value, this.format, true)
        if (!date.isValid()) {
          this.$emit('input', '')
          this.selectedDate = undefined
          this.now = dayjs().valueOf()
          this.currentMonth = this.now
        }
      }
    }
  },
  i18n: {
    messages: {
      pt: {
        time: 'Hora',
        timeStart: 'Hora inicial',
        timeEnd: 'Hora final',
        save: 'Guardar',
        weekdays: {
          initials: {
            sunday: 'D',
            monday: 'S',
            tuesday: 'T',
            wednesday: 'Q',
            thursday: 'Q',
            friday: 'S',
            saturday: 'S'
          }
        }
      },
      en: {
        time: 'Time',
        timeStart: 'Start time',
        timeEnd: 'End time',
        save: 'Save',
        weekdays: {
          initials: {
            sunday: 'S',
            monday: 'M',
            tuesday: 'T',
            wednesday: 'W',
            thursday: 'T',
            friday: 'F',
            saturday: 'S'
          }
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

/* stylelint-disable font-family-no-missing-generic-family-keyword */
$mid-gray: #a9a9a9;

.f-field {
  position: relative;
}

/* Date Picker */

.date-picker {
  padding: 1.5rem;
  width: 20rem;
  position: absolute;
  background: white;
  box-shadow: 0 2px 20px 0 rgba(0 0 0 / 15%);
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5%) scale(0.95);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
  margin-top: 0.5rem;
  font-size: 1rem;

  .year-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .year {
      &:hover {
        cursor: pointer;
        color: $slate;
      }
    }

    .f-field__input {
      text-align: center;
      color: $dark;
      width: 50%;
      padding: 0;
      border: 0;
      border-radius: 0;
      box-shadow: 0 0.0625rem 0 $gray-300;
      transition: box-shadow 0.2s ease-in-out;
    }
    .f-field__input:focus ~ .f-field__label,
    .f-field__input--is-filled ~ .f-field__label {
      color: $gray-400;
    }
    .f-field__input:focus {
      box-shadow: 0 0.125rem 0 $blue-600;
    }
  }

  .days {
    button,
    span {
      border: 0;
      background: none;
      padding: 8px 0;
      box-sizing: border-box;
      width: 14.28px;
    }

    .between {
      color: $dark;
      &::after {
        content: "";
        width: 29px;
        height: 26px;
        position: absolute;
        background: rgba($blue, 0.2);
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    .time-picker {
      color: black !important;
    }
    .ignore {
      color: grey;
    }
  }

  button:not(.disabled) {
    font-weight: 600;
  }

  header,
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header {
    color: black;
    &.date-picker-header {
      padding-left: 16px;
      h4 {
        font-family: "Source Sans Pro", Helvetica, "Helvetica Neue", Arial;
        font-weight: 800;
        margin: 0;
      }
    }
    .pagination {
      width: 20px;
      height: 20px;
      position: relative;
      &.left {
        transform: translateX(-20%) translateY(5%) rotate(45deg);
      }
      &.right {
        transform: translateX(-20%) translateY(5%) rotate(225deg);
      }
      &::before,
      &::after {
        content: "";
        width: 2px;
        height: 8px;
        background: $blue;
        position: absolute;
        top: 6px;
        left: 7px;
        border-radius: 2px;
        transform-origin: bottom left;
      }
      &::after {
        transform: rotate(90deg) translateX(-2px);
      }
    }
  }

  .calendar {
    text-align: center;
    header {
      color: $gray-400;
      font-weight: 600;
      span {
        padding: 4px 0;
      }
    }
    .main {
      border-top: 1px solid $light-gray;
      padding: 4px 0;
      margin: 4px 0;
    }
    button {
      position: relative;
      display: flex;
      justify-content: center;
      &.selected {
        color: white;
        &::before {
          content: "";
          background: $blue !important;
          width: 26px;
          height: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          z-index: -1;
          border-radius: 100%;
        }
      }

      &.selected:last-of-type {
        &::after {
          content: "";
          width: 15px;
          height: 26px;
          position: absolute;
          background: rgba($blue, 0.2);
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translateY(-50%) translateX(-50%);
        }
      }
      &:not(.selected):hover {
        &::before {
          content: "";
          background: rgba($gray-400, 0.4);
          width: 26px;
          height: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          z-index: -1;
          border-radius: 2px;
        }
      }

      &.today {
        color: white;
        &::before {
          content: "";
          background: $slate;
          width: 26px;
          height: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          z-index: -1;
          border-radius: 100%;
        }
      }
      &.disabled {
        color: $mid-gray;
      }
    }
  }

  label {
    font-family: "Source Sans Pro", Helvetica, "Helvetica Neue", Arial;
    font-weight: 800;
    color: $dark;
  }

  .modal-input {
    padding: 8px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .select-wrapper::after {
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 5px;
  }

  .half-wrapper .half:nth-of-type(odd) {
    padding-right: 5px;
  }

  .half-wrapper .half:nth-of-type(even) {
    padding-left: 5px;
  }

  .upper-arrow {
    top: -0.25rem;
    left: 10%;
    transform: translateX(70%) translateY(-50%) rotate(0deg);
  }

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
    .upper-arrow {
      opacity: 1;
      visibility: visible;
    }
  }
}
.f-field--absolute {
  position: absolute;
}
.f-field__input--hidden {
  visibility: hidden;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}
.hours {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.hour {
  display: flex;
  flex-direction: row;
}
</style>
