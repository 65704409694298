
<div class="manage-trip">
  <section>
    <fieldset :disabled="tripData.state === 'canceled'">
      <h1 class="h2">
        {{ $t('manage.trip') }}
      </h1>

      <section>
        <h3>{{ $t('information.about.this.trip') }}</h3>
        <div class="trip-info">
          <div class="trip-info__element">
            <strong>{{ $t('route') }}</strong>
            <p>
              <span>{{ getOrigin() }}</span>
              <i class="arrow inline-text" />
              <span>{{ getDestiny() }}</span>
            </p>
          </div>
          <div class="trip-info__element">
            <strong>{{ $t('departure') }}</strong>
            <p>{{ getDepartureTime() }}</p>
          </div>
          <div class="trip-info__element">
            <strong>{{ $t('stops') }}</strong>
            <p>
              <span
                v-for="stop in tripData.route.stops"
                :key="stop.id"
                style="display: block;"
              >{{ stop.name }}</span>
            </p>
          </div>
          <div class="trip-info__element">
            <strong>{{ $t('subscribed') }}</strong>
            <p>{{ bookingsCount }}</p>
          </div>
          <div class="trip-info__element">
            <strong>{{ $t('state') }}</strong>
            <p>{{ $t(tripData.state) }}</p>
          </div>
        </div>
      </section>

      <section>
        <h3>{{ $t('ticket.attribution') }}</h3>
        <div class="half-wrapper">
          <div class="half">
            <label
              class="pre-header"
              for="available-seats"
            >{{ $t('number.of.available.seats') }}</label>
            <input
              id="available-seats"
              v-model="tripData.capacity"
              type="number"
              name="available-seats"
              class="modal-input available-seats"
              value=""
              data-filled="false"
              placeholder="Nº de lugares"
              @change="update"
            >
          </div>
          <div class="half">
            <label
              class="pre-header"
              for="available-seats"
            >{{ $t('ticket.allocation.strategy') }}</label>
            <div class="select-wrapper">
              <select
                class="modal-input"
                disabled
              >
                <option value="First come first served">
                  {{ $t('first.come.first.served') }}
                </option>
              </select>
            </div>
          </div>
          <div
            :class="{hidden: tripData.bookings.length <= tripData.capacity}"
            class="validation warning  available-seats-warning"
          >
            <p>{{ $t('more.people.than.seats.warning') }}</p>
          </div>
          <div class="validation warning hidden strategy-warning">
            <p>{{ $t('first.come.first.served.description') }}</p>
          </div>
        </div>
      </section>

      <section v-if="canBook()">
        <section>
          <h3>{{ $t('external.bookings') }}</h3>
        </section>
        <section>
          <strong class="pre-header">{{ $t('external.bookings') }}</strong>
          <p>{{ $t('external.bookings.info') }}</p>
          <div class="card">
            <div
              v-for="bookingEmail in nonCanceledExternalBookingEmails"
              :key="bookingEmail"
              class="card-row"
            >
              <div class="card-row__text">
                <p>{{ bookingEmail }}</p>
              </div>
              <div class="card-row__meta">
                <button
                  class="button small circle delete"
                  @click.prevent="removeExternalBooking(bookingEmail)"
                >
                  {{ $t('delete') }}
                </button>
              </div>
            </div>
            <div class="card-row">
              <div class="card-row__text">
                <label for="email-input">{{ $t('email') }}</label>
                <input
                  id="email-input"
                  v-model="newExternalBookingEmail"
                  :placeholder="$t('external.person.email')"
                  type="email"
                  class="modal-input"
                  value=""
                  data-filled="false"
                >
                <label for="name-input">{{ $t('name') }}</label>
                <input
                  v-model="newExternalBookingName"
                  :placeholder="$t('external.person.name')"
                  type="text"
                  class="modal-input"
                  value=""
                  data-filled="false"
                >
              </div>
              <div class="card-row__meta" />
            </div>
          </div>
          <div class="btn--group">
            <button
              class="btn btn--primary"
              @click.prevent="addExternalBooking()"
            >
              {{ $t('book.ticket') }}
            </button>
          </div>
          <div
            v-if="displayNewExternalBookingWarning"
            class="validation warning"
          >
            <p>{{ $t('invalid.email') }}</p>
          </div>
        </section>
      </section>

      <section>
        <h3>{{ $t('bookings.state') }}</h3>
        <input
          v-model="bookingsFilter"
          :placeholder="$t('search.the.table')"
          type="text"
          value=""
          class="modal-input"
          data-filled="false"
        >
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                {{ $t('identifier') }}
              </th>
              <th scope="col">
                {{ $t('current.state') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(booking, i) in bookings"
              :key="i"
            >
              <th scope="row">
                {{ i + 1 }}
              </th>
              <th style="font-weight: normal;">
                {{ booking.owner }}
              </th>
              <th :style="`font-weight: normal;${getBookingStateColor(booking.state)}`">
                {{ $t(booking.state) }}
              </th>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h3>{{ $t('other.actions') }}</h3>
        <div class="btn--group">
          <button
            class="btn"
            @click.prevent="showTripEmailModal"
          >
            {{ $t('send.email.to.subscribed') }}
          </button>
          <button
            class="btn btn--danger"
            @click.prevent="showCancelTripModal"
          >
            {{ $t('cancel.trip') }}
          </button>
        </div>
      </section>
    </fieldset>
  </section>
  <!-- All possible modals -->
  <Modal v-model="cancelTripOpen">
    <cancel-trip
      slot="modal-panel"
      v-model="cancelTripOpen"
      :trip="tripData"
      @submit="submitCancelTrip"
    />
  </Modal>

  <Modal v-model="tripEmailModalOpen">
    <trip-email-modal
      slot="modal-panel"
      v-model="tripEmailModalOpen"
      :trip="tripData"
      @submit="submitEmail"
    />
  </Modal>

  <Modal v-model="stateModalOpen">
    <Component
      :is="modalState.type"
      slot="modal-panel"
      v-model="stateModalOpen"
      :title="modalState.title"
      :body="modalState.body"
      :close="modalState.close"
    />
  </Modal>
</div>
