<template>
  <nav
    class="page-nav"
    aria-label="Navegação de artigos"
  >
    <ul role="navigation">
      <li v-if="paginationData.currentPage > 1">
        <a
          href=""
          class="page-button"
          aria-label="Previous"
          @click.prevent="changePage(paginationData.currentPage - 1)"
        >
          {{ $t('previous') }}
        </a>
      </li>
      <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(1)">
        <a
          href=""
          @click.prevent="changePage(1)"
        >1</a>
      </li>
      <li v-if="pagesToShow[0] > 2">
        <span>...</span>
      </li>
      <li
        v-for="page in pagesToShow"
        :key="page"
        :class="{'current': page == paginationData.currentPage}"
      >
        <a
          href=""
          @click.prevent="changePage(page)"
        >{{ page }}</a>
      </li>
      <li v-if="pagesToShow[pagesToShow.length - 1] < paginationData.totalPages - 1">
        <span>...</span>
      </li>
      <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(paginationData.totalPages)">
        <a
          href=""
          @click.prevent="changePage(paginationData.totalPages)"
        >{{ paginationData.totalPages }}</a>
      </li>
      <li v-if="paginationData.currentPage < paginationData.totalPages">
        <a
          href=""
          class="page-button"
          @click.prevent="changePage(paginationData.currentPage + 1)"
        >
          {{ $t('next') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      paginationData: JSON.parse(JSON.stringify(this.pagination))
    }
  },
  computed: {
    pagesToShow () {
      let from = this.paginationData.currentPage - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.paginationData.totalPages) {
        to = this.paginationData.totalPages
      }
      const pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    }
  },
  methods: {
    changePage (page) {
      this.paginationData.currentPage = page
      this.$emit('paginate', this.paginationData)
    }
  }
}
</script>
