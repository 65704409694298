<template>
  <div>
    <section>
      <div class="list-stops-header">
        <h1 style="margin: 0;">
          {{ $t('stops') }}
        </h1>
        <div class="btn--group list-stops-header__meta">
          <router-link
            :to="{ name: 'ListRoutesPage' }"
            class="btn"
          >
            {{ $t('stops.back') }}
          </router-link>
        </div>
      </div>
      <div class="stops-list">
        <div
          class="card"
        >
          <div
            v-for="stop in stops"
            :key="stop.id"
            class="card-row"
          >
            <div class="card-row__text">
              <strong>{{ stop.name }}</strong>
            </div>
            <div class="card-row__meta">
              <div class="btn--group">
                <button
                  class="btn btn--danger btn--sm"
                  @click="deleteStop(stop)"
                >
                  {{ $t('remove') }}
                </button>
                <button
                  class="btn btn--sm"
                  @click="openModal('EditStop', { stop })"
                >
                  {{ $t('edit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn--primary"
        @click="openModal('CreateStop')"
      >
        {{ $t('create.stop') }}
      </button>
    </section>

    <Modal
      v-model="modalOpen"
      class="modal--lg"
    >
      <template v-if="modalName === 'EditStop'">
        <edit-stop
          slot="modal-panel"
          v-model="modalOpen"
          :stop="modalData.stop"
          @submit="submitEditStop"
        />
      </template>
      <template v-else-if="modalName === 'CreateStop'">
        <create-stop
          slot="modal-panel"
          v-model="modalOpen"
          @submit="submitCreation"
        />
      </template>
    </Modal>

    <Modal v-model="deleteModalOpen">
      <delete-modal
        slot="modal-panel"
        v-model="deleteModalOpen"
        :title="$t('delete.this.stop')"
        :body="$t('delete.this.stop.description')"
        :confirm="$t('yes.delete.this.stop')"
        :cancel="$t('no.keep.this.stop')"
        @submit="submitDelete"
      />
    </Modal>
    <Modal v-model="submitModal">
      <Component
        :is="submitState.type === 'success' ? 'SuccessModal' : 'ErrorModal'"
        slot="modal-panel"
        v-model="submitModal"
        :title="submitState.title"
        :body="submitState.body"
        :close="submitState.close"
      />
    </Modal>
  </div>
</template>

<script>
import DeleteModal from '@/components/utils/DeleteModal.vue'
import SuccessModal from '@/components/utils/SuccessModal.vue'
import ErrorModal from '@/components/utils/ErrorModal.vue'
import Modal from '@/components/utils/Modal.vue'
import CreateStop from '@/components/stops/CreateStop.vue'
import EditStop from '@/components/stops/EditStop.vue'
import { mapActions, mapState } from 'vuex'

import config from '@/config'
import Vue from 'vue'
let VueGoogleMaps = import('gmap-vue')

export default {
  name: 'ListStops',
  components: {
    Modal,
    CreateStop,
    EditStop,
    DeleteModal,
    SuccessModal,
    ErrorModal
  },
  data () {
    return {
      modalOpen: false,
      modalName: '',
      modalData: {},
      deleteModalOpen: false,
      stopToDelete: '',
      submitModal: false,
      submitState: {}
    }
  },
  computed: {
    ...mapState(['stops'])
  },
  async created () {
    this.fetchStops()
    VueGoogleMaps = await VueGoogleMaps
    Vue.use(VueGoogleMaps, {
      load: {
        key: config.googleMapsAPIKey
      }
    })
  },
  methods: {
    ...mapActions([
      'fetchStops',
      'delStop',
      'newStop',
      'editStop'
    ]),
    deleteStop (stop) {
      this.deleteModalOpen = true
      this.stopToDelete = stop
    },
    async submitDelete () {
      try {
        await this.delStop({ id: this.stopToDelete.id })
        this.deleteModalOpen = false
        // TODO: Add i18n
        this.submitState = {
          type: 'success',
          title: 'Paragem apagada com sucesso',
          body: 'Paragem apagada com sucesso',
          close: 'Voltar'
        }
        this.submitModal = true
      } catch (err) {
        this.deleteModalOpen = false
        if (err && err.response && err.response.data && err.response.data.key === 'stop-in-route') {
          // TODO: Add i18n
          this.submitState = {
            type: 'error',
            title: 'Paragem existe numa rota',
            body: 'Paragem existe numa rota não sendo possível apagar',
            close: 'Voltar'
          }
        } else {
          // TODO: Add i18n
          this.submitState = {
            type: 'error',
            title: 'Erro desconhecido',
            body: 'Erro com origem desconhecida',
            close: 'Voltar'
          }
        }
        this.submitModal = true
      }
    },
    async submitCreation (stop) {
      try {
        await this.newStop({ stop })
        this.modalOpen = false
        // TODO: Add i18n
        this.submitState = {
          type: 'success',
          title: 'Paragem criada com sucesso',
          body: 'Paragem criada com sucesso',
          close: 'Voltar'
        }
        this.submitModal = true
        await this.fetchStops()
      } catch (error) {
        this.modalOpen = false
        // TODO: Add i18n
        this.submitState = {
          type: 'error',
          title: 'Erro a criar a paragem',
          body: 'Ocorreu um erro que impossibilitou a criação da paragem',
          close: 'Voltar'
        }
        this.submitModal = true
      }
    },
    async submitEditStop (stop) {
      try {
        await this.editStop({ stop })
        this.modalOpen = false
        // TODO: Add i18n
        this.submitState = {
          type: 'success',
          title: 'Paragem editada com sucesso',
          body: 'Paragem editada com sucesso',
          close: 'Voltar'
        }
        this.submitModal = true
        await this.fetchStops()
      } catch (err) {
        this.modalOpen = false
        // TODO: Add i18n
        this.submitState = {
          type: 'error',
          title: 'Erro a editar a paragem',
          body: 'Ocorreu um erro que impossibilitou a edição da paragem',
          close: 'Voltar'
        }
        this.submitModal = true
      }
    },
    async openModal (name, data) {
      VueGoogleMaps = await VueGoogleMaps
      this.modalOpen = true
      this.modalName = name
      this.modalData = data
    }
  }
}
</script>
<style lang="scss">
.stops-list {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
}

.list-stops-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin: 1rem 0;
}

.list-stops-header__meta {
  margin-left: auto;
}
</style>
