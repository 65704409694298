<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
button,
a.button {
  cursor: pointer;
}
</style>
