<template>
  <div
    v-if="notifications"
    id="notifications"
  >
    <div class="container">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-list"
      >
        <p>{{ notification.message[$i18n.locale] }}</p>
        <button
          class="notification-close"
          @click="hideNotification(notification.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Notifications',
  props: {
    notifications: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions([
      'editNotification',
      'fetchNotifications'
    ]),
    async hideNotification (id) {
      await this.editNotification({ id, viewed: true })
      await this.fetchNotifications({ viewed: true }) // To update the store
    }
  }
}
</script>
