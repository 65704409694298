<template>
  <div
    class="user-ticket"
  >
    <!-- FIXME: The background property present in the 'card' class breaks the animation probably due to the fact that the road and the trees have "z-index: -1;" -->
    <!-- this was done to have the bus covering both the trees and the road when they are behind but with the background they are no longer visible -->
    <div
      class="modal card"
      style="background: none;"
    >
      <div class="card-row">
        <div class="card-row__text user-ticket">
          <i class="icon">
            <svg
              class="bus-riding-animation"
              width="54"
              height="54"
              viewBox="0 0 54 54"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <path
                  id="a"
                  d="M5 30h44v2H5z"
                />
                <path
                  id="b"
                  d="M5 8h44v2H5z"
                />
                <path
                  id="c"
                  d="M21 39h12v2.078H21z"
                />
              </defs>
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  class="wheel"
                  d="M8 47v5.002c0 .556.443.998.997.998h5.006c.55 0 .997-.447.997-.998V47H8z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  class="wheel"
                  d="M39 47v5.002c0 .556.443.998.997.998h5.006c.55 0 .997-.447.997-.998V47h-7z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  class="bus-body"
                  d="M4 47h46V2.001A1 1 0 0 0 48.999 1H5a1 1 0 0 0-1 1.001V47z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <circle
                  stroke="#009DE0"
                  stroke-width="2"
                  cx="12"
                  cy="39"
                  r="3"
                />
                <circle
                  stroke="#009DE0"
                  stroke-width="2"
                  cx="42"
                  cy="39"
                  r="3"
                />
                <path
                  stroke="#009DE0"
                  d="M5.5 30.5h43v1h-43z"
                />
                <path
                  stroke="#009DE0"
                  d="M5.5 8.5h43v1h-43z"
                />
                <path
                  stroke="#009DE0"
                  d="M21.5 39.5h11v1.078h-11z"
                />
                <path
                  d="M50 11v12h1.5c.834 0 1.5-.669 1.5-1.503v-8.994c0-.83-.672-1.503-1.5-1.503H50z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  d="M4 23V11H2.5c-.834 0-1.5.669-1.5 1.503v8.994C1 22.327 1.672 23 2.5 23H4z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  class="wheel-lines"
                  d="M12 50v-1"
                  stroke="#009DE0"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  class="wheel-lines"
                  d="M42 50v-1"
                  stroke="#009DE0"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </g>
            </svg>

            <svg
              class="tree left"
              stroke-width="2"
              width="48"
              height="64"
              viewBox="0 0 48 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                stroke="#009DE0"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M24 63V24m-9 6l9 8.5 8.5-8.5M22 63h4" />
                <path d="M28.93 46.854C39.54 44.792 47 35.513 47 24.206 47 11.39 36.703 1 24 1S1 11.39 1 24.206c0 11.067 7.678 20.325 17.953 22.646" />
              </g>
            </svg>

            <svg
              class="tree right"
              stroke-width="2"
              width="48"
              height="64"
              viewBox="0 0 48 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                stroke="#009DE0"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M24 63V24m-9 6l9 8.5 8.5-8.5M22 63h4" />
                <path d="M28.93 46.854C39.54 44.792 47 35.513 47 24.206 47 11.39 36.703 1 24 1S1 11.39 1 24.206c0 11.067 7.678 20.325 17.953 22.646" />
              </g>
            </svg>

            <svg
              class="road"
              width="119"
              height="41"
              viewBox="0 0 119 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.5 39.503L58.25 1.5l60.25 38.003"
                stroke="#009DE0"
                stroke-width="2"
                fill="none"
              />
            </svg>

          </i>

          <div class="route">
            <div class="source">
              <span>{{ $t('route.origin') }}</span>
              <strong>{{ getOrigin() }}</strong>
            </div>
            <i class="arrow" />
            <div class="destiny">
              <span>{{ $t('route.destiny') }}</span>
              <strong>{{ getDestiny() }}</strong>
            </div>
          </div>
          <div
            class="qr-code"
            :class="{'loading-ticket': !ticket}"
          >
            <qriously
              :value="ticket || ''"
              :size="250"
            />
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="small"
            v-html="$t('ticket.info', { date: getTicketCalendar(), username: loggedUser.username, name: loggedUser.name })"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueQriously from 'vue-qriously'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { getTicket } from '@/api/tickets'

Vue.use(VueQriously)
export default {
  name: 'Ticket',
  props: {
    trip: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ticket: null
    }
  },
  computed: {
    ...mapState([
      'loggedUser'
    ])
  },
  async created () {
    this.ticket = await getTicket(this.trip.id)
  },
  methods: {
    getOrigin () {
      const route = this.trip.route
      return route.stops[0].name
    },
    getDestiny () {
      const route = this.trip.route
      const routeSize = route.stops.length
      return route.stops[routeSize - 1].name
    },
    getTicketCalendar () {
      return dayjs(this.trip.departure).format('DD-MM-YYYY - HH:mm')
    },
    getMonth () {
      const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
      const dt = new Date(this.trip.departure)
      return this.$t(months[dt.getMonth()])
    },
    getYear () {
      const dt = new Date(this.trip.departure)
      return dt.getFullYear()
    },
    getTime () {
      const dt = new Date(this.trip.departure)
      return dt.toLocaleTimeString()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.user-ticket {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: none;
}
.route {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $light-gray;
  .source,
  .destiny {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    .stop {
      font-family: $heading-font-bold;
      font-size: 1.6875rem;
    }
    .label {
      font-size: 0.75rem;
      color: $gray;
    }
  }
}
// Trees animation
.tree {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  &.left {
    transform: translateX(calc(-50% - 120px)) translateY(0%) scale(1.25);
    animation: left-tree 3s infinite;
  }
  &.right {
    transform: translateX(calc(-50% + 120px)) translateY(0%) scale(0.35);
    animation: right-tree 3s infinite;
    animation-delay: 1s;
    visibility: hidden;
  }

  @keyframes left-tree {
    0% {
      transform: translateX(calc(-50% - 120px)) translateY(-20%) scale(1.25);
      stroke-width: 1.5;
    }
    100% {
      transform: translateX(calc(-50%)) translateY(-65%) scale(0.35);
      stroke-width: 3;
    }
  }

  @keyframes right-tree {
    0% {
      transform: translateX(calc(-50% + 120px)) translateY(-20%) scale(1.25);
      stroke-width: 1.5;
      visibility: visible;
    }
    100% {
      transform: translateX(calc(-50%)) translateY(-65%) scale(0.35);
      stroke-width: 3;
      visibility: visible;
    }
  }
}

/* Bus riding animation */
.bus-riding-animation {
  .bus-body {
    fill: white;
  }

  animation: bus 2s infinite;
  .wheel {
    animation: wheels 1s infinite;
  }

  .wheel:first-of-type {
    animation-delay: 0.05s;
  }

  @keyframes wheels {
    0%,
    100% {
      transform: translateY(-2px);
    }
    50% {
      transform: translateY(0);
    }
  }

  .wheel-lines {
    animation: wheel-line 0.2s infinite;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    transform-origin: center;
    stroke-dasharray: 1, 5;
  }

  @keyframes wheel-line {
    0% {
      stroke-dashoffset: 1;
    }

    100% {
      stroke-dashoffset: -5;
    }
  }
}

.road {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

@keyframes bus {
  0%,
  50%,
  100% {
    transform: translateY(5%) translateX(0) rotate(0deg);
  }
  25% {
    transform: translateY(0) translateX(-1%) rotate(-1deg);
  }

  75% {
    transform: translateY(0) translateX(1%) rotate(1deg);
  }
}

.loading-ticket {
  visibility: hidden;
}
</style>
