<template>
  <div>
    <div
      v-if="bookingsPage && bookingsPage.total === 0"
      class="empty-state tickets-bounce"
    >
      <div class="icon-wrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 38 60"
          width="38px"
          class="ticket-sec"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#8F95A1"
            stroke-width="2"
          >
            <g class="bottom">
              <path d="M37 20.8999819V59H1V20.8999819C3.28224032 20.4367116 5 18.4189579 5 16s-1.71775968-4.4367116-4-4.8999819V1h36v10.1000181c-2.2822403.4632703-4 2.481024-4 4.8999819s1.7177597 4.4367116 4 4.8999819z" />
              <path
                stroke-dasharray="4 2"
                d="M8 16h22"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 36.0167558l-5.8778525 4.0734141 2.0576908-6.8489241-5.69040346-4.3314157 7.14957536-.1594538L19 22l2.3609898 6.7503763 7.1495754.1594538-5.6904035 4.3314157 2.0576908 6.8489241z"
              />
              <path d="M8 51h22" />
              <path d="M8 46h22" />
            </g>
            <g class="top">
              <path d="M37 20.8999819V59H1V20.8999819C3.28224032 20.4367116 5 18.4189579 5 16s-1.71775968-4.4367116-4-4.8999819V1h36v10.1000181c-2.2822403.4632703-4 2.481024-4 4.8999819s1.7177597 4.4367116 4 4.8999819z" />
              <path
                stroke-dasharray="4 2"
                d="M8 16h22"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 36.0167558l-5.8778525 4.0734141 2.0576908-6.8489241-5.69040346-4.3314157 7.14957536-.1594538L19 22l2.3609898 6.7503763 7.1495754.1594538-5.6904035 4.3314157 2.0576908 6.8489241z"
              />
              <path d="M8 51h22" />
              <path d="M8 46h22" />
            </g>
          </g>
        </svg>
      </div>
      <h1 class="title-3">
        {{ $t('you.have.no.reservations') }}
      </h1>
      <p>{{ $t('you.have.no.reservations.description') }}</p>
    </div>
    <section
      v-for="dt in bookingDates"
      v-else
      :key="dt.id"
    >
      <h4>{{ getWeekDay(dt) }}, {{ getDay(dt) }} {{ getMonth(dt) }} {{ getYear(dt) }}</h4>
      <table class="l-table l-table--alternating-rows reservations">
        <thead class="l-table__header">
          <tr class="l-table__row">
            <th class="l-table__columnheader">
              {{ $t('route') }}
            </th>
            <th class="l-table__columnheader">
              {{ $t('departure') }}
            </th>
            <th class="l-table__columnheader">
              {{ $t('stops') }}
            </th>
            <th
              class="l-table__columnheader"
              aria-label="$t('actions')"
            />
          </tr>
        </thead>
        <tbody class="l-table__body">
          <tr
            v-for="booking in getBookings(dt)"
            :key="booking.ts"
            data-qa="Ticket"
            class="l-table__row"
          >
            <td
              class="l-table__cell"
              data-label="Rota"
            >
              <span
                aria-hidden="true"
                class="l-table__columnheader--mobile"
              >{{ $t('route') }}</span>
              <span
                class="h4"
              >{{ getOrigin(booking) }} <i class="arrow" /> {{ getDestiny(booking) }}</span>
            </td>
            <td
              class="l-table__cell"
              data-label="Partida"
            >
              <span
                aria-hidden="true"
                class="l-table__columnheader--mobile"
              >{{ $t('departure') }}</span>
              <time>{{ getDepartureTime(booking.departure) }}</time>
            </td>
            <td
              class="l-table__cell"
              data-label="Paragens"
            >
              <span
                aria-hidden="true"
                class="l-table__columnheader--mobile"
              >{{ $t('stops') }}</span>
              <span>
                {{ getStops(booking) }}
              </span>
            </td>
            <td
              v-if="booking.state === 'confirmed' && canCancel(booking)"
              class="l-table__cell trip-row__actions"
              :aria-label="$t('actions')"
            >
              <button
                class="btn btn--primary btn--sm"
                @click.prevent="showTicketModal(booking)"
              >
                {{ $t('show.ticket') }}
              </button>
              <drop-down>
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <ul
                  slot="dropdown-panel"
                  class="dropdown-menu"
                >
                  <li class="dropdown-menu__item">
                    <button
                      class="dropdown-menu__link"
                      data-qa="cancelTicket"
                      @click="showBookingCancellationModal(booking)"
                    >
                      <span class="u-text-danger">
                        {{ $t('cancel.booking') }}
                      </span>
                    </button>
                  </li>
                </ul>
              </drop-down>
            </td>
            <td
              v-else-if="booking.state === 'pending'"
              :aria-label="$t('actions')"
              class="trip-row__actions"
            >
              <button
                class="btn btn--orange btn--sm"
                @click.prevent="showPendingBookingModal(booking)"
              >
                {{ $t('ticket.states.pending') }}
              </button>
              <drop-down>
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <ul
                  slot="dropdown-panel"
                  class="dropdown-menu"
                >
                  <li class="dropdown-menu__item">
                    <button
                      class="dropdown-menu__link"
                      data-qa="cancelTicket"
                      @click="showBookingCancellationModal(booking)"
                    >
                      <span class="u-text-danger">
                        {{ $t('cancel.booking') }}
                      </span>
                    </button>
                  </li>
                </ul>
              </drop-down>
            </td>
            <td
              v-else-if="booking.state === 'canceled'"
              :aria-label="$t('actions')"
              class="trip-row__actions"
            >
              <button
                disabled
                class="btn btn--slate btn--sm"
              >
                {{ $t('ticket.states.canceled') }}
              </button>
              <!-- Hack to keep alignment -->
              <drop-down style="visibility: hidden;">
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              </drop-down>
            </td>
            <td
              v-else-if="booking.state === 'declined'"
              :aria-label="$t('actions')"
              class="trip-row__actions"
            >
              <button
                disabled
                class="btn btn--danger btn--sm"
              >
                {{ $t('ticket.states.declined') }}
              </button>
              <!-- Hack to keep alignment -->
              <drop-down style="visibility: hidden;">
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              </drop-down>
            </td>
            <td
              v-else-if="booking.state === 'confirmed'"
              :aria-label="$t('actions')"
              class="trip-row__actions"
            >
              <button
                class="btn btn--primary btn--sm"
                @click.prevent="showTicketModal(booking)"
              >
                {{ $t('show.ticket') }}
              </button>
              <!-- Hack to keep alignment -->
              <drop-down style="visibility: hidden;">
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              </drop-down>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <!-- All possible modals -->
    <Modal
      v-if="bookingsPage"
      v-model="cancelBookingOpen"
    >
      <cancel-booking
        slot="modal-panel"
        v-model="cancelBookingOpen"
        :trip="selectedTrip"
        :user-bookings="bookingsPage.bookings"
        @submit="submitTripCancel"
      />
    </Modal>
    <Modal
      v-model="pendingBookingOpen"
    >
      <pending-booking
        slot="modal-panel"
        v-model="pendingBookingOpen"
      />
    </Modal>
    <Modal v-model="ticketModalOpen">
      <ticket
        slot="modal-panel"
        v-model="ticketModalOpen"
        :trip="selectedTrip"
      />
    </Modal>
    <Modal v-model="stateModalOpen">
      <Component
        :is="modalState.type"
        slot="modal-panel"
        v-model="stateModalOpen"
        :title="modalState.title"
        :body="modalState.body"
        :close="modalState.close"
      />
    </Modal>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import Modal from '@/components/utils/Modal.vue'
import CancelBooking from '@/components/bookings/CancelBooking.vue'
import PendingBooking from '@/components/bookings/PendingBooking.vue'
import Ticket from '@/components/tickets/Ticket'
import SuccessModal from '@/components/utils/SuccessModal.vue'
import ErrorModal from '@/components/utils/ErrorModal.vue'

import DropDown from '@/components/utils/Dropdown.vue'

import { getBookings, removeBooking } from '@/api/bookings'
import dayjs from 'dayjs'

export default {
  name: 'ListTickets',
  components: {
    CancelBooking,
    PendingBooking,
    Ticket,
    Modal,
    SuccessModal,
    ErrorModal,
    DropDown
  },
  data () {
    return {
      cancelBookingOpen: false,
      pendingBookingOpen: false,
      bookingModalOpen: false,
      ticketModalOpen: false,
      selectedTrip: {}, // Contains the trip selected in the last action
      stateModalOpen: false,
      // Contains the variables required to show the success and error modals
      modalState: {
        type: '' // ErrorModal ou SuccessModal
      },
      bookingsPage: { bookings: [] }
    }
  },
  computed: {
    ...mapState([
      'loggedUser'
    ]),
    bookingDates () {
      const result = []
      if (this.bookingsPage) {
        this.bookingsPage.bookings.forEach((booking) => {
          const dt = new Date(booking.departure).toISOString().slice(0, 10)
          if (!(result.includes(dt))) {
            result.push(dt)
          }
        })
        result.sort((d1, d2) => d1.localeCompare(d2))
      }
      return result
    }
  },
  async created () {
    const start = dayjs().subtract(2, 'hours').valueOf()
    this.bookingsPage = await getBookings({ user: this.loggedUser.username, start })
  },
  methods: {
    sortBookings (bookings) {
      return (bookings || []).sort((a, b) => dayjs(a.departure).valueOf() - dayjs(b.departure).valueOf())
    },
    showBookingCancellationModal (trip) {
      this.selectedTrip = trip
      this.cancelBookingOpen = true
    },
    showPendingBookingModal (trip) {
      this.pendingBookingOpen = true
      this.selectedTrip = trip
    },
    showTicketModal (trip) {
      this.ticketModalOpen = true
      this.selectedTrip = trip
    },
    async submitTripCancel (trip) {
      try {
        const tripId = trip.id
        await removeBooking({ trip: tripId })
        this.cancelBookingOpen = false
        await this.$nextTick()

        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: this.$t('cancel.booking.success'),
          body: this.$t('cancel.booking.success'),
          close: this.$t('back')
        }
        this.bookingsPage.bookings = this.bookingsPage.bookings.filter(elem => elem.id !== tripId)
      } catch (err) {
        this.cancelBookingOpen = false
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: this.$t('cancel.booking.no.longer.possible.title'),
          body: this.$t('cancel.booking.no.longer.possible.body'),
          close: this.$t('back')
        }
      }
    },
    getOrigin (trip) {
      const route = trip.route
      return route.stops[0].name
    },
    getStops (trip) {
      const route = trip.route
      const stops = []
      route.stops.forEach(function (stop, index) {
        stops.push(stop.name)
      })
      return stops.join(', ')
    },
    getDestiny (trip) {
      const route = trip.route
      const routeSize = route.stops.length
      return route.stops[routeSize - 1].name
    },
    getDepartureTime (departure) {
      return dayjs(departure).format('HH:mm')
    },
    getBookings (dt) {
      const currentDayBookings = this.bookingsPage.bookings.filter(booking => (dayjs(booking.departure).format('D-M-YYYY') === dayjs(dt).format('D-M-YYYY')))
      return currentDayBookings.sort((a, b) => {
        return a.departure - b.departure
      })
    },
    getWeekDay (dtStr) {
      const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const dt = new Date(dtStr)
      return this.$t(weekdays[dt.getDay()])
    },
    getDay (dtStr) {
      const dt = new Date(dtStr)
      return dt.getDate()
    },
    getMonth (dtStr) {
      const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
      const dt = new Date(dtStr)
      return this.$t(months[dt.getMonth()])
    },
    getYear (dtStr) {
      const dt = new Date(dtStr)
      return dt.getFullYear()
    },
    togglePending (booking) {
      const el = document.getElementById('cancel-dropdown-' + booking.id)
      if (el) {
        el.classList.toggle('active')
      }
    },
    canCancel (booking) {
      if (dayjs().add(10, 'm').isBefore(booking.departure)) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.trip-row__actions {
  display: flex;
  flex-direction: row;
  > .dropdown-wrapper {
    margin-left: auto;
  }

  @media (min-width: 75rem) {
    > .dropdown-wrapper {
      margin-left: 1rem;
    }

    > :first-child {
      margin-left: auto;
    }
  }
}

.reservations {
  @media screen and (min-width: 60rem) {
    text-align: left;
    .l-table__row {
      justify-content: flex-start;
    }
    .l-table__columnheader,
    .l-table__cell {
      // text-align: left;

      &:nth-of-type(1) {
        width: 25%;
      }

      &:nth-of-type(2) {
        width: 20%;
      }

      &:nth-of-type(3) {
        width: 40%;
      }

      &:nth-of-type(4) {
        width: 15%;
      }
    }
    .l-table__columnheader {
      &:nth-of-type(4) {
        visibility: hidden;
      }
    }
  }
}
</style>
