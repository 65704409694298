import client from './client'

export async function getNotifications ({ viewed }) {
  const queryString = viewed ? '?viewed=true' : '?viewed=false'
  const response = (await client.get('/notifications' + queryString)).data
  return {
    notifications: response
  }
}

export async function createNotification ({ notification }) {
  const response = await client.post('/notifications', { notification })
  return {
    notification: response.data
  }
}

export async function removeNotification ({ id }) {
  await client.delete(`/notifications/${id}`)
  return {}
}

export async function updateNotification ({ id, viewed }) {
  const response = await client.put(`/notifications/${id}`, { viewed })
  return {
    notification: response.data
  }
}
