export const loggedUser = state => state.loggedUser
export const stops = state => state.stops
export const currentStop = state => state.currentStop
export const holidays = state => state.holidays
export const schedulers = state => state.schedulers

export const trips = state => state.trips
export const currentTrip = state => state.currentTrip

export const notifications = state => state.notifications
export const currentNotification = state => state.currentNotification

export const routes = state => state.routes
export const currentRoute = state => state.currentRoute

export const bookings = state => state.bookings

export const currentValidation = state => state.currentValidation
