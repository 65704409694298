import client from './client'

export async function getStops () {
  const response = await client.get('/stops')
  return {
    stops: response.data
  }
}

export async function getStop ({ id }) {
  const response = await client.get(`/stops/${id}`)
  return {
    stop: response.data
  }
}

export async function createStop ({ stop }) {
  const response = await client.post('/stops', { stop })
  return {
    stop: response.data
  }
}

export async function updateStop ({ stop }) {
  const { id, ...stopWithOutId } = stop
  const response = await client.put(`/stops/${stop.id}`, { stop: stopWithOutId })
  return {
    stop: response.data
  }
}

export async function removeStop ({ id }) {
  await client.delete(`/stops/${id}`)
  return {}
}
