
<!-- eslint-disable vue/no-template-shadow  -->
<div class="schedule-modal">
  <section>
    <section>
      <h1 v-if="mode==='create'">
        {{ $t('scheduler.create') }}
      </h1>
      <h1 v-else-if="mode==='edit'">
        {{ edit.trips.scheduler.name }}
      </h1>
      <h1 v-else-if="mode==='duplicate'">
        {{ $t('header.title.duplicate') }}
      </h1>
      <h1 v-else-if="mode==='recreate'">
        {{ $t('header.title.recreate') }}
      </h1>
    </section>
    <section>
      <h3>{{ $t('scheduler.general') }}</h3>
      <div>
        <label
          id="label-name"
          class="pre-header"
          for="schedule-name"
        >{{ $t('scheduler.create.name') }}</label>
        <p>{{ $t('scheduler.create.name.info') }}</p>

        <input
          id="schedule-name"
          v-model="trips.scheduler.name"
          :placeholder="$t('scheduler.name')"
          type="text"
          class="modal-input"
          name="schedule-name"
          data-filled="false"
        >
        <div
          v-if="submitted"
          :class="{hidden: validName}"
          class="validation warning"
        >
          <p>{{ $t('scheduler.create.name.warning') }}</p>
        </div>
      </div>
      <div class="week-days-input">
        <div class="week-days-input__label">
          <label
            id="label-weekday"
            class="pre-header"
            for="schedule-days"
          >{{ $t('scheduler.create.week') }}</label>
          <p>{{ $t('scheduler.create.week.info') }}</p>
        </div>
        <div
          v-for="day in weekdays"
          :key="day.id"
          class="week-days-input__inputs f-field f-field--checkbox"
        >
          <input
            :id="day.id"
            v-model="trips.scheduler.weekdays"
            :value="day.index"
            type="checkbox"
            class="f-field__checkbox"
            :disabled="isWeekdaysDisabled(day)"
          >
          <label
            class="f-field__label"
            :for="day.id"
          >{{ $t(day.id) }}</label>
        </div>
        <div
          v-if="submitted"
          :class="{hidden: validWeekDay}"
          class="validation warning"
        >
          <p>{{ $t('scheduler.create.week.warning') }}</p>
        </div>
      </div>
      <section>
        <label
          id="label-capacity"
          class="pre-header"
          for="capacity"
        >{{ $t('scheduler.create.capacity') }}</label>
        <p>{{ $t('scheduler.create.capacity.info') }}</p>
        <input
          id="capacity-input"
          v-model="trips.scheduler.capacity"
          type="number"
          class="modal-input"
          step="1"
          min="1"
          max="1000"
        >
        <div
          v-if="submitted"
          :class="{hidden: validCapacity}"
          class="validation warning"
        >
          <p>
            {{ isEditing() ? $t('scheduler.create.capacity.warning').replace(' 1 ', ` ${edit.trips.scheduler.capacity} `) : $t('scheduler.create.capacity.warning') }}
          </p>
        </div>
      </section>
    </section>
    <section>
      <section>
        <h3>{{ $t('scheduler.create.periods') }}</h3>
      </section>
      <section>
        <strong
          id="label-periods"
          class="pre-header"
        >{{ $t('scheduler.create.periods') }}</strong>
        <p>{{ $t('scheduler.create.periods.info') }}</p>
        <table class="cards schedule-table table">
          <thead>
            <tr>
              <th>{{ $t('scheduler.create.periods.start') }}</th>
              <th>{{ $t('scheduler.create.periods.end') }}</th>
              <th class="sr-only">
                {{ $t('actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(period, index) in trips.scheduler.periods"
              :key="period.id"
            >
              <td :data-label="$t('start')">
                <p>{{ new Date(period.start).toLocaleDateString() }}</p>
              </td>

              <td :data-label="$t('end')">
                <p>{{ new Date( period.end).toLocaleDateString() }}</p>
              </td>
              <td
                :data-label="$t('actions')"
                class="actions"
              >
                <template v-if="!isPeriodDisabled(index)">
                  <date-picker
                    v-model="trips.scheduler.periods[index]"
                    :ignore="periodsWithoutOne(index)"
                    name="notificationDate"
                    :range-mode="true"
                  >
                    <button
                      slot="trigger"
                      class="btn btn--sm"
                    >
                      {{ $t('pick.a.date') }}
                    </button>
                  </date-picker>
                  <button
                    class="button small circle delete"
                    @click.prevent="removePeriod(index)"
                  >
                    {{ $t('delete') }}
                  </button>
                </template>
              </td>
            </tr>
            <tr v-if="showNewPeriod">
              <td :data-label="$t('start')">
                <p class="disabled">
                  {{ $t('pick.a.date') }}
                </p>
              </td>
              <td :data-label="$t('end')">
                <p class="disabled">
                  {{ $t('pick.a.date') }}
                </p>
              </td>
              <td
                :data-label="$t('actions')"
                class="actions"
              >
                <date-picker
                  v-model="pickedDateBetween"
                  :range="true"
                  :before="false"
                  :range-mode="true"
                  :ignore="trips.scheduler.periods"
                  name="periodInsert"
                >
                  <button
                    slot="trigger"
                    class="btn btn--sm"
                  >
                    {{ $t('define.date') }}
                  </button>
                </date-picker>
                <button

                  class="button small circle delete disabled"
                  @click.prevent="showNewPeriod = false"
                >
                  {{ $t('delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          :disabled="showNewPeriod"
          class="btn"
          @click="addPeriod()"
        >
          {{ $t('add.new.date.range') }}
        </button>
        <div
          v-if="submitted"
          :class="{hidden: validDateRange}"
          class="validation warning"
        >
          <p>{{ $t('you.need.to.define.at.least.one.date.range') }}</p>
        </div>
      </section>
      <section v-if="!isEditing() || trips.scheduler.excludedDays.length > 0">
        <section>
          <h3>{{ $t('exclude.the.following.days') }}</h3>
        </section>
        <section>
          <strong class="pre-header">{{ $t('exclude.the.following.days') }}</strong>
          <p>{{ $t('scheduler.create.excludedDays.info') }}</p>
          <table class="cards schedule-table excluded-days table">
            <thead>
              <tr>
                <th>{{ $t('day') }}</th>
                <th class="sr-only">
                  {{ $t('actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(excludedDay, index) in trips.scheduler.excludedDays"
                :key="index"
              >
                <td :data-label="$t('start')">
                  <p>{{ new Date(excludedDay).toLocaleDateString() }}</p>
                </td>
                <td
                  :data-label="$t('actions')"
                  class="actions"
                >
                  <button
                    :disabled="isEditing()"
                    class="button small circle delete"
                    :class="{disabled: isEditing()}"
                    @click.prevent="removeExcludedDay(index)"
                  >
                    {{ $t('delete') }}
                  </button>
                </td>
              </tr>
              <tr v-if="!isEditing()">
                <td :data-label="$t('start')">
                  <p class="disabled">
                    {{ $t('pick.a.date') }}
                  </p>
                </td>
                <td
                  :data-label="$t('actions')"
                  class="actions"
                >
                  <date-picker
                    v-model="excludedDay"
                    name="excludedDayInsert"
                  >
                    <button
                      slot="trigger"
                      class="btn btn--sm"
                    >
                      {{ $t('define.excluded.day') }}
                    </button>
                  </date-picker>
                  <button
                    class="button small circle delete disabled"
                    @click.prevent="showNewExcludedDay = false"
                  >
                    {{ $t('delete') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>
    </section>
    <section>
      <section id="label-departures">
        <h3>{{ $t('route.and.departure.times') }}</h3>
      </section>
      <section>
        <strong class="pre-header">{{ $t('route.and.departure.times') }}</strong>
        <p>{{ $t('add.the.departure.times.for.this.scheduler') }}</p>
      </section>
      <div
        v-for="(feature, index) in trips.routes"
        :key="index"
        class="card list route"
      >
        <div class="card-row select-route">
          <div class="select-wrapper">
            <select
              v-model="feature.route"
              class="modal-input"
              :disabled="isRouteDisabled(index)"
            >
              <option
                v-for="(route, index) in routes"
                :key="index"
                :value="route.id"
              >
                {{ generateRouteName(route) }}
              </option>
            </select>
          </div>
          <div class="select-route__actions">
            <button
              v-if="!isRouteDisabled(index)"
              class="btn--danger btn--sm"
              @click="removeRoute(index)"
            >
              {{ $t('delete') }}
            </button>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row__text">
            <div class="f-tag-field__tags">
              <div
                v-for="(departure, departureIndex) in feature.departures"
                :key="departure.id"
                class="f-tag-field__tag f-tag-field__tag--blue f-tag-field__tag--rounded"
              >
                <input
                  id="time"
                  v-model="feature.departures[departureIndex]"
                  class=""
                  type="time"
                  required
                  :disabled="isFeatureDisabled(index, departureIndex)"
                >
                <button
                  v-if="!isFeatureDisabled(index, departureIndex)"
                  class="f-tag-field__tag-remove"
                  @click="removeDeparture(index, departureIndex)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke-width="1.16667"
                      transform="translate(.33333 .33333)"
                    >
                      <circle
                        cx="6.66667"
                        cy="6.66667"
                        r="6.08333"
                        class="circle--stroke"
                        stroke="#C9CDD4"
                        fill="transparent"
                      />
                      <path
                        class="cross--stroke"
                        stroke="#8F95A1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.70588 4.70588l3.88216 3.88216m0-3.88216L4.70588 8.58804"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="card-row__meta">
            <button
              class="btn btn--primary btn--sm"
              @click="addDeparture(index)"
            >
              {{ $t('add.departure') }}
            </button>
          </div>
        </div>
      </div>
      <section>
        <button
          class="btn"
          @click="addFeature"
        >
          + Adicionar nova rota
        </button>
      </section>
      <div
        v-if="submitted"
        :class="{hidden: allRoutesHaveDepartures}"
        class="validation warning"
      >
        <p>{{ $t('you.need.to.define.at.least.the.departure.time.for.the.route') }}</p>
      </div>
    </section>
  </section>

  <div class="btn--group">
    <button
      class="btn"
      :disabled="loading"
      @click.prevent="isModalOpen = false"
    >
      {{ $t('cancel') }}
    </button>
    <button
      class="btn btn--primary"
      :disabled="loading"
      @click.prevent="createTrips"
    >
      {{ $t('save') }}
    </button>
  </div>
</div>
