
<div>
  <section>
    <div class="list-stops-header">
      <h1 style="margin: 0;">
        {{ $t('stops') }}
      </h1>
      <div class="btn--group list-stops-header__meta">
        <router-link
          :to="{ name: 'ListRoutesPage' }"
          class="btn"
        >
          {{ $t('stops.back') }}
        </router-link>
      </div>
    </div>
    <div class="stops-list">
      <div
        class="card"
      >
        <div
          v-for="stop in stops"
          :key="stop.id"
          class="card-row"
        >
          <div class="card-row__text">
            <strong>{{ stop.name }}</strong>
          </div>
          <div class="card-row__meta">
            <div class="btn--group">
              <button
                class="btn btn--danger btn--sm"
                @click="deleteStop(stop)"
              >
                {{ $t('remove') }}
              </button>
              <button
                class="btn btn--sm"
                @click="openModal('EditStop', { stop })"
              >
                {{ $t('edit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn btn--primary"
      @click="openModal('CreateStop')"
    >
      {{ $t('create.stop') }}
    </button>
  </section>

  <Modal
    v-model="modalOpen"
    class="modal--lg"
  >
    <template v-if="modalName === 'EditStop'">
      <edit-stop
        slot="modal-panel"
        v-model="modalOpen"
        :stop="modalData.stop"
        @submit="submitEditStop"
      />
    </template>
    <template v-else-if="modalName === 'CreateStop'">
      <create-stop
        slot="modal-panel"
        v-model="modalOpen"
        @submit="submitCreation"
      />
    </template>
  </Modal>

  <Modal v-model="deleteModalOpen">
    <delete-modal
      slot="modal-panel"
      v-model="deleteModalOpen"
      :title="$t('delete.this.stop')"
      :body="$t('delete.this.stop.description')"
      :confirm="$t('yes.delete.this.stop')"
      :cancel="$t('no.keep.this.stop')"
      @submit="submitDelete"
    />
  </Modal>
  <Modal v-model="submitModal">
    <Component
      :is="submitState.type === 'success' ? 'SuccessModal' : 'ErrorModal'"
      slot="modal-panel"
      v-model="submitModal"
      :title="submitState.title"
      :body="submitState.body"
      :close="submitState.close"
    />
  </Modal>
</div>
