export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS'
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION'
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const FETCH_TRIPS = 'FETCH_TRIPS'
export const RECEIVE_TRIPS = 'RECEIVE_TRIPS'

export const FETCH_ROUTES = 'FETCH_ROUTES'
export const RECEIVE_ROUTES = 'RECEIVE_ROUTES'
export const FETCH_ROUTE = 'FETCH_ROUTE'
export const RECEIVE_ROUTE = 'RECEIVE_ROUTE'
export const ADD_ROUTE = 'ADD_ROUTE'
export const EDIT_ROUTE = 'EDIT_ROUTE'
export const REMOVE_ROUTE = 'REMOVE_ROUTE'

export const FETCH_STOPS = 'FETCH_STOPS'
export const RECEIVE_STOPS = 'RECEIVE_STOPS'
export const FETCH_STOP = 'FETCH_STOP'
export const RECEIVE_STOP = 'RECEIVE_STOP'
export const ADD_STOP = 'ADD_STOP'
export const REMOVE_STOP = 'REMOVE_STOP'

export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS'
export const RECEIVE_HOLIDAYS = 'RECEIVE_HOLIDAYS'

export const FETCH_SCHEDULERS = 'FETCH_SCHEDULERS'
export const RECEIVE_SCHEDULERS = 'RECEIVE_SCHEDULERS'
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'

export const RECEIVE_TICKET = 'RECEIVE_TICKET'
export const RECEIVE_VALIDATION = 'RECEIVE_VALIDATION'

export const SEND_EMAIL = 'SEND_EMAIL'
export const RECEIVED_ERRORS = 'RECEIVED_ERRORS'
