
<footer
  id="footer"
  class="layout-footer"
>
  <div class="container">
    <nav class="footer-nav">
      <ul>
        <li>
          <a
            href="https://tecnico.ulisboa.pt/pt/sobre-o-tecnico/"
            rel="noopener"
            target="_blank"
          >{{ $t('about.tecnico') }}</a>
        </li>
        <li>
          <a
            href="https://agaft.tecnico.ulisboa.pt/shuttle/"
            rel="noopener"
            target="_blank"
          >{{ $t('schedules.and.regulation') }}</a>
        </li>
        <li>
          <a
            target="_self"
            href="mailto:dsi@tecnico.ulisboa.pt"
          >{{ $t('support') }}</a>
        </li>
      </ul>
      <p class="copyright">
        2018 © <a
          href="https://tecnico.ulisboa.pt/"
          rel="noopener"
          target="_blank"
        >Instituto Superior Técnico</a>, <a
          href="https://www.ulisboa.pt/"
          rel="noopener"
          target="_blank"
        >Universidade de Lisboa</a>
      </p>
    </nav>
  </div>
</footer>
