
<div
  v-if="!nonEditable"
  ref="datePicker"
  :name="name"
>
  <div
    @click.stop="toggle"
  >
    <slot
      name="trigger"
      :on-blur="onBlur"
      :disabled="disabled"
      :open-date-picker="openDatePicker"
      :close-date-picker="closeDatePicker"
    >
      <input
        ref="input"
        :value="value"
        :name="name"
        :placeholder="inputPlaceholder"
        :disabled="disabled"
        type="text"
        class="f-field__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        @focus="openDatePicker"
        @blur="onBlur"
        @click="openDatePicker"
        @input="$emit('input', $event.target.value)"
        @keyup.enter.prevent="closeDatePicker"
      >
      <label
        v-if="label"
        :for="name"
        :class="{ 'f-field__label--lg-margin': variant === 'outlined' }"
        class="f-field__label"
      >
        {{ label }}
      </label>
    </slot>
  </div>

  <div
    class="date-picker"
    :name="name"
    :class="{ 'open': open }"
  >
    <div class="upper-arrow" />
    <div class="days">
      <header class="date-picker-header">
        <button
          class="pagination left"
          @click.prevent="prevYear"
        />
        <div
          v-if="year"
          class="year-wrapper"
        >
          <h4
            v-if="!isYearBeingEdited"
            :class="{ 'year--editable': isYearBeingEdited }"
            class="year"
            @click.prevent="editYear"
          >
            {{ year }}
          </h4>
          <input
            v-if="isYearBeingEdited"
            v-model="yearBeingEdited"
            type="text"
            class="f-field__input"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            @blur="setEditedYear"
            @keydown.enter.prevent="setEditedYear"
          >
        </div>
        <button
          class="pagination right"
          @click.prevent="nextYear"
        />
      </header>
      <header class="date-picker-header">
        <button
          class="pagination left"
          @click.prevent="prevMonth"
        />
        <h4>{{ month }}</h4>
        <button
          class="pagination right"
          @click.prevent="nextMonth"
        />
      </header>
      <div class="calendar">
        <header>
          <span>{{ $t('weekdays.initials.sunday') }}</span>
          <span>{{ $t('weekdays.initials.monday') }}</span>
          <span>{{ $t('weekdays.initials.tuesday') }}</span>
          <span>{{ $t('weekdays.initials.wednesday') }}</span>
          <span>{{ $t('weekdays.initials.thursday') }}</span>
          <span>{{ $t('weekdays.initials.friday') }}</span>
          <span>{{ $t('weekdays.initials.saturday') }}</span>
        </header>
        <div class="main">
          <div
            v-for="week in weeks"
            :key="generateWeekId(week)"
            class="row"
          >
            <button
              v-for="day in week"
              :key="generateDayId(day)"
              :class="{
                selected: day.selectedDayEdges && day.isInSelectedMonth,
                today: day.isToday,
                disabled: !day.isInSelectedMonth || day.ignoreDay || (day.isBeforeToday && !before),
                between: !day.ignoreDay && day.selectedDayBetween && day.isInSelectedMonth
              }"
              @click.prevent="select(day)"
              @mouseover="hoveredDate = day.date"
              @mouseleave="hoveredDate = undefined"
            >
              {{ day.dayOfTheMonth }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="time"
      class="hours"
    >
      <div
        v-if="!rangeMode"
        class="hour"
      >
        <label for="time">
          {{ $t('time') }}:
        </label>
        <input
          id="time"
          v-model="selectedTimeStart"
          class="time-picker"
          type="time"
        >
      </div>
      <template v-else-if="rangeMode && time">
        <div class="hour">
          <label for="timeStart">
            {{ $t('timeStart') }}:
          </label>
          <input
            id="timeStart"
            v-model="selectedTimeStart"
            class="time-picker"
            type="time"
          >
        </div>
        <div class="hour">
          <label for="timeEnd">
            {{ $t('timeEnd') }}:
          </label>
          <input
            id="timeEnd"
            v-model="selectedTimeEnd"
            class="time-picker"
            type="time"
          >
        </div>
      </template>
    </div>
    <button
      v-if="time"
      class="btn btn--primary"
      @click="emitResult"
    >
      {{ $t('save') }}
    </button>
  </div>
</div>
<div
  v-else
  class="f-field"
>
  <p>
    {{ value }}
  </p>
  <label
    v-if="label"
    class="f-field__label f-field__label--lg-margin"
  >
    {{ label }}
  </label>
</div>
