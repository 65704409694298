import client from './client'
export async function getBookings ({ trip, start, end, user, tripState, state, page, itemsPerPage }) {
  let queryString = ''
  let hasVariable = false
  if (trip || start || end || user || tripState || state) {
    queryString += '?'
  }
  if (trip) {
    queryString += `trip=${trip}`
    hasVariable = true
  }
  if (start) {
    queryString += hasVariable ? '&' : ''
    queryString += `start=${start}`
    hasVariable = true
  }
  if (end) {
    queryString += hasVariable ? '&' : ''
    queryString += `end=${end}`
    hasVariable = true
  }
  if (user) {
    queryString += hasVariable ? '&' : ''
    queryString += `user=${user}`
    hasVariable = true
  }
  if (tripState) {
    queryString += hasVariable ? '&' : ''
    queryString += `tripState=${tripState}`
    hasVariable = true
  }
  if (state) {
    queryString += hasVariable ? '&' : ''
    queryString += `state=${state}`
    hasVariable = true
  }

  if (page) {
    queryString += hasVariable ? '&' : ''
    queryString += `page=${page}`
    hasVariable = true
  }
  if (itemsPerPage) {
    queryString += hasVariable ? '&' : ''
    queryString += `itemsPerPage=${itemsPerPage}`
  }

  return (await client.get(`/bookings${queryString}`)).data
}

export async function createBooking ({ trip }) {
  const response = await client.post('/bookings', { trip })
  return response.data
}

export async function removeBooking ({ trip }) {
  await client.delete(`/bookings/${trip}`)
  return true
}

export async function createExternalBooking ({ trip, externalUserEmail, externalUserName }) {
  const response = await client.post('/bookings/external', { trip, externalUserEmail, externalUserName })
  return response.data
}

export async function removeExternalBooking ({ trip, externalUserEmail }) {
  await client.delete(`/bookings/external/${trip}/${externalUserEmail}`)
  return true
}
