import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  apiRequests: {},
  loggedUser: {},
  stops: [],
  currentStop: undefined,
  routes: [],
  currentRoute: undefined,
  holidays: [],
  schedulers: [],
  trips: [],
  notifications: [],
  currentNotification: undefined,
  bookingsPage: [],
  currentValidation: {}
}

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

export default store
