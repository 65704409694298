import Notifications from '@/components/notifications/Notifications'
import WithTopBarLayout from '@/layouts/WithTopBarLayout'
import AdminListTripsPage from '@/pages/AdminListTripsPage'
import ListRoutesPage from '@/pages/ListRoutesPage'
import ListSchedulersPage from '@/pages/ListSchedulersPage'
import ListTripsPage from '@/pages/ListTripsPage'
import MyTicketsPage from '@/pages/MyTicketsPage'
import ReportsPage from '@/pages/ReportsPage.vue'
import ListStops from '@/pages/ListStops.vue'
import ManageTripPage from '@/components/trips/ManageTrip.vue'

import store from '@/store'
import Vue from 'vue'
import Router from 'vue-router'
import { getTrip } from '@/api/trips'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    // allows saving position when navigation happens between same route
    if (to.meta?.scrollBehavior?.routeUpdate === 'savedPosition' && to.path === from.path) {
      return savedPosition
    }
    // allows saving position when navigation happens between different routes
    if (to.meta?.scrollBehavior?.routeEnter === 'savedPosition' && to.path !== from.path) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/ticket-validator',
      name: 'TicketValidator',
      component: () => import(/* webpackChunkName: "ticket-validator" */'@/components/tickets/TicketValidator')
    },
    {
      path: '',
      component: WithTopBarLayout,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('fetchProfile')
        next()
      },
      children: [
        {
          path: '/',
          name: 'ListTripsPage',
          component: ListTripsPage
        },
        {
          path: '/stops',
          name: 'ListStops',
          component: ListStops
        },
        {
          path: '/trips/:id',
          name: 'ManageTrip',
          component: ManageTripPage,
          props: (route) => {
            return {
              trip: route.params.trip
            }
          },
          beforeEnter: async (to, from, next) => {
            if (!to.params.trip) {
              const trip = await getTrip({ id: decodeURIComponent(to.params.id) })
              to.params.trip = trip
            }
            next()
          }
        },
        {
          path: '/trips',
          name: 'AdminListTripsPage',
          component: AdminListTripsPage
        },
        {
          path: '/tickets',
          name: 'MyTicketsPage',
          component: MyTicketsPage
        },
        {
          path: '/routes',
          name: 'ListRoutesPage',
          component: ListRoutesPage
        },
        {
          path: '/scheduler',
          name: 'ListSchedulersPage',
          component: ListSchedulersPage
        },
        {
          path: '/notifications',
          name: 'Notifications',
          component: Notifications
        },
        {
          path: '/reports',
          name: 'ReportsPage',
          component: ReportsPage
        }
      ]
    }]
})

export default router
