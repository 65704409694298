import translationsPt from '@/i18n/translations-pt.json'
import translationsEn from '@/i18n/translations-en.json'
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import Axios from 'axios'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import 'dayjs/locale/pt'

import updateLocale from 'dayjs/plugin/updateLocale'
import IsBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

// Add portuguese calendar locales
dayjs.extend(updateLocale)
dayjs.updateLocale('pt', {
  calendar: {
    lastDay: '[Ontem às ] HH:mm',
    sameDay: '[Hoje às ] HH:mm',
    nextDay: '[Amanhã às ] HH:mm',
    lastWeek: '[Última ] dddd [às ] HH:mm',
    nextWeek: 'dddd [ às ] HH:mm',
    sameElse: 'DD/MM/YYYY [ às ] HH:mm'
  }
})

dayjs.extend(minMax)
dayjs.extend(IsBetween)
dayjs.extend(calendar)
dayjs.extend(LocalizedFormat)
Vue.use(VueI18n)

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  locale: 'pt',
  messages: {
    pt: translationsPt,
    en: translationsEn
  }
})

export function getLanguage () {
  const language = window.localStorage.getItem('language') || window.navigator.language.split('-')[0]
  return language === 'pt' ? 'pt' : 'en'
}

export function setLocale (lang) {
  i18n.locale = lang
  dayjs.locale(lang)
  window.localStorage.setItem('language', lang)
  Axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
}

setLocale(getLanguage())
