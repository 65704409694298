<template>
  <!-- eslint-disable vue/no-template-shadow  -->
  <div>
    <section>
      <section class="text-centered">
        <i class="icon">
          <svg
            width="62"
            height="57"
            viewBox="0 0 62 57"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#009DE0"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
            >
              <path d="M36.643 54.814v-9.699l.838-.137C51.164 42.738 61 33.684 61 23.25 61 11.034 47.62 1 31 1 14.38 1 1 11.034 1 23.25c0 10.647 10.238 19.834 24.292 21.847l.293.042 11.058 9.675z" />
              <path d="M15.5 17.714h14.365" />
              <path d="M15.5 23.25h32.107" />
              <path d="M15.5 28.786h32.107" />
            </g>
          </svg>
        </i>
        <h1>{{ $t('notifications') }}</h1>
        <p>{{ $t('notifications.description') }}</p>
      </section>

      <section>
        <h3>{{ $t('create.notification') }}</h3>

        <section>
          <label
            class="pre-header"
            for="create-new-notification"
          >{{ $t('notifications.message.in.pt') }}</label>
          <textarea
            id="create-new-notification-pt"
            v-model="notification.message.pt"
            :placeholder="$t('write.a.message.in.pt')"
            name="create-new-notification"
            class="modal-input available-seats"
          />
          <label
            class="pre-header"
            for="create-new-notification"
          >{{ $t('notifications.message.in.en') }}</label>
          <textarea
            id="create-new-notification-en"
            v-model="notification.message.en"
            :placeholder="$t('write.a.message.in.en')"
            name="create-new-notification"
            class="modal-input available-seats"
          />
        </section>

        <section>
          <strong class="pre-header">{{ $t('notification.expiration') }}</strong>
          <div class="expiration-date">
            <p
              v-if="!notification.validity"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(notification.validity).toLocaleString($i18n.locale) }}
            </p>

            <date-picker
              v-model="notification.validity"
              class="expiration-date__pick"
              name="notificationDate"
              :time="true"
            >
              <button
                slot="trigger"
                class="btn btn--small expiration-date__pick"
              >
                {{ $t('notifications.date.define') }}
              </button>
            </date-picker>
          </div>

          <div
            v-if="submitted"
            :class="{hidden: notification.validity}"
            class="validation warning"
          >
            <p>{{ $t('you.need.to.define.an.expiration.time.for.the.notification') }}</p>
          </div>
        </section>

        <section>
          <button
            class="btn btn--primary"
            @click="create()"
          >
            {{ $t('notifications.publish') }}
          </button>
        </section>
      </section>

      <section v-if="activeNotifications.length > 0">
        <h3>{{ $t('notifications.active') }}</h3>
        <div
          v-for="notification in activeNotifications"
          :key="notification.id"
          class="notification-list"
        >
          <div class="notification-list__text">
            <strong>{{ notification.message[$i18n.locale] }}</strong>
            <p class="small">
              {{ $t('notifications.visible', { date: getCalendar(notification) }) }}
            </p>
          </div>
          <div class="notification-list__actions">
            <button
              class="button circle delete"
              @click="remove(notification)"
            >
              {{ $t('delete') }}
            </button>
          </div>
        </div>
      </section>

      <section v-if="oldNotifications.length > 0">
        <h3>{{ $t('notifications.history') }}</h3>
        <div
          v-for="notification in oldNotifications"
          :key="notification.id"
          class="notification-list"
        >
          <div class="notification-list__text">
            <strong>{{ notification.message[$i18n.locale] }}</strong>
            <p class="small">
              {{ $t('notifications.visible', { date: getCalendar(notification) }) }}
            </p>
          </div>
        </div>
      </section>
    </section>
    <!-- All possible modals -->
    <Modal v-model="deleteModalOpen">
      <delete-modal
        slot="modal-panel"
        v-model="deleteModalOpen"
        :title="$t('delete.this.notifications')"
        :body="$t('delete.this.notifications.description')"
        :confirm="$t('yes.delete.this.notifications')"
        :cancel="$t('no.keep.this.notifications')"
        @submit="submitDelete"
      />
    </Modal>
    <Modal v-model="stateModalOpen">
      <Component
        :is="modalState.type"
        slot="modal-panel"
        v-model="stateModalOpen"
        :title="modalState.title"
        :body="modalState.body"
        :close="modalState.close"
      />
    </Modal>
  </div>
</template>

<script>
import DatePicker from '@/components/utils/DatePicker.vue'
import SuccessModal from '@/components/utils/SuccessModal.vue'
import ErrorModal from '@/components/utils/ErrorModal.vue'
import DeleteModal from '@/components/utils/DeleteModal.vue'
import Modal from '@/components/utils/Modal.vue'

import { createNotification, getNotifications, removeNotification } from '@/api/notifications'
import dayjs from 'dayjs'

export default {
  name: 'Notifications',
  components: {
    DatePicker,
    Modal,
    SuccessModal,
    ErrorModal,
    DeleteModal
  },
  data () {
    return {
      months: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
      ],
      notification: {
        message: {
          pt: '',
          en: ''
        },
        validity: null
      },
      submitted: false,
      datePickerOpen: false,
      stateModalOpen: false,
      modalState: {},
      deleteModalOpen: false,
      notificationToDelete: {},
      notifications: []
    }
  },
  computed: {
    oldNotifications: function () {
      if (!this.notifications) return []

      return this.notifications.filter((notification) => {
        return dayjs(notification.validity).isBefore(dayjs())
      })
    },
    activeNotifications: function () {
      if (!this.notifications) return []

      return this.notifications.filter((notification) => {
        return dayjs(notification.validity).isAfter(dayjs())
      })
    }
  },
  async created () {
    this.notifications = await (await getNotifications({ viewed: false })).notifications
  },
  methods: {
    getCalendar: function (notification) {
      return dayjs(notification.validity).format('DD-MM-YYYY HH:mm')
    },
    getTime: function (notification) {
      // Problem with local time
      const dt = new Date(notification.validity)
      return dt.getUTCHours() + ':' + dt.getUTCMinutes()
    },
    async create () {
      this.submitted = true
      if (this.notification.message.pt.length > 0 && this.notification.message.en.length > 0 && this.notification.validity !== 0) {
        try {
          await createNotification({ notification: this.notification })
          this.notifications = (await getNotifications({ viewed: false })).notifications
          this.stateModalOpen = true
          this.modalState = {
            type: 'SuccessModal',
            title: 'Notificação criada com sucesso',
            body: 'Notificação criada com sucesso',
            close: 'Voltar'
          }
        } catch (err) {
          this.stateModalOpen = true
          this.modalState = {
            type: 'ErrorModal',
            title: 'Não foi possível criada a notificação',
            body: 'Não foi possível criada a notificação',
            close: 'Voltar'
          }
        }
        this.submitted = false
        this.notification = {
          message: {
            pt: '',
            en: ''
          },
          validity: 0
        }
      }
    },
    async remove (notification) {
      this.notificationToDelete = notification
      this.deleteModalOpen = true
    },
    async submitDelete () {
      try {
        await removeNotification({ id: this.notificationToDelete.id })
        this.notifications = (await getNotifications({ viewed: false })).notifications
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: 'Notificação apagada com sucesso',
          body: 'Notificação apagada com sucesso',
          close: 'Voltar'
        }
      } catch (err) {
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Não foi possível apagar a notificação',
          body: 'Não foi possível apagar a notificação',
          close: 'Voltar'
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.notification-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1.5rem;
  margin: 10px 0;
  box-shadow: 0 0 20px 0 rgba(0 0 0 / 10%);
}
.notification-list__text {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  p {
    margin: 8px 0 0;
    font-size: 0.75rem;
  }
}
.notification-list__action {
  margin-left: auto;
  .button.delete {
    margin-top: 24px;
    @media (min-width: 1200px) {
      transform: rotate(45deg);
      margin: 0;
    }
  }
}

.expiration-date {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  padding: 1rem 0;
  margin-top: 0.5rem;
}

.expiration-date__pick {
  position: relative;
  margin-top: 0 !important;
  margin-left: auto;
}

</style>
