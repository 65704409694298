<template>
  <div class="card">
    <div class="card-row">
      <div class="card-row__text">
        <div class="modal cancel-ticket">
          <i class="icon">
            <svg
              width="72"
              height="83"
              viewBox="0 0 72 83"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                stroke="#009DE0"
                stroke-width="2"
                fill="none"
                fill-rule="evenodd"
              >
                <path d="M51.69 54.253a13.976 13.976 0 0 0-8.067 9.27 13.976 13.976 0 0 0 2.351 12.062l-1.626 6.07-43.12-11.554 12.103-45.165c2.88.183 5.58-1.658 6.348-4.525.768-2.868-.649-5.811-3.235-7.093l3.208-11.973 43.12 11.554-3.21 11.973c-2.88-.183-5.578 1.657-6.347 4.525-.768 2.867.649 5.81 3.235 7.092l-4.76 17.764z" />
                <path
                  d="M23.272 21.373l26.35 7.06"
                  stroke-dasharray="4.92 2.46"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M30.089 48.632l-8.335 2.943 4.64-7.458-5.439-6.962 8.614 2.106 4.972-7.244.684 8.76 8.512 2.483-8.191 3.309.29 8.779z"
                />
                <path d="M12.154 62.864l26.35 7.06" />
                <path d="M13.743 56.937l26.35 7.06" />
                <g transform="translate(43 53)">
                  <circle
                    cx="14"
                    cy="14"
                    r="14"
                  />
                  <g
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      class="question mark"
                      d="M18.485 18.485L10 10c0-2.5 1.985-4 4.243-4 2.257 0 4.242 2 4.242 4 0 2.282-1.414 3.782-4.242 4.5v2"
                    />
                    <path
                      class="question dot"
                      d="M14.243 20.5L10 18.485 18.485 10"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </i>

          <h1 class="cancel-ticket__header">
            {{ $t('cancel.booking') }}
          </h1>

          <p class="cancel-ticket__paragraph">
            {{ $t('cancel.booking.confirmation.message') }}
          </p>
          <p class="cancel-ticket__paragraph">
            {{ $t('are.you.sure.you.want.to.continue') }}
          </p>

          <div class="cancel-ticket__actions">
            <button
              class="btn btn--primary"
              @click="bookingCancellation()"
            >
              {{ $t('yes.cancel.my.booking') }}
            </button>

            <button
              class="btn"
              @click="isModalOpen=false"
            >
              {{ $t('no.keep.my.booking') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CancelBooking',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    trip: {
      type: Object,
      required: true
    },
    userBookings: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapState(['loggedUser']),
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }

  },
  async mounted () {
    this.open = true
  },
  methods: {
    getOrigin () {
      const route = this.trip.route
      return route.stops[0].name
    },
    getDestiny () {
      const route = this.trip.route
      const routeSize = route.stops.length
      return route.stops[routeSize - 1].name
    },
    bookingCancellation () {
      this.$emit('submit', this.trip)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.question.mark {
  animation: question-mark 4s infinite;
}
.question.dot {
  animation: question-dot 4s infinite;
}

.cancel-ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cancel-ticket__header {
  width: 100%;
  font-size: 27px;
  margin: 0 0 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $light-gray;
}

.cancel-ticket__paragraph {
  margin: 1.5rem 0;
  font-size: 14px;
}

.cancel-ticket__paragraph + .cancel-ticket__paragraph {
  margin-top: 0;
}

.cancel-ticket__actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    margin: 0.75rem 0;
    width: 100%;
  }
}
</style>
