<template>
  <div>
    <section>
      <h1>{{ $t('edit.stop') }}</h1>
      <section>
        <label
          class="h4"
          for="station-name"
        >{{ $t('stop.name') }}</label>
        <p>{{ $t('stop.name.info') }}</p>
        <input
          id="station-name"
          v-model="stopData.name"
          type="text"
          class="modal-input"
          name="station-name"
          data-filled="false"
          :placeholder="$t('stop.name')"
        >
        <div class="validation warning hidden">
          <p>{{ $t('stop.name.warning') }}</p>
        </div>
      </section>
      <section>
        <label
          class="h4"
          for="stop-address"
        >
          {{ $t('stop.address.label') }}
        </label>
        <p>{{ $t('stop.address.info') }}</p>
        <input
          id="stop-address"
          v-model="stopData.address"
          :placeholder="$t('stop.address.label')"
          class="modal-input"
          type="text"
        >
      </section>
      <section>
        <strong class="pre-header">{{ $t('stop.location') }}</strong>
        <p>{{ $t('stop.location.info') }}</p>
        <gmap-map
          :center="stopData.location"
          :zoom="16"
          style="width: 600px; height: 300px;"
        >
          <gmap-marker
            :position="stopData.location"
            :clickable="true"
            :draggable="true"
            @dragend="getMarkerPosition($event.latLng)"
          />
        </gmap-map>
      </section>
    </section>
    <div class="btn--group">
      <button
        class="btn"
        @click="isModalOpen = false"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="btn btn--primary"
        @click="$emit('submit', stopData)"
      >
        {{ $t('edit.stop') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EditStop',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    stop: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      id: null,
      stopData: JSON.parse(JSON.stringify(this.stop))
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.id = this.stop.id
  },
  methods: {
    ...mapActions([
      'fetchStop',
      'editStop',
      'fetchStops'
    ]),
    getMarkerPosition (event) {
      const location = { lat: event.lat(), lng: event.lng() }
      this.stopData.location = location
    }
  },
  i18n: {
    messages: {
      pt: {
        stop: {
          address: {
            label: 'Endereço da paragem',
            info: 'O endereço completo da localização da paragem'
          }
        }
      },
      en: {
        stop: {
          address: {
            label: 'Address of the stop',
            info: 'The full address for this stop'
          }
        }
      }
    }
  }
}
</script>
