<template>
  <section>
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('scheduler') }}
        </h1>
      </div>
      <div class="section-header__meta">
        <div class="btn--group">
          <button
            class="btn btn--primary btn--sm"
            @click.prevent="showCreateSchedulerModal()"
          >
            {{ $t('scheduler.create') }}
          </button>
        </div>
      </div>
    </header>
    <div class="scheduler-filter">
      <label for="date-filter">{{ $t('filter.month.define') }}</label>
      <input
        id="date-filter"
        v-model="selectedMonth"
        type="date"
        @input="fetchSchedulersForMonth"
      >
      <button
        v-show="selectedMonth"
        class="scheduler-filter__reset u-text-secondary"
        @click="selectedMonth = null; fetchSchedulersForMonth()"
      >
        {{ $t('clear.filter') }}
      </button>
    </div>
    <table
      v-if="schedulers.length > 0"
      class="cards admin calendar table"
    >
      <thead>
        <tr>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('scheduler.create.periods') }}</th>
          <th>{{ $t('days') }}</th>
          <th class="sr-only">
            {{ $t('actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="scheduler in schedulers"
          :key="scheduler.trips.scheduler.id"
        >
          <td
            :data-label="$t('name')"
            class="scheduler-name"
          >
            {{ scheduler.trips.scheduler.name }}
          </td>
          <td :data-label="$t('date.periods')">
            <time
              v-for="period in scheduler.trips.scheduler.periods"
              :key="period.id"
            >{{ getDate(period.start, period.end) }}
            </time>
          </td>
          <td :data-label="$t('days')">
            <p>{{ getDays(scheduler.trips.scheduler.weekdays) }}</p>
          </td>
          <td :data-label="$t('actions')">
            <Dropdown>
              <button
                slot="dropdown-trigger"
                style="margin-left: auto;"
                class="i-ellipsis"
                aria-label="More options"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <ul
                slot="dropdown-panel"
                class="dropdown-menu"
              >
                <li class="dropdown-menu__item">
                  <button
                    class="dropdown-menu__link"
                    @click="showEditSchedulerModal(scheduler)"
                  >
                    {{ $t('edit') }}
                  </button>
                </li>
                <li class="dropdown-menu__item">
                  <button
                    class="dropdown-menu__link"
                    @click="showDuplicateSchedulerModal(scheduler)"
                  >
                    {{ $t('duplicate') }}
                  </button>
                </li>
                <li class="dropdown-menu__item">
                  <button
                    class="dropdown-menu__link"
                    @click="showRecreateSchedulerModal(scheduler)"
                  >
                    {{ $t('recreate') }}
                  </button>
                </li>
                <li class="dropdown-menu__item">
                  <button
                    class="dropdown-menu__link u-text-danger"
                    @click="removeScheduler(scheduler)"
                  >
                    {{ $t('delete') }}
                  </button>
                </li>
              </ul>
            </Dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <h2 v-else>
      {{ $t('no.schedulers.defined.yet') }}
    </h2>
    <!-- Modals -->
    <Modal v-model="deleteModalOpen">
      <delete-modal
        slot="modal-panel"
        v-model="deleteModalOpen"
        :title="$t('delete.this.scheduler')"
        :body="$t('delete.this.scheduler.description')"
        :confirm="$t('yes.delete.this.scheduler')"
        :cancel="$t('no.keep.this.scheduler')"
        @submit="submitDelete"
      />
    </Modal>
    <Modal
      v-model="createScheduleOpen"
      class="modal--xlg"
    >
      <create-trips
        slot="modal-panel"
        v-model="createScheduleOpen"
        :mode="createScheduleState.mode"
        :edit="createScheduleState.edit"
        @submit="submitCreateSchedule"
      />
    </Modal>
    <Modal v-model="stateModalOpen">
      <Component
        :is="modalState.type"
        slot="modal-panel"
        v-model="stateModalOpen"
        :title="modalState.title"
        :body="modalState.body"
        :close="modalState.close"
      />
    </Modal>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CreateTrips from '@/components/trips/CreateTrips.vue'
import DeleteModal from '@/components/utils/DeleteModal.vue'
import SuccessModal from '@/components/utils/SuccessModal.vue'
import ErrorModal from '@/components/utils/ErrorModal.vue'
import Dropdown from '@/components/utils/Dropdown.vue'
import Modal from '@/components/utils/Modal.vue'

import dayjs from 'dayjs'

export default {
  name: 'ListScheduler',
  components: {
    Dropdown,
    Modal,
    CreateTrips,
    DeleteModal,
    SuccessModal,
    ErrorModal
  },
  data () {
    return {
      selectedMonth: null,
      deleteModalOpen: false,
      schedulerToDelete: null,
      createScheduleOpen: false,
      createScheduleState: {},
      stateModalOpen: false,
      modalState: {}
    }
  },
  computed: {
    ...mapState([
      'schedulers'
    ])
  },
  async created () {
    await this.fetchSchedulers({})
  },
  methods: {
    ...mapActions([
      'fetchSchedulers',
      'newTrips',
      'delScheduler',
      'editScheduler'
    ]),
    async fetchSchedulersForMonth () {
      const query = {}
      if (this.selectedMonth !== null) {
        const monthStart = dayjs(this.selectedMonth).startOf('month')
        query.start = monthStart.valueOf()
        query.end = monthStart.add(1, 'month').valueOf()
      }
      await this.fetchSchedulers(query)
    },
    getDays: function (days) {
      const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const names = []
      for (const index of days) {
        const weekName = weekdays[index]
        names.push(this.$t(weekName))
      }
      return names.join(', ')
    },
    showCreateSchedulerModal () {
      this.createScheduleOpen = true
      this.createScheduleState = { mode: 'create', edit: null }
      this.selectedMonth = null
    },
    showEditSchedulerModal (scheduler) {
      this.createScheduleState = { mode: 'edit', edit: scheduler }
      this.createScheduleOpen = true
      this.selectedMonth = null
    },
    showDuplicateSchedulerModal (scheduler) {
      scheduler = JSON.parse(JSON.stringify(scheduler))
      scheduler.trips.scheduler.periods = []
      scheduler.trips.scheduler.excludedDays = []
      this.createScheduleState = { edit: scheduler, mode: 'duplicate' }
      this.createScheduleOpen = true
      this.selectedMonth = null
    },
    showRecreateSchedulerModal (scheduler) {
      const now = dayjs()
      scheduler = JSON.parse(JSON.stringify(scheduler))
      scheduler.trips.scheduler.periods = scheduler.trips.scheduler.periods.filter(period => dayjs(period.start).isAfter(now))
      scheduler.trips.scheduler.excludedDays = scheduler.trips.scheduler.excludedDays.filter(excludedDay => dayjs(excludedDay).isAfter(now))
      this.createScheduleState = { edit: scheduler, mode: 'recreate' }
      this.createScheduleOpen = true
      this.selectedMonth = null
    },
    removeScheduler (scheduler) {
      this.schedulerToDelete = scheduler
      this.deleteModalOpen = true
    },
    async submitCreateSchedule ({ trips, id }) {
      try {
        if (this.createScheduleState.mode === 'edit') {
          await this.editScheduler({ id, trips })
        } else {
          await this.newTrips({ trips })
          if (this.createScheduleState.mode === 'recreate') {
            await this.delScheduler({ id })
          }
        }
        await this.fetchSchedulers({})
        this.createScheduleOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: this.$t(`modals.state.success.${this.createScheduleState.mode}.title`),
          body: this.$t(`modals.state.success.${this.createScheduleState.mode}.body`),
          close: this.$t(`modals.state.success.${this.createScheduleState.mode}.close`)
        }
      } catch (err) {
        this.createScheduleOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Não foi possível criar o horário',
          body: 'Não foi possível criar o horário',
          close: 'Voltar'
        }
      }
    },
    async submitDelete () {
      try {
        await this.delScheduler({ id: this.schedulerToDelete.trips.scheduler.id })
        await this.fetchSchedulers({})
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: 'Horário apagado com sucesso',
          body: 'Horário apagado com sucesso',
          close: 'Voltar'
        }
      } catch (err) {
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Não foi possível apagar o horário',
          body: 'Não foi possível apagar o horário',
          close: 'Voltar'
        }
      }
    },
    getDate (dtStr, dtEnd) {
      const start = new Date(dtStr)
      const end = new Date(dtEnd)

      if (start.getFullYear() === end.getFullYear()) {
        if (start.getMonth() === end.getMonth()) {
          if (start.getDate() === end.getDate()) {
            return start.getDate() + ' ' + this.getMonthString(start.getMonth()) + ' ' + start.getFullYear()
          } else {
            return start.getDate() + '-' + end.getDate() + ' ' + this.getMonthString(start.getMonth()) + ' ' + start.getFullYear()
          }
        } else {
          return start.getDate() + ' ' + this.getMonthString(start.getMonth()) + '-' + end.getDate() + ' ' + this.getMonthString(end.getMonth()) + ' ' + start.getFullYear()
        }
      } else {
        return start.getDate() + ' ' + this.getMonthString(start.getMonth()) + ' ' + start.getFullYear() + ' - ' + end.getDate() + ' ' + this.getMonthString(end.getMonth()) + ' ' + start.getFullYear()
      }
    },
    getMonthString (month) {
      switch (month + 1) {
        case 1: return this.$t('month.january')
        case 2: return this.$t('month.february')
        case 3: return this.$t('month.march')
        case 4: return this.$t('month.april')
        case 5: return this.$t('month.may')
        case 6: return this.$t('month.june')
        case 7: return this.$t('month.july')
        case 8: return this.$t('month.august')
        case 9: return this.$t('month.september')
        case 10: return this.$t('month.october')
        case 11: return this.$t('month.november')
        case 12: return this.$t('month.december')
      }
    }
  },
  i18n: {
    messages: {
      pt: {
        modals: {
          state: {
            success: {
              create: {
                title: 'Horário criado com sucesso',
                body: 'Horário criado com sucesso',
                close: 'Voltar'
              },
              recreate: {
                title: 'Horário recriado com sucesso',
                body: 'Horário recriado com sucesso',
                close: 'Voltar'
              },
              duplicate: {
                title: 'Horário duplicado com sucesso',
                body: 'Horário duplicado com sucesso',
                close: 'Voltar'
              },
              edit: {
                title: 'Horário editado com sucesso',
                body: 'Horário duplicado com sucesso',
                close: 'Voltar'
              }
            },
            error: {
              create: {
                title: 'Não foi possível criar o horário',
                body: 'Não foi possível criar o horário',
                close: 'Voltar'
              },
              recreate: {
                title: 'Não foi possível recriar o horário',
                body: 'Não foi possível recriar o horário',
                close: 'Voltar'
              },
              duplicate: {
                title: 'Não foi possível duplicar o horário',
                body: 'Não foi possível duplicar o horário',
                close: 'Voltar'
              },
              edit: {
                title: 'Não foi possível editar o horário',
                body: 'Não foi possível editar o horário',
                close: 'Voltar'
              }
            }
          }
        }
      },
      en: {
        modals: {
          state: {
            success: {
              create: {
                title: 'Schedule created with success',
                body: 'Schedule created with success',
                close: 'Back'
              },
              recreate: {
                title: 'Schedule recreated with success',
                body: 'Schedule recreated with success',
                close: 'Back'
              },
              duplicate: {
                title: 'Schedule duplicated with success',
                body: 'Schedule duplicated with success',
                close: 'Back'
              },
              edit: {
                title: 'Schedule edited with success',
                body: 'Schedule edited with success',
                close: 'Back'
              }
            },
            error: {
              create: {
                title: 'Schedule creation failed',
                body: 'Schedule creation failed',
                close: 'Back'
              },
              recreate: {
                title: 'Schedule recreation failed',
                body: 'Schedule recreation failed',
                close: 'Back'
              },
              duplicate: {
                title: 'Schedule duplicate failed',
                body: 'Schedule duplicate failed',
                close: 'Back'
              },
              edit: {
                title: 'Schedule edit failed',
                body: 'Schedule edit failed',
                close: 'Back'
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:color";
@import "../assets/scss/variables";

time {
  display: block;
}

.input {
  border: 1px solid $blue !important;
  color: #fff !important;
  background: $blue !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;

  &:hover {
    border: 1px solid color.adjust($blue, $lightness: 6%) !important;
    background-color: color.adjust($blue, $lightness: 6%) !important;
  }
}

.scheduler-filter {
  display: flex;
  flex-direction: column;
  width: 10rem;
}

.scheduler-filter__reset {
  margin-top: 0.5rem;
}
</style>
