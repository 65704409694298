
<section>
  <h1 data-qa="title">
    {{ $t('my.tickets') }}
  </h1>
  <nav class="tab navigation">
    <ul>
      <li
        v-for="(option, index) in navOptions"
        :key="index"
      >
        <a
          :class="{ active: option.active }"
          @click="changeView(index)"
        >{{ $t(option.name) }}</a>
      </li>
    </ul>
  </nav>
  <list-tickets v-if="selectedOption.index === 0" />
  <traveled-trips v-else />
</section>
