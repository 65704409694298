import * as types from './mutation-types'
import { getSchedulers, deleteScheduler, updateScheduler } from '@/api/schedulers'
import TripsAPI from '@/api/trips'
import { getNotifications, createNotification, removeNotification, updateNotification } from '@/api/notifications'
import { getRoutes, getRoute, createRoute, removeRoute, updateRoute } from '@/api/routes'
import { getStops, getStop, createStop, removeStop, updateStop } from '@/api/stops'
import { getHolidays } from '@/api/holidays'
import { getProfile } from '@/api/profile'

export const fetchProfile = ({ commit }) => {
  return getProfile()
    .then(({ profile }) => {
      return commit(types.RECEIVE_PROFILE, { profile })
    })
}

/**
 * Get all holidays.
 */
export const fetchHolidays = ({ commit }) => {
  commit(types.FETCH_HOLIDAYS)
  return getHolidays()
    .then(({ holidays }) => {
      return commit(types.RECEIVE_HOLIDAYS, { holidays })
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Get all trips.
 */
export const fetchTrips = ({ commit }) => {
  commit(types.FETCH_TRIPS)
  return TripsAPI.getNextTrips()
    .then(({ trips }) => {
      return commit(types.RECEIVE_TRIPS, { trips })
    })
}

export const fetchTripsBetween = ({ commit }, { start, end }) => {
  commit(types.FETCH_TRIPS)
  return TripsAPI.getTripsBetween({ start, end })
    .then((trips) => {
      return commit(types.RECEIVE_TRIPS, { trips })
    })
}

export const newTrips = ({ commit }, { trips }) => {
  return TripsAPI.createTrips({ trips })
    .then((trips) => {
      return true
    })
}

export const editTripCapacity = ({ commit }, { id, capacity }) => {
  return TripsAPI.updateTripCapacity({ id, capacity })
    .then((trips) => {
      return true
    })
}

export const delTrip = ({ commit }, { id }) => {
  return TripsAPI.cancelTrip({ id })
}

export const sendMailToTripTravelers = ({ commit }, { tripId, subject, message }) => {
  return TripsAPI.sendEmailToTravelers({ id: tripId, subject, message })
    .then(({ response }) => {
      return commit(types.SEND_EMAIL)
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Get all schedulers.
 */
export const fetchSchedulers = ({ commit }, { start, end }) => {
  commit(types.FETCH_SCHEDULERS)
  return getSchedulers({ start, end })
    .then(({ schedulers }) => {
      return commit(types.RECEIVE_SCHEDULERS, { schedulers })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editScheduler = ({ commit }, { id, trips }) => {
  return updateScheduler({ id, trips })
    .then((trips) => {
      return true
    })
}

export const delScheduler = ({ commit }, { id }) => {
  commit(types.FETCH_SCHEDULERS)
  return deleteScheduler({ id })
    .then((result) => {
      return true
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Stops operations.
 */
export const fetchStops = ({ commit }) => {
  commit(types.FETCH_STOPS)
  return getStops()
    .then(({ stops }) => {
      return commit(types.RECEIVE_STOPS, { stops })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const fetchStop = ({ commit }, { id }) => {
  commit(types.FETCH_STOP, { id })
  return getStop({ id })
    .then(({ stop }) => {
      return commit(types.RECEIVE_STOP, { stop })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const newStop = ({ commit }, { stop }) => {
  return createStop({ stop })
    .then(({ stop }) => {
      return commit(types.ADD_STOP, { stop })
    })
}

export const editStop = ({ commit }, { stop }) => {
  commit(types.FETCH_STOP, { stop })
  return updateStop({ stop })
    .then((result) => {
      return true
    })
}

export const delStop = ({ commit }, { id }) => {
  commit(types.FETCH_STOP, { id })
  return removeStop({ id })
    .then((result) => {
      return commit(types.REMOVE_STOP, { id })
    })
}

/**
 * Notifications operations.
 */
export const fetchNotifications = ({ commit }, { viewed }) => {
  commit(types.FETCH_NOTIFICATIONS)
  return getNotifications({ viewed })
    .then(({ notifications }) => {
      return commit(types.RECEIVE_NOTIFICATIONS, { notifications })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const newNotification = ({ commit }, { notification }) => {
  commit(types.CREATE_NOTIFICATION, { notification })
  return createNotification({ notification })
    .then(({ notification }) => {
      return commit(types.RECEIVE_NOTIFICATION, { notification })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const delNotification = ({ commit }, { id }) => {
  return removeNotification({ id })
    .then((result) => {
      // commit(types.DELETE_NOTIFICATION, { id })
      return true
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editNotification = ({ commit }, { id, viewed }) => {
  return updateNotification({ id, viewed })
    .then((result) => {
      // commit(types.DELETE_NOTIFICATION, { id })
      return true
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Notifications routes.
 */

export const fetchRoutes = ({ commit }) => {
  commit(types.FETCH_ROUTES)
  return getRoutes()
    .then(({ routes }) => {
      return commit(types.RECEIVE_ROUTES, { routes })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const fetchRoute = ({ commit }, { id }) => {
  commit(types.FETCH_ROUTE, { id })
  return getRoute({ id })
    .then(({ route }) => {
      return commit(types.RECEIVE_ROUTE, { route })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const newRoute = ({ commit }, { route }) => {
  return createRoute({ route })
    .then(({ route }) => {
      return commit(types.ADD_ROUTE, { route })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editRoute = ({ commit }, { route }) => {
  return updateRoute({ route })
    .then(({ route }) => {
      return commit(types.EDIT_ROUTE, { route })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const delRoute = ({ commit }, { id }) => {
  commit(types.FETCH_ROUTE, { id })
  return removeRoute({ id })
    .then((result) => {
      return commit(types.REMOVE_ROUTE, { id })
    })
    .catch((err) => {
      console.log(err)
    })
}
