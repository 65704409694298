import * as types from './mutation-types'

export default {
  [types.FETCH_SCHEDULERS] (state) {
    state.schedulers = []
  },
  [types.RECEIVE_SCHEDULERS] (state, { schedulers }) {
    state.schedulers = schedulers
  },
  [types.FETCH_HOLIDAYS] (state) {
    state.holidays = []
  },
  [types.RECEIVE_HOLIDAYS] (state, { holidays }) {
    state.holidays = holidays
  },
  [types.FETCH_TRIPS] (state) {
    state.trips = undefined
  },
  [types.RECEIVE_TRIPS] (state, { trips }) {
    state.trips = trips
  },
  [types.FETCH_NOTIFICATIONS] (state) {
    state.notifications = []
  },
  [types.FETCH_NOTIFICATION] (state) {
    state.currentNotification = undefined
  },
  [types.RECEIVE_NOTIFICATIONS] (state, { notifications }) {
    state.notifications = notifications
  },
  [types.RECEIVE_NOTIFICATION] (state, { notification }) {
    state.currentNotification = notification
  },
  [types.CREATE_NOTIFICATION] (state, { notification }) {
    state.currentNotification = notification
  },
  [types.DELETE_NOTIFICATION] (state, { notification }) {
    state.currentNotification = notification
  },
  [types.FETCH_ROUTES] (state) {
    state.routes = undefined
  },
  [types.RECEIVE_ROUTES] (state, { routes }) {
    state.routes = routes
  },
  [types.FETCH_ROUTE] (state) {
    state.currentRoute = undefined
  },
  [types.RECEIVE_ROUTE] (state, { route }) {
    state.currentRoute = route
  },
  [types.ADD_ROUTE] (state, { route }) {
    state.routes.push({ route })
  },
  [types.EDIT_ROUTE] (state, { route }) {
    //
  },
  [types.REMOVE_ROUTE] (state, { id }) {
    state.routes = state.routes.filter(function (item) {
      return item.id !== id
    })
  },
  [types.FETCH_STOPS] (state) {
    state.stops = undefined
  },
  [types.RECEIVE_STOPS] (state, { stops }) {
    state.stops = stops
  },
  [types.FETCH_STOP] (state) {
    state.currentStop = undefined
  },
  [types.RECEIVE_STOP] (state, { stop }) {
    state.currentStop = stop
  },
  [types.ADD_STOP] (state, { stop }) {
    state.stops.push({ stop })
  },
  [types.REMOVE_STOP] (state, { id }) {
    state.stops = state.stops.filter(function (item) {
      return item.id !== id
    })
  },
  [types.RECEIVE_PROFILE] (state, { profile }) {
    state.loggedUser = profile
  },
  [types.RECEIVE_VALIDATION] (state, { validation }) {
    state.currentValidation = validation
  },
  [types.SEND_EMAIL] (state) {
    // FIXME
  }
}
