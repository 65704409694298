
<div
  ref="dropdown"
  :class="{'dropdown--dropup': dropup}"
  class="dropdown-wrapper"
  @click="toggle"
>
  <slot name="dropdown-trigger" />
  <transition name="dropdown-slide-down">
    <div
      v-show="opened"
      :class="`dropdown--${size}`"
      class="dropdown__panel"
    >
      <slot name="dropdown-panel" />
    </div>
  </transition>
</div>
