
<section>
  <h1 data-qa="title">
    {{ $t('reports') }}
  </h1>
  <h4>{{ $t('shuttle.usage.stats') }}</h4>
  <table class="cards table schedule-table reports-page__cards">
    <thead>
      <tr>
        <th>{{ $t('start') }}</th>
        <th>{{ $t('end') }}</th>
        <th :aria-label="$t('actions')" />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td :data-label="$t('start')">
          <p
            v-if="!pickedDateBetweenForTrips"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenForTrips.start).toLocaleDateString() }}
          </p>
        </td>
        <td :data-label="$t('end')">
          <p
            v-if="!pickedDateBetweenForTrips"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenForTrips.end).toLocaleDateString() }}
          </p>
        </td>
        <td
          class="btn--group"
        >
          <date-picker
            v-model="pickedDateBetweenForTrips"
            :range-mode="true"
            :before="true"
            name="periodInsertForTrips"
          >
            <button
              slot="trigger"
              class="btn btn--sm"
            >
              {{ $t('define.date') }}
            </button>
          </date-picker>

          <button
            :class="{ 'disabled': !pickedDateBetweenForTrips}"
            href="#"
            class="btn btn--sm btn--primary"
            @click.prevent="downloadStatsForTrips()"
          >
            {{ $t('download.csv') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <h4>{{ $t('people.who.missed.the.shuttle') }}</h4>
  <table class="cards table schedule-table">
    <thead>
      <tr>
        <th>{{ $t('start') }}</th>
        <th>{{ $t('end') }}</th>
        <th :aria-label="$t('actions')" />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td :data-label="$t('start')">
          <p
            v-if="!pickedDateBetweenForUsers"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenForUsers.start).toLocaleDateString() }}
          </p>
        </td>
        <td :data-label="$t('end')">
          <p

            v-if="!pickedDateBetweenForUsers"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenForUsers.end).toLocaleDateString() }}
          </p>
        </td>
        <td
          class="btn--group"
        >
          <date-picker
            v-model="pickedDateBetweenForUsers"
            :range-mode="true"
            :before="true"
            name="periodInsertForUsers"
          >
            <button
              slot="trigger"
              class="btn btn--sm"
              style="z-index: 0;"
            >
              {{ $t('define.date') }}
            </button>
          </date-picker>
          <button
            :class="{ 'disabled': !pickedDateBetweenForUsers}"
            class="btn btn--sm btn--primary"
            @click.prevent="downloadStatsForUsers()"
          >
            {{ $t('download.csv') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <h4>{{ $t('stats.aggregated.by.user') }}</h4>
  <table class="cards table schedule-table">
    <thead>
      <tr>
        <th>{{ $t('start') }}</th>
        <th>{{ $t('end') }}</th>
        <th :aria-label="$t('actions')" />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td :data-label="$t('start')">
          <p
            v-if="!pickedDateBetweenUsersAggregated"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenUsersAggregated.start).toLocaleDateString() }}
          </p>
        </td>
        <td :data-label="$t('end')">
          <p

            v-if="!pickedDateBetweenUsersAggregated"
            class="disabled"
          >
            {{ $t('pick.a.date') }}
          </p>
          <p v-else>
            {{ new Date(pickedDateBetweenUsersAggregated.end).toLocaleDateString() }}
          </p>
        </td>
        <td
          class="btn--group"
        >
          <date-picker
            v-model="pickedDateBetweenUsersAggregated"
            :range-mode="true"
            :before="true"
            name="periodInsertForUsers"
          >
            <button
              slot="trigger"
              class="btn btn--sm"
              style="z-index: 0;"
            >
              {{ $t('define.date') }}
            </button>
          </date-picker>
          <button
            :class="{ 'disabled': !pickedDateBetweenUsersAggregated}"
            class="btn btn--sm btn--primary"
            @click.prevent="downloadStatsForUsersAggregated()"
          >
            {{ $t('download.csv') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</section>
