<template>
  <header
    :class="{'nav-is-open': navIsOpen}"
    class="main-header"
  >
    <nav class="main-navigation">
      <ul>
        <li class="space-right">
          <h1 class="logo">
            <router-link :to="{ name: 'ListTripsPage' }">
              <svg
                width="30"
                height="37"
                viewBox="0 0 30 37"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <path
                    id="a"
                    d="M29.719 36.251H0V.182h29.719z"
                  />
                </defs>
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <mask
                    id="b"
                    fill="#fff"
                  >
                    <use xlink:href="#a" />
                  </mask>
                  <path
                    d="M22.77 11.57h-1.237v9.336h-2.678V11.57H17.62V9.698h5.15v1.873zm-6.596-4.844v17.157c0 2.224-1.796 3.375-4.01 3.375-2.215 0-4.027-.971-4.027-2.86h.003c0-.74.599-1.342 1.337-1.342a1.34 1.34 0 0 1 1.336 1.343h.002c0 1.37.109 2.301 1.348 2.301 1.332 0 1.332-.934 1.332-2.817l.002-17.157c0-2.222 1.795-3.374 4.01-3.374 2.214 0 4.026.97 4.026 2.86h-.003a1.34 1.34 0 0 1-1.337 1.341 1.34 1.34 0 0 1-1.337-1.341h-.001c0-1.371-.109-2.302-1.348-2.302-1.333 0-1.333.933-1.333 2.816zM8.142 9.698h2.67l.004 11.2H8.139l.003-11.2zM0 .182V15.32c0 13.63 14.832 20.93 14.832 20.93s14.887-7.3 14.887-20.93V.182H0z"
                    fill="#009DE0"
                    mask="url(#b)"
                  />
                </g>
              </svg>
              <span class="site-name">Shuttle</span>
            </router-link>
          </h1>
        </li>
        <template v-if="hasRole('admin') && $auth.isLoggedWith2FA()">
          <li class="menu-item">
            <router-link :to="{ name: 'AdminListTripsPage' }">
              {{ $t('next.shuttles') }}
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{ name: 'ListSchedulersPage' }">
              {{ $t('scheduler') }}
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{ name: 'ListRoutesPage' }">
              {{ $t('routes.and.stops') }}
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{ name: 'Notifications' }">
              {{ $t('notifications') }}
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{ name: 'ReportsPage' }">
              {{ $t('reports') }}
            </router-link>
          </li>
        </template>
        <template v-else>
          <li class="menu-item">
            <router-link :to="{ name: 'ListTripsPage' }">
              {{ $t('next.shuttles') }}
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{ name: 'MyTicketsPage' }">
              {{ $t('my.tickets') }}
            </router-link>
          </li>
        </template>
        <li class="space-left">
          <a
            v-if="$i18n.locale === 'en'"
            href="#"
            @click.prevent="changeLanguage({ language: 'pt' })"
          >
            Português
          </a>
          <a
            v-if="$i18n.locale === 'pt'"
            href="#"
            @click.prevent="changeLanguage({ language: 'en' })"
          >
            English
          </a>
        </li>
        <li
          class="nav-trigger"
          @click.prevent="toggleNav()"
        >
          <a href>
            <span />
          </a>
        </li>
        <li
          v-if="loggedUser"
          class="site-user"
          style="position: relative;"
        >
          <user-dropdown>
            <li v-if="hasRole('admin') && !$auth.isLoggedWith2FA()">
              <button
                style="width: 100%;"
                @click.prevent="login2FA"
              >
                {{ $t('admin.dashboard') }}
              </button>
            </li>
            <template v-if="hasRole('admin') && $auth.isLoggedWith2FA()">
              <li>
                <button
                  style="width: 100%;"
                  @click.prevent="$auth.leave2FA()"
                >
                  {{ $t('leave.admin.mode') }}
                </button>
              </li>
              <li>
                <router-link
                  :to="{name: 'List2FAMethods'}"
                  style="width: 100%;"
                >
                  {{ $t('list2faMethods') }}
                </router-link>
              </li>
            </template>
            <li>
              <button
                v-if="$auth.isLogged"
                style="width: 100%;"
                @click.stop.prevent="$auth.logout()"
              >
                {{ $t('logout') }}
              </button>
            </li>
          </user-dropdown>
        </li>
      </ul>
      <div class="mobile-nav menu">
        <ul>
          <template v-if="hasRole('admin') && $auth.isLoggedWith2FA()">
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'AdminListTripsPage' }">
                {{ $t('next.shuttles') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'ListSchedulersPage' }">
                {{ $t('scheduler') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'ListRoutesPage' }">
                {{ $t('routes.and.stops') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'Notifications' }">
                {{ $t('notifications') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'ReportsPage' }">
                {{ $t('reports') }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{name: 'List2FAMethods'}"
              >
                {{ $t('list2faMethods') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="$auth.leave2FA()"
            >
              <router-link :to="{ name: 'ListTripsPage' }">
                {{ $t('leave.admin.mode') }}
              </router-link>
            </li>
          </template>
          <template v-else>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'ListTripsPage' }">
                {{ $t('next.shuttles') }}
              </router-link>
            </li>
            <li
              class="menu-item"
              @click="closeNav()"
            >
              <router-link :to="{ name: 'MyTicketsPage' }">
                {{ $t('my.tickets') }}
              </router-link>
            </li>
            <li
              v-if="hasRole('admin')"
              class="menu-item"
            >
              <button
                style="color: white;"
                @click.prevent="login2FA"
              >
                {{ $t('admin.dashboard') }}
              </button>
            </li>
          </template>
          <button
            v-if="$auth.isLogged"
            class="button blue"
            @click.stop="$auth.logout()"
          >
            <span class="link-text">{{ $t('logout') }}</span>
          </button>
        </ul>
      </div>
      <div
        class="overlay"
        @click="closeNav()"
      />
    </nav>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import UserDropdown from '@/components/UserDropdown'
import dayjs from 'dayjs'

export default {
  name: 'TopBar',
  components: {
    UserDropdown
  },
  data () {
    return {
      navIsOpen: false
    }
  },
  computed: {
    ...mapState([
      'loggedUser'
    ])
  },
  methods: {
    closeNav () {
      this.navIsOpen = false
    },
    toggleNav () {
      this.navIsOpen = !this.navIsOpen
    },
    async changeLanguage ({ language }) {
      window.localStorage.setItem('language', language)
      dayjs.locale(language)
      this.setLocale(language)
    },
    updateMenu (index) {
      this.menu[this.selectedMenu].state = 'notSelected'
      this.selectedMenu = index
      this.menu[index].state = 'selected'
    },
    toggleMenu () {
      document.querySelector('.menu.dropdown.user-dropdown').classList.toggle('active')
    },
    login2FA () {
      window.location = `/api/login/2fa?callbackUrl=${window.location.pathname}`
    }
  }
}
</script>

<style lang="scss">
.top-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;

  .brand {
    flex: 1 0 200px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 60px;
    cursor: pointer;
  }

  .avatar {
    flex: 0 0 30px;
    display: block;
    line-height: 60px;
    cursor: pointer;
    img {
      vertical-align: middle;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
}
.overlay {
  position: fixed;
  z-index: 200;
  height: 100vh;
  width: 100%;
  top: 2.8125rem;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: rgb(46 50 66 / 30%);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 600ms cubic-bezier(0.77, 0, 0.175, 1), visibility 600ms cubic-bezier(0.77, 0, 0.175, 1);
}
$blue: gray;
$dark: #2e3442;
$nav-link-hover-color: $blue;

$nav-background-color-mobile: #2e323e;
$nav-link-color-mobile: white;
$nav-link-current-color-mobile: $blue;
$nav-arrow-color-mobile: #596179;
$nav-link-parent-color-mobile: white;
$nav-separator-border-color-mobile: #373b4a;
.mobile-nav {
  overflow-x: hidden;
  position: fixed;
  z-index: 10000;
  width: 10.625rem;
  top: 0;
  text-align: left;
  right: -10.625rem;
  will-change: right;
  transition: right 0.2s ease-out;
  background-color: $dark;
  color: white;
  ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0.625rem;
      &:nth-last-child(2) {
        flex-grow: 1;
      }
    }
  }
  ul.children > li:not(.parent, .back) > a::before {
    content: "— ";
    color: #969696;
    height: 100vh;
  }
  a {
    color: $nav-link-color-mobile;
    &:hover {
      color: $nav-link-hover-color;
    }
  }
  li.current > a {
    color: $nav-link-current-color-mobile;
    font-weight: 700;
  }
  li.parent > a {
    color: $nav-link-parent-color-mobile;
    font-weight: 700;
  }
  .has-children > a::after {
    border-color: $nav-arrow-color-mobile;
  }
  li:not(:last-child) > a {
    border-color: $nav-separator-border-color-mobile;
  }
}
@media screen and (max-width: 991px) {
  .nav-is-open {
    // left: -10.625rem;
    // ~ * {
    //   transform: translateX(-10.625rem);
    // }
    .nav-trigger {
      a span {
        background-color: rgb(0 0 255 / 0%) !important;
      }
      a span::before {
        margin-top: -1px !important;
        transform: rotate(45deg);
        transform-origin: 0.1875rem;
      }
      a span::after {
        transform: rotate(-45deg);
        transform-origin: 0.15rem;
      }
    }
    .mobile-nav {
      right: 0;
    }
    .overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }
  }
}

</style>
