
<div class="delete-modal card">
  <div class="card-row">
    <div class="card-row__text">
      <i class="delete-modal__icon">
        <svg
          class="garbage-can"
          width="63"
          height="68"
          viewBox="0 0 63 68"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="#F9690E"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <g class="garbage-body">
                <path d="M4.575 17.088h42.9v47.258a2.5 2.5 0 0 1-2.5 2.5h-37.9a2.5 2.5 0 0 1-2.5-2.5V17.088z" />
                <path d="M26.025 28.935V55" />
                <path d="M14.108 28.935V55" />
                <path d="M37.941 28.935V55" />
              </g>
              <g class="lid">
                <path d="M1 17.088h50.05V10.11a2.5 2.5 0 0 0-2.5-2.5H3.5a2.5 2.5 0 0 0-2.5 2.5v6.978z" />
                <path d="M17.683 7.61h16.683V4.187a2.5 2.5 0 0 0-2.5-2.5H20.183a2.5 2.5 0 0 0-2.5 2.5V7.61z" />
              </g>
            </g>
            <g transform="translate(34 33)">
              <circle
                fill="#FFF"
                cx="14"
                cy="14"
                r="14"
              />
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  class="question mark"
                  d="M17.985 18.985L9.5 10.5c0-2.5 1.985-4 4.243-4 2.257 0 4.242 2 4.242 4 0 2.282-1.414 3.782-4.242 4.5v2"
                />
                <path
                  class="question dot"
                  d="M13.743 21L9.5 18.985l8.485-8.485"
                />
              </g>
            </g>
          </g>
        </svg>
      </i>
      <h1>{{ title }}</h1>
      <p>
        {{ body }}
      </p>
      <div class="delete-modal__options">
        <button
          class="btn"
          @click="closeModal"
        >
          {{ cancel }}
        </button>
        <button
          class="btn btn--danger"
          @click="$emit('submit')"
        >
          {{ confirm }}
        </button>
      </div>
    </div>
  </div>
</div>
