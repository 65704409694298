import { stringify } from 'csv-stringify/browser/esm/sync'
import dayjs from 'dayjs'

export default {
  methods: {
    StatsToCsv (stats) {
      const columns = [
        this.$t('day'),
        this.$t('departure'),
        this.$t('stops'),
        this.$t('subscribed'),
        this.$t('travelling'),
        this.$t('capacity')
      ]
      const trips = stats.map(trip => {
        return [
          dayjs(trip.departure).locale(this.$i18n.locale).format('L'),
          dayjs(trip.departure).locale(this.$i18n.locale).format('LTS'),
          trip.stops.map(stop => stop.name).join(', '),
          trip.subscribed,
          trip.travellers,
          trip.capacity
        ]
      })

      const data = stringify([
        columns,
        ...trips
      ],
      {
        delimiter: ',',
        header: false
      })
      return data
    },
    FailingPeopleToCsv (stats) {
      const columns = [
        this.$t('istid'),
        this.$t('name'),
        this.$t('email'),
        this.$t('departure'),
        this.$t('stops'),
        this.$t('scheduler')
      ]
      const users = []
      for (const user in stats) {
        const missingTrips = stats[user].trips
        missingTrips.forEach((trip, index) => {
          users.push([
            user,
            stats[user].name,
            stats[user].email,
            dayjs(trip.departure).locale(this.$i18n.locale).format('llll'),
            trip.stops.map(stop => stop.name).join(', '),
            trip.scheduler
          ])
        })
      }
      const data = stringify(
        [
          columns,
          ...users
        ],
        {
          delimiter: ',',
          header: false
        })
      return data
    },
    usersAggregatedToCsv (stats) {
      const columns = [
        this.$t('istid'),
        this.$t('subscribed'),
        this.$t('travelling')
      ]
      const users = stats.map(u => {
        return [
          u.user,
          u.bookings,
          u.travels
        ]
      })

      const data = stringify(
        [
          columns,
          ...users
        ],
        {
          delimiter: ',',
          header: false
        })
      return data
    }
  }
}
