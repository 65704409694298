
<div>
  <div class="card-modal card">
    <div class="card-row">
      <div class="card-row__text pending-ticket">
        <i class="icon">
          <svg
            class="timer"
            width="66"
            height="66"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >

              <g class="pointer">
                <path d="M0 0h66v66H0z" />
                <path
                  d="M33.167 33.4V13"
                  stroke="#009DE0"
                  stroke-width="2"
                  fill="#666"
                  fill-rule="nonzero"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>

              <circle
                fill="#009DE0"
                fill-rule="nonzero"
                cx="33"
                cy="33"
                r="3"
              />
              <path
                d="M33 66C14.775 66 0 51.225 0 33S14.775 0 33 0s33 14.775 33 33-14.775 33-33 33zm0-2c17.12 0 31-13.88 31-31C64 15.88 50.12 2 33 2 15.88 2 2 15.88 2 33c0 17.12 13.88 31 31 31z"
                fill="#009DE0"
                fill-rule="nonzero"
              />
              <path
                d="M33 7.435A1.217 1.217 0 1 1 33 5a1.217 1.217 0 0 1 0 2.435zm9.774 1.942a1.217 1.217 0 1 1 .95-2.242 1.217 1.217 0 0 1-.95 2.242zm8.303 5.546a1.218 1.218 0 1 1 1.721-1.721 1.218 1.218 0 0 1-1.72 1.72l-.001.001zm5.538 8.284a1.218 1.218 0 1 1 2.258-.912 1.218 1.218 0 0 1-2.258.912zM58.565 33A1.217 1.217 0 1 1 61 33a1.217 1.217 0 0 1-2.435 0zm-1.942 9.774a1.217 1.217 0 1 1 2.242.95 1.217 1.217 0 0 1-2.242-.95zm-5.546 8.303a1.218 1.218 0 1 1 1.721 1.721 1.218 1.218 0 0 1-1.72-1.72l-.001-.001zm-8.284 5.538a1.218 1.218 0 1 1 .912 2.258 1.218 1.218 0 0 1-.912-2.258zM33 58.565A1.217 1.217 0 1 1 33 61a1.217 1.217 0 0 1 0-2.435zm-9.774-1.942a1.217 1.217 0 1 1-.95 2.242 1.217 1.217 0 0 1 .95-2.242zm-8.303-5.546a1.218 1.218 0 1 1-1.721 1.721 1.218 1.218 0 0 1 1.72-1.72l.001-.001zm-5.546-8.303a1.217 1.217 0 1 1-2.242.95 1.217 1.217 0 0 1 2.242-.95zM7.435 33A1.217 1.217 0 1 1 5 33a1.217 1.217 0 0 1 2.435 0zm1.942-9.774a1.217 1.217 0 1 1-2.242-.95 1.217 1.217 0 0 1 2.242.95zm5.546-8.303a1.218 1.218 0 1 1-1.721-1.721 1.218 1.218 0 0 1 1.72 1.72l.001.001zm8.284-5.538a1.218 1.218 0 1 1-.912-2.258 1.218 1.218 0 0 1 .912 2.258z"
                fill="#009DE0"
                fill-rule="nonzero"
              />
            </g>
          </svg>
        </i>

        <div class="countdown">
          <strong>{{ attribution.hours }}</strong>
          <strong class="blink">:</strong>
          <strong>{{ attribution.minutes }}</strong>
        </div>

        <h1 class="pending-ticket__header">
          {{ $t('ticket.attribution.pending') }}...
        </h1>

        <p class="pending-ticket__paragraph">
          {{ $t('ticket.attribution.pending.message', { hours: attribution.hours, minutes: attribution.minutes }) }}
        </p>
        <div class="pending-ticket__actions">
          <button
            class="btn btn--primary"
            @click="isModalOpen=false"
          >
            {{ $t('back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
