<template>
  <section>
    <header>
      <h1>{{ $t('routes.and.stops') }}</h1>
      <router-link
        :to="{ name: 'ListStops' }"
        class="button blue small filled"
      >
        {{ $t('modal.stops') }}
      </router-link>
    </header>
    <div class="card">
      <div
        v-for="(route, index) in routes"
        :key="route.id"
        class="card-row"
      >
        <div class="card-row__text">
          <div class="route">
            <div
              class="route__stops"
              aria-label="stops"
            >
              <div
                v-for="(stop, i) in route.stops"
                :key="generateStopId(route.id, stop.id, i)"
                class="f-tag-field__tags route__stops-tag"
              >
                <span
                  v-if="i === 0"
                  class="sort"
                >{{ $t('route.origin') }}</span>
                <span
                  v-else-if="i === (route.stops.length -1)"
                  class="sort"
                >{{ $t('route.destiny') }}</span>
                <span
                  v-else
                  class="sort"
                >{{ i }}-{{ $t('route.stop') }}</span>
                <div class="f-tag-field__tag f-tag-field__tag--blue f-tag-field__tag--rounded">
                  <div
                    :style="{ width: 30 + stop.name.length * 6 + 'px'}"
                    class="select-wrap"
                  >
                    <select
                      v-model="stop.id"
                      @change="onChangeStop(index, i, stop.id)"
                    >
                      <option
                        v-for="s in stops"
                        :key="s.id"
                        :value="s.id"
                      >
                        {{ s.name }}
                      </option>
                    </select>
                  </div>
                  <button
                    class="remove"
                    @click="removeStop(index, i)"
                  />
                </div>
              </div>
            </div>

            <div
              :data-label="$t('actions')"
              class="route__actions"
            >
              <div class="btn--group">
                <button
                  class="btn btn--sm"
                  @click="addStop(index)"
                >
                  {{ $t('add.stop') }}
                </button>
                <button
                  class="btn btn--sm btn--danger"
                  @click="removeRoute(route, index)"
                >
                  {{ $t('remove') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn--group">
      <button
        class="btn btn--primary"
        @click="addRoute"
      >
        {{ $t('create.new.route') }}
      </button>
    </div>

    <!-- Modals -->
    <Modal v-model="deleteModalOpen">
      <delete-modal
        slot="modal-panel"
        v-model="deleteModalOpen"
        :title="$t('delete.this.route')"
        :body="$t('delete.this.route.description')"
        :confirm="$t('yes.delete.this.route')"
        :cancel="$t('no.keep.this.route')"
        @submit="submitDelete"
      />
    </Modal>
    <Modal v-model="stateModalOpen">
      <Component
        :is="modalState.type"
        slot="modal-panel"
        v-model="stateModalOpen"
        :title="modalState.title"
        :body="modalState.body"
        :close="modalState.close"
      />
    </Modal>
  </section>
</template>

<script>
import DeleteModal from '@/components/utils/DeleteModal'
import SuccessModal from '@/components/utils/SuccessModal'
import ErrorModal from '@/components/utils/ErrorModal'
import { mapState, mapActions } from 'vuex'
import Modal from '@/components/utils/Modal.vue'

const getStop = (stops, id) => {
  for (const stop of stops) {
    if (id === stop.id) {
      return stop
    }
  }
}

export default {
  components: {
    DeleteModal,
    SuccessModal,
    ErrorModal,
    Modal
  },
  data () {
    return {
      deleteModalOpen: false,
      stateModalOpen: false,
      modalState: {},
      routeToDelete: null
    }
  },
  computed: {
    ...mapState([
      'routes',
      'stops'
    ])
  },
  async created () {
    await this.fetchRoutes()
    await this.fetchStops()
  },
  methods: {
    ...mapActions([
      'fetchRoutes',
      'fetchStops',
      'newRoute',
      'editRoute',
      'delRoute'
    ]),
    async addRoute () {
      const stop = { id: this.stops[0].id, name: this.stops[0].name }
      const route = { stops: [stop] }
      await this.newRoute({ route })
      await this.fetchRoutes()
    },
    async addStop (index) {
      const stop = { id: this.stops[0].id, name: this.stops[0].name }
      this.routes[index].stops.push(stop)
      await this.editRoute({ route: this.routes[index] })
    },
    async onChangeStop (routeIndex, stopIndexRoute, idStop) {
      const newStop = getStop(this.stops, idStop)
      const stop = { id: newStop.id, name: newStop.name }
      this.routes[routeIndex].stops[stopIndexRoute] = stop
      await this.editRoute({ route: this.routes[routeIndex] })
      this.$forceUpdate() // To force recalculation of stop length
    },
    async removeStop (routeIndex, stopIndex) {
      this.routes[routeIndex].stops.splice(stopIndex, 1)
      await this.editRoute({ route: this.routes[routeIndex] })
    },
    removeRoute (route, index) {
      this.deleteModalOpen = true
      this.routeToDelete = route
    },
    async submitDelete () {
      try {
        this.delRoute({ id: this.routeToDelete.id })
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: 'Rota apagada com sucesso',
          body: 'Rota apagada com sucesso',
          close: 'Voltar'
        }
      } catch (err) {
        this.deleteModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Erro a apagar rota',
          body: 'Erro a apagar rota',
          close: 'Voltar'
        }
      }
    },
    generateRouteId (route) {
      return route.stops.reduce((accumulator, stop) => {
        return accumulator + stop.id
      }, '')
    },
    generateStopId (routeId, stopId, index) {
      return routeId + stopId + index
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.route {
  display: flex;
  flex-direction: column;
  @media (min-width: 30rem) {
    flex-direction: row;
  }
}

.route__stops {
  display: flex;
  flex-flow: row wrap;
}

.route__actions {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media (min-width: 30rem) {
    margin-left: auto;
  }
}

.select-wrap {
  position: relative;
  width: calc(100% - 30px);
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 0;
  }
  &::before {
    border-top: 4px solid $dark;
    transform: translateY(calc(-50% - -3px));
  }
  &::after {
    border-bottom: 4px solid $dark;
    transform: translateY(calc(-50% - 3px));
  }
  select {
    font-size: 0.875rem;
    padding: 0;
    width: 100%;
    margin: 0;
    border: 0;
    position: relative;
    z-index: 2;
    padding-right: 2px;
    color: $dark;
    font-weight: 600;
  }
}
.remove {
  width: 22px;
  height: 22px;
  border: 1px solid rgb(0 0 0 / 50%);
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 100%;
  position: relative;
  transform: rotate(45deg);
  // transition: all 0.2s ease-in-out;
  margin-left: 8px;
  outline: none;
  &::before,
  &::after {
    content: "";
    width: 8px;
    height: 1px;
    background: $dark;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    // transition: all 0.2s ease-in-out;
  }
  &::after {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
  }
  &:hover {
    background: white;
    border-color: white;
    &::before,
    &::after {
      background: $blue;
    }
  }
  &:active {
    opacity: 0.5;
  }
}
.sort {
  margin-left: 1rem;
  position: unset;
  color: $gray-400;
  font-weight: 400;
  font-size: 0.75rem;
  pointer-events: none;
  // transition: all 0.2s ease-in-out;
}
.route__stops-tag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
</style>
