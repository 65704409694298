
<div
  v-if="trip"
  class="book-ticket card"
>
  <div class="card-row modal">
    <div class="card-row__text">
      <div class="coin-to-ticket-bus-animation-wrap">
        <div class="coin">
          <svg
            width="66"
            height="66"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(1 1)"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                stroke="#009DE0"
                stroke-width="2"
                cx="32"
                cy="32"
                r="32"
              />
              <path
                d="M24.615 46.77v-1.804h6.934v-24.5h-5.27V19.08c2.404-.416 4.3-1.063 5.686-1.85h1.664v27.736h6.426v1.803h-15.44z"
                fill="#009DE0"
              />
              <path
                d="M30.5 5.1a7.287 7.287 0 0 1 2.4 0m6.872 1.212a7.287 7.287 0 0 1 2.256.82m6.043 3.49a7.287 7.287 0 0 1 1.839 1.543m4.486 5.346a7.287 7.287 0 0 1 1.2 2.078m2.387 6.558a7.287 7.287 0 0 1 .416 2.364m0 6.978a7.287 7.287 0 0 1-.416 2.364m-2.387 6.558a7.287 7.287 0 0 1-1.2 2.078m-4.486 5.346a7.287 7.287 0 0 1-1.839 1.543m-6.043 3.49a7.287 7.287 0 0 1-2.256.82M32.9 58.9a7.287 7.287 0 0 1-2.4 0m-6.873-1.212a7.287 7.287 0 0 1-2.255-.82m-6.044-3.49a7.287 7.287 0 0 1-1.839-1.543M9.004 46.49a7.287 7.287 0 0 1-1.2-2.078m-2.387-6.558A7.287 7.287 0 0 1 5 35.489m0-6.978a7.287 7.287 0 0 1 .417-2.364m2.387-6.558a7.287 7.287 0 0 1 1.2-2.078m4.485-5.346a7.287 7.287 0 0 1 1.839-1.543m6.044-3.49a7.287 7.287 0 0 1 2.255-.82"
                stroke="#009DE0"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
        <div class="coin-slot">
          <svg
            width="88"
            height="84"
            viewBox="0 0 88 84"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M43 2v80"
                stroke="#009DE0"
                stroke-width="4"
              />
              <path
                class="explosion"
                d="M64.75 4.828l-10 17.32m25.922-1.398l-17.32 10M86.5 42.5h-20m14.172 21.75l-17.32-10m1.398 25.922l-10-17.32"
                stroke="#009DE0"
                stroke-width="2"
              />
              <path
                d="M22.634 4.828l10 17.32M6.712 20.75l17.32 10M.884 42.5h20M6.712 64.25l17.32-10m-1.398 25.922l10-17.32"
                stroke="#009DE0"
                stroke-width="0"
              />
            </g>
          </svg>
        </div>
        <div class="ticket-bus">
          <svg
            width="47"
            height="74"
            viewBox="0 0 47 74"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#009DE0"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M1 72.182v-46.76c2.83-.568 4.96-3.044 4.96-6.013S3.83 13.964 1 13.395V1h44.64v12.395c-2.83.569-4.96 3.045-4.96 6.014 0 2.969 2.13 5.445 4.96 6.014v46.759H1z"
                stroke-linecap="round"
              />
              <path
                d="M9.68 19.41h27.28"
                stroke-dasharray="4.92 2.46"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M23.32 43.975l-7.289 4.999 2.552-8.405-7.056-5.316 8.865-.196 2.928-8.284 2.928 8.284 8.865.196-7.056 5.316 2.552 8.405z"
              />
              <path d="M9.68 62.364h27.28M9.68 56.227h27.28" />
            </g>
          </svg>
        </div>
      </div>

      <div class="route">
        <div class="source">
          <span class="route-label">{{ $t('route.origin') }}</span>
          <strong class="stop">{{ getOrigin() }}</strong>
        </div>
        <i class="arrow" />
        <div class="destiny">
          <span class="route-label">{{ $t('route.destiny') }}</span>
          <strong class="stop">{{ getDestiny() }}</strong>
        </div>
      </div>

      <p class="ticket-reserved">
        {{ $t('ticket.reservation.for', { date: getDepartureCalendar() }) }}
      </p>

      <div class="agree-to-terms">
        <p>{{ $t('book.ticket.terms') }}</p>
        <div class="f-field f-field--checkbox">
          <input
            id="checkbox_id"
            v-model="acceptTerms"
            class="f-field__checkbox"
            type="checkbox"
            name="checkbox"
            value="value"
          >
          <label
            class="f-field__label"
            for="checkbox_id"
          >{{ $t('i.accept.the.terms') }}</label>
        </div>
      </div>
      <div
        class="btn--group"
      >
        <button
          :disabled="!acceptTerms"
          style="width: 100%;"
          :class="{ disabled: !acceptTerms }"
          data-qa="BookTicket"
          class="btn btn--primary"
          @click="book()"
        >
          {{ $t('book.ticket') }}
        </button>
      </div>
    </div>
  </div>
</div>
