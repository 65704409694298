import client from './client'

/**
 * Reads the a ticket for the giver trip if the logged user has one
 *
 * @param {String} tripId  An id for a the trip
 * @returns
 */
export async function getTicket (tripId) {
  return (await client.get(`/tickets/${tripId}`)).data
}

export async function validateTicket ({ ticket }) {
  return (await client.post('/tickets', { ticket })).data
}
