<template>
  <div class="send-email">
    <div>
      <section class="text-centered">
        <i class="icon">
          <svg
            class="send-email-animation"
            width="63"
            height="57"
            viewBox="0 0 63 57"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#009DE0"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
            >
              <path d="M31.5 56H2a1 1 0 0 1-1-1V22l7.945-5.493L22 8l9.5-6L41 8l13 8.507L62 22" />
              <path d="M1 22l8-5.493L22 8l9.5-6L41 8l13.055 8.507L62 22v33a1 1 0 0 1-1 1H31.5" />
              <g class="back page">
                <path
                  d="M9 54V8h45v46"
                  fill="#FFF"
                />
              </g>
              <g class="front page">
                <path
                  fill="#FFF"
                  d="M9 54V8h45v46"
                />
                <path d="M34.15 32.652c-1.305.69-2.67.98-4.093.869-2.136-.166-6.564-1.97-6.084-7.125.48-5.155 4.553-8.095 8.085-7.913 3.532.183 6.045 2.255 6.08 6.066.034 3.811-2.329 4.766-3.488 4.821-1.16.056-1.828-.942-1.663-2.053.11-.74.445-2.4 1.004-4.983" />
                <path d="M33.534 24.201c-.876-.796-1.652-1.194-2.327-1.194-1.013 0-3.24 1.023-2.962 4.093.122 1.342.877 2.337 2.181 2.011.457-.114 1.291-.678 2.503-1.692" />
              </g>
              <path
                d="M1 23.5L31.5 45 62 23v32a1 1 0 0 1-1 1H1.998a1 1 0 0 1-1-1L1 23.5z"
                fill="#FFF"
              />
            </g>
          </svg>
        </i>
        <h1 class="h2">
          {{ $t('emails') }}
        </h1>
        <p>
          {{ $t('emails.description') }}<br>
          <span><b>{{ getOrigin() }}</b></span>
          <i class="arrow inline-text" />
          <span><b>{{ getDestiny() }}</b> </span>
          <span>{{ $t('for.the.day') }} </span>
          <span><b>{{ getDepartureDay() }} </b></span>
          <span>{{ $t('at') }} </span>
          <span><b>{{ getDepartureTime() }}</b></span>
        </p>
      </section>

      <section>
        <textarea
          id="create-new-email"
          v-model="message"
          :placeholder="$t('write.a.message')"
          name="create-new-email"
          class="modal-input available-seats"
        />
      </section>

      <div class="trip-email__actions">
        <button
          class="btn btn--primary"
          @click="sendEmailForm()"
        >
          {{ $t('send.email') }}
        </button>
        <button
          class="btn"
          @click="isModalOpen = false"
        >
          {{ $t('go.back.administration') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'TripEmailModal',
  props: {
    trip: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      message: '',
      submitted: false
    }
  },
  computed: {
    subject () {
      return `Shuttle Técnico ${this.getOrigin()} - ${this.getDestiny()}: ${dayjs(this.trip.departure).format('DD-MM-YYYY - HH:mm')}`
    },
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions([
      'sendMailToTripTravelers'
    ]),
    getOrigin () {
      const route = this.trip.route
      return route.stops[0].name
    },
    getDepartureDay () {
      return dayjs(this.trip.departure).format('DD MMMM')
    },
    getDepartureTime () {
      return dayjs(this.trip.departure).format('HH:mm')
    },
    getDestiny () {
      const route = this.trip.route
      return route.stops[route.stops.length - 1].name
    },
    async sendEmailForm () {
      this.submitted = true
      if (this.message.length > 0) {
        this.submitted = false
        this.$emit('submit', { subject: this.subject, message: this.message })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trip-email__actions {
  display: flex;
  flex-direction: column;
  width: 100%;

  button + button {
    margin-top: 0.5rem;
  }
}
</style>
