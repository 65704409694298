
<div
  id="user"
  class="user-container administrator"
>
  <a
    :data-istid="loggedUser.username"
    :data-name="loggedUser.name"
    class="user-box"
    @click="toggle"
  >
    <img
      :src="loggedUser.avatar"
      :alt="loggedUser.name"
      class="user-photo"
    >
  </a>
  <div
    v-if="$slots.default"
    :class="{ active: show }"
    class="menu dropdown user-dropdown"
    @click="toggle($event)"
  >
    <ul>
      <slot />
    </ul>
  </div>
  <div class="upper-arrow" />
</div>
