
<div>
  <show-notifications :notifications="notifications" />
  <top-bar />
  <main class="main-content">
    <div class="container">
      <router-view class="col" />
    </div>
  </main>
  <bottom-bar />
  <div id="fixed-modal" />
</div>
