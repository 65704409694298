
<div
  v-if="notifications"
  id="notifications"
>
  <div class="container">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification-list"
    >
      <p>{{ notification.message[$i18n.locale] }}</p>
      <button
        class="notification-close"
        @click="hideNotification(notification.id)"
      />
    </div>
  </div>
</div>
