
<section>
  <header>
    <h1>{{ $t('routes.and.stops') }}</h1>
    <router-link
      :to="{ name: 'ListStops' }"
      class="button blue small filled"
    >
      {{ $t('modal.stops') }}
    </router-link>
  </header>
  <div class="card">
    <div
      v-for="(route, index) in routes"
      :key="route.id"
      class="card-row"
    >
      <div class="card-row__text">
        <div class="route">
          <div
            class="route__stops"
            aria-label="stops"
          >
            <div
              v-for="(stop, i) in route.stops"
              :key="generateStopId(route.id, stop.id, i)"
              class="f-tag-field__tags route__stops-tag"
            >
              <span
                v-if="i === 0"
                class="sort"
              >{{ $t('route.origin') }}</span>
              <span
                v-else-if="i === (route.stops.length -1)"
                class="sort"
              >{{ $t('route.destiny') }}</span>
              <span
                v-else
                class="sort"
              >{{ i }}-{{ $t('route.stop') }}</span>
              <div class="f-tag-field__tag f-tag-field__tag--blue f-tag-field__tag--rounded">
                <div
                  :style="{ width: 30 + stop.name.length * 6 + 'px'}"
                  class="select-wrap"
                >
                  <select
                    v-model="stop.id"
                    @change="onChangeStop(index, i, stop.id)"
                  >
                    <option
                      v-for="s in stops"
                      :key="s.id"
                      :value="s.id"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                </div>
                <button
                  class="remove"
                  @click="removeStop(index, i)"
                />
              </div>
            </div>
          </div>

          <div
            :data-label="$t('actions')"
            class="route__actions"
          >
            <div class="btn--group">
              <button
                class="btn btn--sm"
                @click="addStop(index)"
              >
                {{ $t('add.stop') }}
              </button>
              <button
                class="btn btn--sm btn--danger"
                @click="removeRoute(route, index)"
              >
                {{ $t('remove') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn--group">
    <button
      class="btn btn--primary"
      @click="addRoute"
    >
      {{ $t('create.new.route') }}
    </button>
  </div>

  <!-- Modals -->
  <Modal v-model="deleteModalOpen">
    <delete-modal
      slot="modal-panel"
      v-model="deleteModalOpen"
      :title="$t('delete.this.route')"
      :body="$t('delete.this.route.description')"
      :confirm="$t('yes.delete.this.route')"
      :cancel="$t('no.keep.this.route')"
      @submit="submitDelete"
    />
  </Modal>
  <Modal v-model="stateModalOpen">
    <Component
      :is="modalState.type"
      slot="modal-panel"
      v-model="stateModalOpen"
      :title="modalState.title"
      :body="modalState.body"
      :close="modalState.close"
    />
  </Modal>
</section>
