import client from './client'

export async function getRoutes () {
  const response = await client.get('/routes')
  return {
    routes: response.data
  }
}

export async function getRoute ({ id }) {
  const response = await client.get(`/routes/${id}`)
  return {
    route: response.data
  }
}

export async function createRoute ({ route }) {
  const response = await client.post('/routes', { route })
  return {
    route: response.data
  }
}

export async function updateRoute ({ route }) {
  const stops = route.stops
  const response = await client.put(`/routes/${route.id}`, { route: { stops } })
  return {
    route: response.data
  }
}

export async function removeRoute ({ id }) {
  await client.delete(`/routes/${id}`)
  return {}
}
