
<section>
  <header class="section-header">
    <div class="section-header__text">
      <h1 class="section-header__title">
        {{ $t('scheduler') }}
      </h1>
    </div>
    <div class="section-header__meta">
      <div class="btn--group">
        <button
          class="btn btn--primary btn--sm"
          @click.prevent="showCreateSchedulerModal()"
        >
          {{ $t('scheduler.create') }}
        </button>
      </div>
    </div>
  </header>
  <div class="scheduler-filter">
    <label for="date-filter">{{ $t('filter.month.define') }}</label>
    <input
      id="date-filter"
      v-model="selectedMonth"
      type="date"
      @input="fetchSchedulersForMonth"
    >
    <button
      v-show="selectedMonth"
      class="scheduler-filter__reset u-text-secondary"
      @click="selectedMonth = null; fetchSchedulersForMonth()"
    >
      {{ $t('clear.filter') }}
    </button>
  </div>
  <table
    v-if="schedulers.length > 0"
    class="cards admin calendar table"
  >
    <thead>
      <tr>
        <th>{{ $t('name') }}</th>
        <th>{{ $t('scheduler.create.periods') }}</th>
        <th>{{ $t('days') }}</th>
        <th class="sr-only">
          {{ $t('actions') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="scheduler in schedulers"
        :key="scheduler.trips.scheduler.id"
      >
        <td
          :data-label="$t('name')"
          class="scheduler-name"
        >
          {{ scheduler.trips.scheduler.name }}
        </td>
        <td :data-label="$t('date.periods')">
          <time
            v-for="period in scheduler.trips.scheduler.periods"
            :key="period.id"
          >{{ getDate(period.start, period.end) }}
          </time>
        </td>
        <td :data-label="$t('days')">
          <p>{{ getDays(scheduler.trips.scheduler.weekdays) }}</p>
        </td>
        <td :data-label="$t('actions')">
          <Dropdown>
            <button
              slot="dropdown-trigger"
              style="margin-left: auto;"
              class="i-ellipsis"
              aria-label="More options"
              aria-haspopup="true"
              aria-expanded="false"
            />
            <ul
              slot="dropdown-panel"
              class="dropdown-menu"
            >
              <li class="dropdown-menu__item">
                <button
                  class="dropdown-menu__link"
                  @click="showEditSchedulerModal(scheduler)"
                >
                  {{ $t('edit') }}
                </button>
              </li>
              <li class="dropdown-menu__item">
                <button
                  class="dropdown-menu__link"
                  @click="showDuplicateSchedulerModal(scheduler)"
                >
                  {{ $t('duplicate') }}
                </button>
              </li>
              <li class="dropdown-menu__item">
                <button
                  class="dropdown-menu__link"
                  @click="showRecreateSchedulerModal(scheduler)"
                >
                  {{ $t('recreate') }}
                </button>
              </li>
              <li class="dropdown-menu__item">
                <button
                  class="dropdown-menu__link u-text-danger"
                  @click="removeScheduler(scheduler)"
                >
                  {{ $t('delete') }}
                </button>
              </li>
            </ul>
          </Dropdown>
        </td>
      </tr>
    </tbody>
  </table>
  <h2 v-else>
    {{ $t('no.schedulers.defined.yet') }}
  </h2>
  <!-- Modals -->
  <Modal v-model="deleteModalOpen">
    <delete-modal
      slot="modal-panel"
      v-model="deleteModalOpen"
      :title="$t('delete.this.scheduler')"
      :body="$t('delete.this.scheduler.description')"
      :confirm="$t('yes.delete.this.scheduler')"
      :cancel="$t('no.keep.this.scheduler')"
      @submit="submitDelete"
    />
  </Modal>
  <Modal
    v-model="createScheduleOpen"
    class="modal--xlg"
  >
    <create-trips
      slot="modal-panel"
      v-model="createScheduleOpen"
      :mode="createScheduleState.mode"
      :edit="createScheduleState.edit"
      @submit="submitCreateSchedule"
    />
  </Modal>
  <Modal v-model="stateModalOpen">
    <Component
      :is="modalState.type"
      slot="modal-panel"
      v-model="stateModalOpen"
      :title="modalState.title"
      :body="modalState.body"
      :close="modalState.close"
    />
  </Modal>
</section>
