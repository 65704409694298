import client from './client'

export async function getStatsForTrips (start, end) {
  const response = await client.get('/stats/trips', {
    params: {
      start,
      end
    }
  })
  return {
    stats: response.data
  }
}

export async function getStatsForUsers (start, end) {
  const response = await client.get('/stats/users', {
    params: {
      start,
      end
    }
  })
  return {
    stats: response.data
  }
}

export async function getStatsForUsersAggregated (start, end) {
  const response = await client.get('/stats/users-aggregated', {
    params: {
      start,
      end
    }
  })
  return {
    stats: response.data
  }
}
