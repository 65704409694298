<template>
  <div class="delete-modal card">
    <div class="card-row">
      <div class="card-row__text">
        <i class="delete-modal__icon">
          <svg
            class="garbage-can"
            width="63"
            height="68"
            viewBox="0 0 63 68"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#F9690E"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <g class="garbage-body">
                  <path d="M4.575 17.088h42.9v47.258a2.5 2.5 0 0 1-2.5 2.5h-37.9a2.5 2.5 0 0 1-2.5-2.5V17.088z" />
                  <path d="M26.025 28.935V55" />
                  <path d="M14.108 28.935V55" />
                  <path d="M37.941 28.935V55" />
                </g>
                <g class="lid">
                  <path d="M1 17.088h50.05V10.11a2.5 2.5 0 0 0-2.5-2.5H3.5a2.5 2.5 0 0 0-2.5 2.5v6.978z" />
                  <path d="M17.683 7.61h16.683V4.187a2.5 2.5 0 0 0-2.5-2.5H20.183a2.5 2.5 0 0 0-2.5 2.5V7.61z" />
                </g>
              </g>
              <g transform="translate(34 33)">
                <circle
                  fill="#FFF"
                  cx="14"
                  cy="14"
                  r="14"
                />
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    class="question mark"
                    d="M17.985 18.985L9.5 10.5c0-2.5 1.985-4 4.243-4 2.257 0 4.242 2 4.242 4 0 2.282-1.414 3.782-4.242 4.5v2"
                  />
                  <path
                    class="question dot"
                    d="M13.743 21L9.5 18.985l8.485-8.485"
                  />
                </g>
              </g>
            </g>
          </svg>
        </i>
        <h1>{{ title }}</h1>
        <p>
          {{ body }}
        </p>
        <div class="delete-modal__options">
          <button
            class="btn"
            @click="closeModal"
          >
            {{ cancel }}
          </button>
          <button
            class="btn btn--danger"
            @click="$emit('submit')"
          >
            {{ confirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'DeleteModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    confirm: {
      type: String,
      required: true
    },
    cancel: {
      type: String,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions([
      'delRoute'
    ]),
    closeModal () {
      this.isModalOpen = false
    }
  }
}
</script>
<style lang="scss">
.delete-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.delete-modal__icon {
  align-self: center;
  justify-self: center;
}

.delete-modal__options {
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  button {
    width: 100%;
    margin: 0.5rem 0;
  }
}

.garbage-can {
  .question.mark {
    stroke-dasharray: 34;
    stroke-dashoffset: 22;
    animation: question-mark 4s infinite;
  }
  @keyframes question-mark {
    0%,
    25%,
    75%,
    100% {
      stroke-dasharray: 34;
      stroke-dashoffset: 22;
    }
    35%,
    65% {
      stroke-dasharray: 34;
      stroke-dashoffset: -13;
    }
  }
  .question.dot {
    stroke-dasharray: 17;
    stroke-dashoffset: 29;
    animation: question-dot 4s infinite;
  }
  @keyframes question-dot {
    0%,
    25%,
    75%,
    100% {
      stroke-dasharray: 17;
      stroke-dashoffset: 29;
      stroke-width: 2;
    }
    32%,
    65% {
      stroke-dasharray: 17;
      stroke-dashoffset: 50.9;
      stroke-width: 2.2;
    }
  }

  overflow: visible !important;
  .lid {
    transform-origin: top;
    fill: white;
    animation: garbage-lid 6s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  }

  @keyframes garbage-lid {
    0% {
      transform: translateX(0) translateY(0) rotate(0);
    }
    15% {
      transform: translateX(0) translateY(10px) rotate(0);
    }
    20% {
      transform: translateX(0) translateY(-10px) rotate(-5deg);
    }

    25% {
      transform: translateX(0) translateY(0) rotate(4deg);
    }

    30% {
      transform: translateX(0) translateY(-2px) rotate(-3deg);
    }

    32% {
      transform: translateX(0) translateY(0) rotate(2deg);
    }

    33% {
      transform: translateX(0) translateY(0) rotate(1deg);
    }

    36% {
      transform: translateX(0) translateY(0) rotate(0);
    }
  }

  .garbage-body {
    transform-origin: bottom;
    animation: 6s garbage-body cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  }

  @keyframes garbage-body {
    0% {
      transform: scaleY(1);
    }

    15% {
      transform: scaleY(0.8);
    }

    20% {
      transform: scaleY(1);
    }
  }
}

</style>
