<template>
  <section>
    <h1 data-qa="title">
      {{ $t('reports') }}
    </h1>
    <h4>{{ $t('shuttle.usage.stats') }}</h4>
    <table class="cards table schedule-table reports-page__cards">
      <thead>
        <tr>
          <th>{{ $t('start') }}</th>
          <th>{{ $t('end') }}</th>
          <th :aria-label="$t('actions')" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :data-label="$t('start')">
            <p
              v-if="!pickedDateBetweenForTrips"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenForTrips.start).toLocaleDateString() }}
            </p>
          </td>
          <td :data-label="$t('end')">
            <p
              v-if="!pickedDateBetweenForTrips"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenForTrips.end).toLocaleDateString() }}
            </p>
          </td>
          <td
            class="btn--group"
          >
            <date-picker
              v-model="pickedDateBetweenForTrips"
              :range-mode="true"
              :before="true"
              name="periodInsertForTrips"
            >
              <button
                slot="trigger"
                class="btn btn--sm"
              >
                {{ $t('define.date') }}
              </button>
            </date-picker>

            <button
              :class="{ 'disabled': !pickedDateBetweenForTrips}"
              href="#"
              class="btn btn--sm btn--primary"
              @click.prevent="downloadStatsForTrips()"
            >
              {{ $t('download.csv') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <h4>{{ $t('people.who.missed.the.shuttle') }}</h4>
    <table class="cards table schedule-table">
      <thead>
        <tr>
          <th>{{ $t('start') }}</th>
          <th>{{ $t('end') }}</th>
          <th :aria-label="$t('actions')" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :data-label="$t('start')">
            <p
              v-if="!pickedDateBetweenForUsers"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenForUsers.start).toLocaleDateString() }}
            </p>
          </td>
          <td :data-label="$t('end')">
            <p

              v-if="!pickedDateBetweenForUsers"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenForUsers.end).toLocaleDateString() }}
            </p>
          </td>
          <td
            class="btn--group"
          >
            <date-picker
              v-model="pickedDateBetweenForUsers"
              :range-mode="true"
              :before="true"
              name="periodInsertForUsers"
            >
              <button
                slot="trigger"
                class="btn btn--sm"
                style="z-index: 0;"
              >
                {{ $t('define.date') }}
              </button>
            </date-picker>
            <button
              :class="{ 'disabled': !pickedDateBetweenForUsers}"
              class="btn btn--sm btn--primary"
              @click.prevent="downloadStatsForUsers()"
            >
              {{ $t('download.csv') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <h4>{{ $t('stats.aggregated.by.user') }}</h4>
    <table class="cards table schedule-table">
      <thead>
        <tr>
          <th>{{ $t('start') }}</th>
          <th>{{ $t('end') }}</th>
          <th :aria-label="$t('actions')" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :data-label="$t('start')">
            <p
              v-if="!pickedDateBetweenUsersAggregated"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenUsersAggregated.start).toLocaleDateString() }}
            </p>
          </td>
          <td :data-label="$t('end')">
            <p

              v-if="!pickedDateBetweenUsersAggregated"
              class="disabled"
            >
              {{ $t('pick.a.date') }}
            </p>
            <p v-else>
              {{ new Date(pickedDateBetweenUsersAggregated.end).toLocaleDateString() }}
            </p>
          </td>
          <td
            class="btn--group"
          >
            <date-picker
              v-model="pickedDateBetweenUsersAggregated"
              :range-mode="true"
              :before="true"
              name="periodInsertForUsers"
            >
              <button
                slot="trigger"
                class="btn btn--sm"
                style="z-index: 0;"
              >
                {{ $t('define.date') }}
              </button>
            </date-picker>
            <button
              :class="{ 'disabled': !pickedDateBetweenUsersAggregated}"
              class="btn btn--sm btn--primary"
              @click.prevent="downloadStatsForUsersAggregated()"
            >
              {{ $t('download.csv') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { getStatsForTrips, getStatsForUsers, getStatsForUsersAggregated } from '@/api/stats'
import DatePicker from '@/components/utils/DatePicker.vue'
import StatsToCsv from '@/mixins/CsvGenerator'

import dayjs from 'dayjs'

export default {
  name: 'ReportsPage',
  components: {
    DatePicker
  },
  mixins: [
    StatsToCsv
  ],
  data () {
    return {
      trips: '0',
      selectedOption: {},
      navOptions: [
        {
          index: 0,
          name: 'stats',
          active: true
        }
      ],
      pickedDateBetweenForTrips: null,
      pickedDateBetweenForUsers: null,
      pickedDateBetweenUsersAggregated: null,
      isDataPicked: false
    }
  },
  created () {
    this.selectedOption = this.navOptions[0]
  },
  methods: {
    changeView: function (index) {
      this.navOptions[this.selectedOption.index].active = false
      this.navOptions[index].active = true
      this.selectedOption = this.navOptions[index]
    },
    async downloadStatsForTrips () {
      const start = this.getIsoStart(this.pickedDateBetweenForTrips.start)
      const end = this.pickedDateBetweenForTrips.end ? this.getIsoEnd(this.pickedDateBetweenForTrips.end) : this.getIsoEnd(this.pickedDateBetweenForTrips.start)
      const result = await getStatsForTrips(start, end)
      const csv = this.StatsToCsv(result.stats)
      let filename
      if (!end) {
        filename = `${start.substr(0, 10)}`
      } else {
        filename = `${start.substr(0, 10)}_${end.substr(0, 10)}`
      }
      this.downloadCSV(filename, csv)
    },
    async downloadStatsForUsers () {
      const start = this.getIsoStart(this.pickedDateBetweenForUsers.start)
      const end = this.pickedDateBetweenForUsers.end ? this.getIsoEnd(this.pickedDateBetweenForUsers.end) : this.getIsoEnd(this.pickedDateBetweenForUsers.start)
      const result = await getStatsForUsers(start, end)
      const csv = this.FailingPeopleToCsv(result.stats)
      let filename
      if (!end) {
        filename = `${start.substr(0, 10)}`
      } else {
        filename = `${start.substr(0, 10)}_${end.substr(0, 10)}`
      }
      this.downloadCSV(filename, csv)
    },
    async downloadStatsForUsersAggregated () {
      const start = this.getIsoStart(this.pickedDateBetweenUsersAggregated.start)
      const end = this.pickedDateBetweenUsersAggregated.end ? this.getIsoEnd(this.pickedDateBetweenUsersAggregated.end) : this.getIsoEnd(this.pickedDateBetweenUsersAggregated.start)
      const result = await getStatsForUsersAggregated(start, end)
      const csv = this.usersAggregatedToCsv(result.stats)
      let filename
      if (!end) {
        filename = `${start.substr(0, 10)}`
      } else {
        filename = `${start.substr(0, 10)}_${end.substr(0, 10)}`
      }
      this.downloadCSV(filename, csv)
    },
    async downloadCSV (filename, data) {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
      const fakeLink = document.createElement('a')
      fakeLink.href = window.URL.createObjectURL(blob)
      fakeLink.download = `${filename}.csv`
      document.body.appendChild(fakeLink)
      fakeLink.click()
      setTimeout(function () {
        document.body.removeChild(fakeLink)
        window.URL.revokeObjectURL(fakeLink.href)
      }, 100)
    },
    getIsoStart (date) {
      return dayjs(date).startOf('day').toISOString()
    },
    getIsoEnd (date) {
      return dayjs(date).endOf('day').toISOString()
    }
  }
}
</script>

<style lang="scss">
.cards.table.schedule-table.reports-page__cards thead tr,
.cards.table.schedule-table.reports-page__cards tbody tr {
  justify-content: space-between;
}

.actions.sr-only {
  position: unset;
  visibility: hidden;
}

.reports-page__actions {
  justify-content: flex-end !important;
  position: relative;
}
</style>
