
<nav
  class="page-nav"
  aria-label="Navegação de artigos"
>
  <ul role="navigation">
    <li v-if="paginationData.currentPage > 1">
      <a
        href=""
        class="page-button"
        aria-label="Previous"
        @click.prevent="changePage(paginationData.currentPage - 1)"
      >
        {{ $t('previous') }}
      </a>
    </li>
    <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(1)">
      <a
        href=""
        @click.prevent="changePage(1)"
      >1</a>
    </li>
    <li v-if="pagesToShow[0] > 2">
      <span>...</span>
    </li>
    <li
      v-for="page in pagesToShow"
      :key="page"
      :class="{'current': page == paginationData.currentPage}"
    >
      <a
        href=""
        @click.prevent="changePage(page)"
      >{{ page }}</a>
    </li>
    <li v-if="pagesToShow[pagesToShow.length - 1] < paginationData.totalPages - 1">
      <span>...</span>
    </li>
    <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(paginationData.totalPages)">
      <a
        href=""
        @click.prevent="changePage(paginationData.totalPages)"
      >{{ paginationData.totalPages }}</a>
    </li>
    <li v-if="paginationData.currentPage < paginationData.totalPages">
      <a
        href=""
        class="page-button"
        @click.prevent="changePage(paginationData.currentPage + 1)"
      >
        {{ $t('next') }}
      </a>
    </li>
  </ul>
</nav>
