
<div class="card">
  <div class="card-row">
    <div class="card-row__text">
      <div class="modal cancel-trip">
        <i class="icon cancel-trip__icon">
          <svg
            class="cancel-trip-animation"
            width="64"
            height="68"
            viewBox="0 0 64 68"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path
                id="a"
                d="M5 30h44v2H5z"
              />
              <path
                id="b"
                d="M5 8h44v2H5z"
              />
              <path
                id="c"
                d="M21 39h12v2.078H21z"
              />
            </defs>
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g transform="translate(0 14)">
                <path
                  d="M8 47v5.002c0 .556.443.998.997.998h5.006c.55 0 .997-.447.997-.998V47H8z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <circle
                  stroke="#009DE0"
                  stroke-width="2"
                  cx="12"
                  cy="39"
                  r="3"
                />
                <circle
                  stroke="#009DE0"
                  stroke-width="2"
                  cx="42"
                  cy="39"
                  r="3"
                />
                <path
                  stroke="#009DE0"
                  d="M5.5 30.5h43v1h-43z"
                />
                <path
                  stroke="#009DE0"
                  d="M5.5 8.5h43v1h-43z"
                />
                <path
                  d="M39 47v5.002c0 .556.443.998.997.998h5.006c.55 0 .997-.447.997-.998V47h-7z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  d="M4 47h46V2.001A1 1 0 0 0 48.999 1H5a1 1 0 0 0-1 1.001V47z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  stroke="#009DE0"
                  d="M21.5 39.5h11v1.078h-11z"
                />
                <path
                  d="M50 11v12h1.5c.834 0 1.5-.669 1.5-1.503v-8.994c0-.83-.672-1.503-1.5-1.503H50z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
                <path
                  d="M4 23V11H2.5c-.834 0-1.5.669-1.5 1.503v8.994C1 22.327 1.672 23 2.5 23H4z"
                  stroke="#009DE0"
                  stroke-width="2"
                />
              </g>
              <g
                transform="translate(35 1)"
                stroke="#009DE0"
                stroke-width="2"
              >
                <circle
                  cx="14"
                  cy="14"
                  r="14"
                  fill="#fff"
                />
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    class="question mark"
                    d="M18.485 18.485L10 10c0-2.5 1.985-4 4.243-4 2.257 0 4.242 2 4.242 4 0 2.282-1.414 3.782-4.242 4.5v2"
                  />
                  <path
                    class="question dot"
                    d="M14.243 20.5L10 18.485 18.485 10"
                  />
                </g>
              </g>
            </g>
          </svg>
        </i>

        <h1 class="h2 cancel-trip__header">
          {{ $t('cancel.trip') }}
        </h1>

        <p class="cancel-trip__paragraph">
          {{ $t('cancel.trip.confirmation.message') }}
        </p>
        <p class="cancel-trip__paragraph">
          {{ $t('are.you.sure.you.want.to.continue') }}
        </p>
        <div class="cancel-trip__actions">
          <button
            class="btn"
            @click.prevent="isModalOpen = false"
          >
            {{ $t('no.keep.trip') }}
          </button>

          <button
            class="btn btn--danger"
            @click.prevent="$emit('submit', trip.id)"
          >
            {{ $t('yes.cancel.trip') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
