import client from './client'

export async function getSchedulers ({ start, end }) {
  let queryString = ''
  let hasVariable = false
  if (start || end) {
    queryString += '?'
  }
  if (start) {
    queryString += `start=${start}`
    hasVariable = true
  }
  if (end) {
    queryString += hasVariable ? '&' : ''
    queryString += `end=${end}`
  }
  const response = await client.get(`/trips/schedulers${queryString}`)
  return {
    schedulers: response.data
  }
}

export async function updateScheduler ({ id, trips }) {
  const response = await client.put(`/trips/schedulers/${id}`, { trips })
  return response.data
}

export async function deleteScheduler ({ id }) {
  const response = await client.delete(`/trips?schedulerId=${id}`)
  return {
    schedulers: response.data
  }
}
