import client from './client'
import dayjs from 'dayjs'

export const getNextTrips = async () => {
  const start = dayjs().subtract(30, 'minutes').valueOf()
  const end = dayjs(start).add(7, 'days').endOf('day').valueOf()
  const response = await client.get(`/trips?start=${start}&end=${end}`)
  return response.data
}

export const getTripsBetween = async ({ start, end }) => {
  const response = await client.get(`/trips?start=${start}&end=${end}`)
  return response.data
}

export const getTrip = async ({ id }) => {
  const response = await client.get(`/trips/${id}`)
  return response.data
}

export const createTrips = async ({ trips }) => {
  const response = await client.post('/trips', { trips })
  return response.data
}

export const updateTripCapacity = async ({ id, capacity }) => {
  const response = await client.put(`/trips/${id}`, { capacity })
  return response.data
}

export const cancelTrip = async ({ id }) => {
  await client.delete(`/trips/${id}`)
  return true
}

export const sendEmailToTravelers = async ({ id, subject, message }) => {
  const response = await client.post(`/trips/${id}/emails`, {
    subject,
    message
  })
  return response
}

export default {
  getNextTrips,
  getTripsBetween,
  createTrips,
  updateTripCapacity,
  cancelTrip,
  sendEmailToTravelers
}
