<template>
  <div class="manage-trip">
    <section>
      <fieldset :disabled="tripData.state === 'canceled'">
        <h1 class="h2">
          {{ $t('manage.trip') }}
        </h1>

        <section>
          <h3>{{ $t('information.about.this.trip') }}</h3>
          <div class="trip-info">
            <div class="trip-info__element">
              <strong>{{ $t('route') }}</strong>
              <p>
                <span>{{ getOrigin() }}</span>
                <i class="arrow inline-text" />
                <span>{{ getDestiny() }}</span>
              </p>
            </div>
            <div class="trip-info__element">
              <strong>{{ $t('departure') }}</strong>
              <p>{{ getDepartureTime() }}</p>
            </div>
            <div class="trip-info__element">
              <strong>{{ $t('stops') }}</strong>
              <p>
                <span
                  v-for="stop in tripData.route.stops"
                  :key="stop.id"
                  style="display: block;"
                >{{ stop.name }}</span>
              </p>
            </div>
            <div class="trip-info__element">
              <strong>{{ $t('subscribed') }}</strong>
              <p>{{ bookingsCount }}</p>
            </div>
            <div class="trip-info__element">
              <strong>{{ $t('state') }}</strong>
              <p>{{ $t(tripData.state) }}</p>
            </div>
          </div>
        </section>

        <section>
          <h3>{{ $t('ticket.attribution') }}</h3>
          <div class="half-wrapper">
            <div class="half">
              <label
                class="pre-header"
                for="available-seats"
              >{{ $t('number.of.available.seats') }}</label>
              <input
                id="available-seats"
                v-model="tripData.capacity"
                type="number"
                name="available-seats"
                class="modal-input available-seats"
                value=""
                data-filled="false"
                placeholder="Nº de lugares"
                @change="update"
              >
            </div>
            <div class="half">
              <label
                class="pre-header"
                for="available-seats"
              >{{ $t('ticket.allocation.strategy') }}</label>
              <div class="select-wrapper">
                <select
                  class="modal-input"
                  disabled
                >
                  <option value="First come first served">
                    {{ $t('first.come.first.served') }}
                  </option>
                </select>
              </div>
            </div>
            <div
              :class="{hidden: tripData.bookings.length <= tripData.capacity}"
              class="validation warning  available-seats-warning"
            >
              <p>{{ $t('more.people.than.seats.warning') }}</p>
            </div>
            <div class="validation warning hidden strategy-warning">
              <p>{{ $t('first.come.first.served.description') }}</p>
            </div>
          </div>
        </section>

        <section v-if="canBook()">
          <section>
            <h3>{{ $t('external.bookings') }}</h3>
          </section>
          <section>
            <strong class="pre-header">{{ $t('external.bookings') }}</strong>
            <p>{{ $t('external.bookings.info') }}</p>
            <div class="card">
              <div
                v-for="bookingEmail in nonCanceledExternalBookingEmails"
                :key="bookingEmail"
                class="card-row"
              >
                <div class="card-row__text">
                  <p>{{ bookingEmail }}</p>
                </div>
                <div class="card-row__meta">
                  <button
                    class="button small circle delete"
                    @click.prevent="removeExternalBooking(bookingEmail)"
                  >
                    {{ $t('delete') }}
                  </button>
                </div>
              </div>
              <div class="card-row">
                <div class="card-row__text">
                  <label for="email-input">{{ $t('email') }}</label>
                  <input
                    id="email-input"
                    v-model="newExternalBookingEmail"
                    :placeholder="$t('external.person.email')"
                    type="email"
                    class="modal-input"
                    value=""
                    data-filled="false"
                  >
                  <label for="name-input">{{ $t('name') }}</label>
                  <input
                    v-model="newExternalBookingName"
                    :placeholder="$t('external.person.name')"
                    type="text"
                    class="modal-input"
                    value=""
                    data-filled="false"
                  >
                </div>
                <div class="card-row__meta" />
              </div>
            </div>
            <div class="btn--group">
              <button
                class="btn btn--primary"
                @click.prevent="addExternalBooking()"
              >
                {{ $t('book.ticket') }}
              </button>
            </div>
            <div
              v-if="displayNewExternalBookingWarning"
              class="validation warning"
            >
              <p>{{ $t('invalid.email') }}</p>
            </div>
          </section>
        </section>

        <section>
          <h3>{{ $t('bookings.state') }}</h3>
          <input
            v-model="bookingsFilter"
            :placeholder="$t('search.the.table')"
            type="text"
            value=""
            class="modal-input"
            data-filled="false"
          >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  {{ $t('identifier') }}
                </th>
                <th scope="col">
                  {{ $t('current.state') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(booking, i) in bookings"
                :key="i"
              >
                <th scope="row">
                  {{ i + 1 }}
                </th>
                <th style="font-weight: normal;">
                  {{ booking.owner }}
                </th>
                <th :style="`font-weight: normal;${getBookingStateColor(booking.state)}`">
                  {{ $t(booking.state) }}
                </th>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <h3>{{ $t('other.actions') }}</h3>
          <div class="btn--group">
            <button
              class="btn"
              @click.prevent="showTripEmailModal"
            >
              {{ $t('send.email.to.subscribed') }}
            </button>
            <button
              class="btn btn--danger"
              @click.prevent="showCancelTripModal"
            >
              {{ $t('cancel.trip') }}
            </button>
          </div>
        </section>
      </fieldset>
    </section>
    <!-- All possible modals -->
    <Modal v-model="cancelTripOpen">
      <cancel-trip
        slot="modal-panel"
        v-model="cancelTripOpen"
        :trip="tripData"
        @submit="submitCancelTrip"
      />
    </Modal>

    <Modal v-model="tripEmailModalOpen">
      <trip-email-modal
        slot="modal-panel"
        v-model="tripEmailModalOpen"
        :trip="tripData"
        @submit="submitEmail"
      />
    </Modal>

    <Modal v-model="stateModalOpen">
      <Component
        :is="modalState.type"
        slot="modal-panel"
        v-model="stateModalOpen"
        :title="modalState.title"
        :body="modalState.body"
        :close="modalState.close"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CancelTrip from '@/components/trips/CancelTrip.vue'
import TripEmailModal from '@/components/trips/TripEmailModal.vue'
import SuccessModal from '@/components/utils/SuccessModal.vue'
import ErrorModal from '@/components/utils/ErrorModal.vue'
import Modal from '@/components/utils/Modal.vue'
import { cancelTrip, getTrip, sendEmailToTravelers } from '@/api/trips'
import { createExternalBooking, removeExternalBooking } from '@/api/bookings'
import dayjs from 'dayjs'

export default {
  name: 'ManageTrip',
  components: {
    CancelTrip,
    TripEmailModal,
    SuccessModal,
    ErrorModal,
    Modal
  },
  props: {
    trip: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tripData: this.trip,
      nonCanceledExternalBookingEmails: [],
      showNewExternalBooking: true,
      newExternalBookingEmail: '',
      newExternalBookingName: '',
      displayNewExternalBookingWarning: false,
      cancelTripOpen: false,
      stateModalOpen: false,
      modalState: {},
      bookingsFilter: '',
      tripEmailModalOpen: false
    }
  },
  computed: {
    bookingsCount () {
      return this.tripData.bookings.reduce((acc, booking) => {
        if (booking.state === 'confirmed' || booking.state === 'pending') {
          return acc + 1
        }
        return acc
      }, 0)
    },
    bookings () {
      if (this.bookingsFilter.length > 0) {
        const filter = this.bookingsFilter.toLowerCase()
        return this.allBookings.filter(booking => booking.owner.toLowerCase().includes(filter) || this.$t(booking.state).toLowerCase().includes(filter))
      } else {
        return this.allBookings
      }
    },
    allBookings () {
      return this.getBookings()
    }
  },
  created () {
    this.nonCanceledExternalBookingEmails = this.tripData.bookings
      .filter(booking => booking.state !== 'canceled' && this.isEmail(booking.owner))
      .map(booking => booking.owner)
  },
  methods: {
    ...mapActions([
      'editTripCapacity'
    ]),
    showCancelTripModal () {
      this.cancelTripOpen = true
    },
    showTripEmailModal () {
      this.tripEmailModalOpen = true
    },
    getOrigin () {
      const route = this.tripData.route
      return route.stops[0].name
    },
    getDepartureTime () {
      return dayjs(this.tripData.departure).format('HH:mm')
    },
    getDestiny () {
      const route = this.tripData.route
      return route.stops[route.stops.length - 1].name
    },
    async update () {
      if (this.tripData.capacity) {
        const seats = this.tripData.capacity
        await this.editTripCapacity({ id: this.tripData.id, capacity: seats })
      }
    },
    isEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async addExternalBooking () {
      if (this.isEmail(this.newExternalBookingEmail)) {
        this.displayNewExternalBookingWarning = false
        try {
          await createExternalBooking({
            trip: this.tripData.id,
            externalUserEmail: this.newExternalBookingEmail,
            externalUserName: this.newExternalBookingName
          })
          this.tripData = await getTrip({ id: decodeURIComponent(this.tripData.id) })
          this.nonCanceledExternalBookingEmails = [
            ...this.nonCanceledExternalBookingEmails,
            this.newExternalBookingEmail
          ]
          this.newExternalBookingEmail = ''
          this.newExternalBookingName = ''
          this.stateModalOpen = true
          this.modalState = {
            type: 'SuccessModal',
            title: this.$t('success.reservation'),
            body: this.$t('success.reservation'),
            close: this.$t('back')
          }
        } catch (err) {
          this.stateModalOpen = true
          this.modalState = {
            type: 'ErrorModal',
            title: this.$t('impossible.to.book.ticket'),
            body: err.response.data.key === 'capacity-exceeded' ? err.response.data.translations[this.$i18n.locale] : this.$t('impossible.to.book.ticket.description'),
            close: this.$t('back')
          }
        }
      } else {
        this.displayNewExternalBookingWarning = true
      }
    },
    async removeExternalBooking (bookingEmail) {
      try {
        await removeExternalBooking({
          trip: this.tripData.id,
          externalUserEmail: bookingEmail
        })
        this.tripData = await getTrip({ id: decodeURIComponent(this.tripData.id) })
        this.nonCanceledExternalBookingEmails = this.nonCanceledExternalBookingEmails.filter(bE => bE !== bookingEmail)
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: this.$t('ticket.cancelled.successfully'),
          body: this.$t('ticket.cancelled.successfully'),
          close: this.$t('go.back')
        }
      } catch (err) {
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: this.$t('unable.to.cancel.ticket'),
          body: this.$t('unable.to.cancel.ticket'),
          close: this.$t('go.back')
        }
      }
    },
    getBookings () {
      const bookingsObj = {}
      for (const booking of this.tripData.bookings) {
        if (!bookingsObj[booking.owner] || dayjs(booking.ts).isAfter(dayjs(bookingsObj[booking.owner].ts))) {
          bookingsObj[booking.owner] = { ...booking }
        }
      }
      for (const traveler of this.tripData.travelers) {
        bookingsObj[traveler.owner].state = 'traveled'
      }
      const bookings = Object.values(bookingsObj)
      const hasArrived = dayjs(this.tripData.departure).add(2, 'hours').isBefore(dayjs())
      if (hasArrived) {
        for (const booking of bookings) {
          if (booking.state === 'confirmed') {
            booking.state = 'missed'
          }
        }
      }
      return bookings.sort((a, b) => {
        const ats = dayjs(a.ts)
        const bts = dayjs(b.ts)
        if (ats.isBefore(bts)) return -1
        if (ats.isAfter(bts)) return 1
        return 0
      })
    },
    getBookingStateColor (state) {
      switch (state) {
        case 'traveled':
          return ' color: green;'
        case 'missed':
          return ' color: red;'
        default:
          return ''
      }
    },
    canBook () {
      return dayjs(this.tripData.departure).add(2, 'hours').isAfter(dayjs())
    },
    async submitEmail (email) {
      try {
        await sendEmailToTravelers({ id: this.trip.id, ...email })
        this.tripEmailModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: 'Email enviado com sucesso',
          body: 'Email enviado com sucesso',
          close: 'Voltar'
        }
      } catch (err) {
        this.tripEmailModalOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Não foi possível enviar o email',
          body: 'Não foi possível enviar o email',
          close: 'Voltar'
        }
      }
    },
    async submitCancelTrip () {
      try {
        await cancelTrip({ id: this.tripData.id })
        this.tripData = await getTrip({ id: decodeURIComponent(this.tripData.id) })

        this.cancelTripOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'SuccessModal',
          title: 'Viagem cancelada com sucesso',
          body: 'Viagem cancelada com sucesso',
          close: 'Voltar'
        }
      } catch (err) {
        this.cancelTripOpen = false
        await this.$nextTick()
        this.stateModalOpen = true
        this.modalState = {
          type: 'ErrorModal',
          title: 'Não foi possivel cancelar a viagem',
          body: 'Não foi possivel cancelar a viagem',
          close: 'Voltar'
        }
      }
    }
  },
  i18n: {
    messages: {
      pt: {
        state: 'Estado',
        confirmed: 'Confirmada',
        active: 'Ativa',
        canceled: 'Cancelada',
        performed: 'Realizada'
      },
      en: {
        state: 'State',
        confirmed: 'Confirmed',
        active: 'Active',
        canceled: 'Canceled',
        performed: 'Performed'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.trip-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  padding: 1rem 0;
  margin-top: 0.5rem;
  @media (max-width: 30rem) {
    flex-direction: column;
  }
}

.trip-info__element {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}
.trip-info__element + .trip-info__element {
  @media (max-width: 30rem) {
    margin-top: 0.75rem;
  }
}
</style>
