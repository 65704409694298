import axios from 'axios'

const client = axios.create({
  baseURL: '/api'
})

client.interceptors.request.use(function (config) {
  if (!['get', 'head'].includes(config.method.toLowerCase())) {
    try {
      const crumbCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('crumb=')).split('=')[1]
      config.headers['X-CSRF-Token'] = crumbCookie
    } catch (error) {
      return config
    }
  }
  return config
})

export default client
