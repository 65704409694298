<template>
  <div
    id="user"
    class="user-container administrator"
  >
    <a
      :data-istid="loggedUser.username"
      :data-name="loggedUser.name"
      class="user-box"
      @click="toggle"
    >
      <img
        :src="loggedUser.avatar"
        :alt="loggedUser.name"
        class="user-photo"
      >
    </a>
    <div
      v-if="$slots.default"
      :class="{ active: show }"
      class="menu dropdown user-dropdown"
      @click="toggle($event)"
    >
      <ul>
        <slot />
      </ul>
    </div>
    <div class="upper-arrow" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState([
      'loggedUser'
    ])
  },
  methods: {
    toggle (e) {
      this.show = !this.show
      if (this.show) {
        setTimeout(() => window.addEventListener('click', this.clickOutEvent), 10)
      }
    },
    close () {
      this.show = false
    },
    clickOutEvent (e) {
      const el = this.$el.children[1]
      if (e.target !== el && !el.contains(e.target)) {
        this.close()
      }
      window.removeEventListener('click', this.clickOutEvent)
    }
  },
  events: {
    'hide::dropdown' () {
      this.show = false
    }
  }
}
</script>
