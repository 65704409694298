
<transition name="modal-slide-down">
  <div
    v-if="value"
    :id="id"
    class="modal"
    role="dialog"
    aria-modal="true"
    v-bind="$attrs"
    @keydown.esc="trap.deactivate"
  >
    <button
      v-if="hasCloseButton"
      role="button"
      class="modal__close"
      :aria-label="$t('close-button-aria-label')"
      @click.prevent="close"
    >
      <svg
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fill-rule="evenodd"
        >
          <circle
            stroke="#DDE4E9"
            stroke-width="2.5"
            fill="#DDE4E9"
            cx="20"
            cy="20"
            r="18.75"
          />
          <g
            opacity=".5"
            stroke="#1C172F"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          >
            <path d="M14.12 14.12l11.64 11.64M25.76 14.12L14.12 25.76" />
          </g>
        </g>
      </svg>
    </button>
    <div class="modal__panel">
      <div
        :id="`${id}-modal-container`"
        class="modal__container u-a11y-focusable"
        tabindex="-1"
      >
        <slot name="modal-panel" />
      </div>
    </div>
    <!-- FIXME: this footer hides the scrollbar of the modal's body -->
    <footer
      v-if="!!$slots['modal-footer']"
      class="modal__footer"
    >
      <slot name="modal-footer" />
    </footer>
  </div>
</transition>
