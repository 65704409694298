
<div class="modal card">
  <div class="card-row">
    <div class="error-modal card-row__text">
      <i class="icon">
        <svg
          class="error-modal-svg error-icon"
          width="66"
          height="66"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="#F9690E"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              class="circle"
              cx="33"
              cy="33"
              r="32"
            />
            <g
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                class="line"
                d="M22.393 43.607l21.214-21.214"
              />
              <path
                class="line"
                d="M22.393 22.393l21.214 21.214"
              />
            </g>
          </g>
        </svg>
      </i>

      <h1 class="error-modal__header">
        {{ title }}
      </h1>
      <p class="error-modal__paragraph">
        {{ body }}
      </p>
      <div class="error-modal__actions">
        <button
          class="button blue filled"
          @click.prevent="isModalOpen = false"
        >
          {{ close }}
        </button>
      </div>
    </div>
  </div>
</div>
