<template>
  <section>
    <h1 data-qa="title">
      {{ $t('my.tickets') }}
    </h1>
    <nav class="tab navigation">
      <ul>
        <li
          v-for="(option, index) in navOptions"
          :key="index"
        >
          <a
            :class="{ active: option.active }"
            @click="changeView(index)"
          >{{ $t(option.name) }}</a>
        </li>
      </ul>
    </nav>
    <list-tickets v-if="selectedOption.index === 0" />
    <traveled-trips v-else />
  </section>
</template>

<script>
import ListTickets from '@/components/tickets/ListTickets.vue'
import TraveledTrips from '@/components/traveled-trips/TraveledTrips.vue'

export default {
  name: 'MyTickets',
  components: {
    ListTickets,
    TraveledTrips
  },
  data () {
    return {
      selectedOption: {},
      navOptions: [{
        index: 0,
        name: 'scheduled.trips',
        active: true
      }, {
        index: 1,
        name: 'historical.trips',
        active: false
      }]
    }
  },
  created () {
    this.selectedOption = this.navOptions[0]
  },
  methods: {
    changeView: function (index) {
      this.navOptions[this.selectedOption.index].active = false
      this.navOptions[index].active = true
      this.selectedOption = this.navOptions[index]
    }
  }
}
</script>
