<template>
  <div class="modal card">
    <div class="card-row">
      <div class="card-row__text success-modal">
        <i class="icon">
          <svg
            class="success-animation"
            width="90"
            height="102"
            viewBox="0 0 90 102"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(1 1)"
              stroke="#009DE0"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                class="success-animation-circle"
                stroke-width="10"
                cx="44"
                cy="50"
                r="27"
              />
              <path
                class="check"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M33 50.578l8.911 9.542L55 39"
              />
              <path
                class="lines"
                d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                stroke-width="2"
                stroke-linecap="round"
              />
            </g>
          </svg>
        </i>
        <h1 class="success-modal__header">
          {{ title }}
        </h1>
        <p class="success-modal__message">
          {{ body }}
        </p>
        <div class="success-modal__actions">
          <button
            class="btn btn--primary"
            @click.prevent="isModalOpen = false"
          >
            {{ close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuccessModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    close: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.success-animation {
  .success-animation-circle {
    transform-origin: center;
    opacity: 0;
    animation: circle-explosion 1s forwards;
    animation-delay: 0.25s;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .lines {
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
    opacity: 0;
    animation: path-explosion 2s forwards;
    animation-delay: 0.25s;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
    opacity: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}

.success-modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  text-align: center;
}

.success-modal__header {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $light-gray;
}

.success-modal__message {
  font-size: 14px;
  margin: 1.5rem 0;
}

.success-modal__actions {
  margin-top: 1.5rem;
  width: 100%;
  button {
    width: 100%;
  }
}

</style>
