<template>
  <div>
    <show-notifications :notifications="notifications" />
    <top-bar />
    <main class="main-content">
      <div class="container">
        <router-view class="col" />
      </div>
    </main>
    <bottom-bar />
    <div id="fixed-modal" />
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import BottomBar from '@/components/BottomBar'
import ShowNotifications from '@/components/notifications/ShowNotifications'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'WithTopBar',
  components: {
    TopBar,
    BottomBar,
    ShowNotifications
  },
  computed: {
    ...mapState([
      'notifications'
    ])
  },
  created () {
    this.fetchNotifications({ viewed: true })
  },
  methods: {
    ...mapActions([
      'fetchNotifications'
    ])
  }
}
</script>

<style lang="scss">
/*
* .container {
*  margin: 0 auto;
*  width: 960px;
*  display: flex;
*  .content {
*    width: 100%;
*  }
*}

*/

.main-content {
  min-height: 85vh;
}
</style>
