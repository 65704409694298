
<section>
  <header class="section-header">
    <h1 class="section-header__title">
      {{ $t('trips.next') }}
    </h1>
    <div class="section-header__meta">
      <date-picker
        v-model="selectedTs"
        :before="true"
        name="next-trips-calendar-picker"
        @input="selectFromDatePicker(selectedTs)"
      >
        <button
          slot="trigger"
          class="btn btn--outline btn--primary"
        >
          {{ $t('notifications.date.define') }}
        </button>
      </date-picker>
      <select
        v-model="selectedRoute"
        class="modal-input"
        style="font-size: larger;"
        @change="showUpdatedTrips"
      >
        <option :value="null">
          &#8645; {{ $t('all.routes') }}
        </option>
        <option
          v-for="route in routes"
          :key="route.id"
          :value="route.id"
        >
          &#8645; {{ generateRouteName(route) }}
        </option>
      </select>
    </div>
  </header>

  <nav class="tab navigation">
    <ul>
      <li
        v-for="day in days"
        :key="day.id"
      >
        <a
          :class="{ active: day.active}"
          href="#"
          @click.prevent="getTrips(day)"
        >
          {{ daysNames[day.index] }}
        </a>
      </li>
    </ul>
  </nav>

  <table
    v-if="dayTrips.length > 0"
    class="cards schedule table"
  >
    <thead>
      <tr>
        <th>{{ $t('route') }}</th>
        <th>{{ $t('departure') }}</th>
        <th>{{ $t('stops') }}</th>
        <th class="sr-only">
          {{ $t('actions') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="trip in dayTrips"
        :key="trip.id"
      >
        <td data-label="Rota">
          <span class="h4">{{ getOrigin(trip) }}</span>
          <i class="arrow" />
          <span class="h4">{{ getDestiny(trip) }}</span>
        </td>
        <td data-label="Partida">
          <time>{{ getDepartureTime(trip.departure) }}</time>
        </td>
        <td data-label="Paragens">
          <p> {{ getStops(trip) }} </p>
        </td>
        <td
          v-if="trip.state === 'canceled'"
          :aria-label="$t('actions')"
          class="trip-row__actions"
        >
          <button
            :disabled="true"
            class="btn btn--sm btn--slate"
          >
            {{ $t('canceled') }}
          </button>
          <!-- Hack to make btn align  -->
          <drop-down style="visibility: hidden;">
            <button
              slot="dropdown-trigger"
              class="i-ellipsis"
              aria-label="More options"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </drop-down>
        </td>
        <td
          v-else-if="userGetBooking(trip) && ['confirmed','pending'].includes(userGetBooking(trip).state)"
          :aria-label="$t('actions')"
          class="trip-row__actions"
        >
          <template v-if="userGetBooking(trip).state === 'confirmed'">
            <button
              class="button btn--primary btn--sm"
              @click.prevent="showTicketModal(trip)"
            >
              {{ $t('show.ticket') }}
            </button>
            <drop-down :style="{ visibility: canCancel(trip) ? 'visible' : 'hidden' }">
              <button
                slot="dropdown-trigger"
                class="i-ellipsis"
                aria-label="More options"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <ul
                slot="dropdown-panel"
                class="dropdown-menu"
              >
                <li class="dropdown-menu__item">
                  <button
                    class="dropdown-menu__link"
                    data-qa="cancelTicket"
                    @click.prevent="showBookingCancellationModal(trip)"
                  >
                    <span class="u-text-danger">
                      {{ $t('cancel.booking') }}
                    </span>
                  </button>
                </li>
              </ul>
            </drop-down>
          </template>
          <template v-else-if="userGetBooking(trip).state === 'pending'">
            <button
              class="btn btn--orange btn--sm"
              @click.prevent="showPendingModal(trip)"
            >
              {{ $t('pending') }}
            </button>
            <drop-down>
              <button
                slot="dropdown-trigger"
                class="i-ellipsis"
                aria-label="More options"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <ul
                slot="dropdown-panel"
                class="dropdown-menu"
              >
                <li class="dropdown-menu__item">
                  <button
                    data-qa="cancelTicket"
                    class="dropdown-menu__link"
                    @click.prevent="showBookingCancellationModal(trip)"
                  >
                    <span class="u-text-danger">
                      {{ $t('cancel.booking') }}
                    </span>
                  </button>
                </li>
              </ul>
            </drop-down>
          </template>
          <template v-else-if="userGetBooking(trip).state === 'canceled'">
            <button
              class="btn btn--orange btn--sm"
            >
              {{ $t('canceled') }}
            </button>
            <!-- Hack to make btn align  -->
            <drop-down style="visibility: hidden;">
              <button
                slot="dropdown-trigger"
                class="i-ellipsis"
                aria-label="More options"
                aria-haspopup="true"
                aria-expanded="false"
              />
            </drop-down>
          </template>
        </td>
        <td
          v-else
          :aria-label="$t('actions')"
          class="trip-row__actions"
        >
          <button
            v-if="canBook(trip)"
            data-qa="Trip"
            class="btn--primary btn--outline btn--sm"
            @click.prevent="showBookingModal(trip)"
          >
            {{ $t('book.trip') }}
          </button>
          <!-- Hack to make btn align  -->
          <drop-down style="visibility: hidden;">
            <button
              slot="dropdown-trigger"
              class="i-ellipsis"
              aria-label="More options"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </drop-down>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    v-if="dayTrips.length === 0"
    class="empty-state sleeping-bus"
  >
    <div class="icon-wrap">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 77 54"
      >
        <g
          fill="none"
          fill-rule="evenodd"
        >
          <g
            stroke="#8F95A1"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            vector-effect="non-scaling-stroke"
            class="zs"
          >
            <path
              d="M32 19.8h-4l4-4.8h-4"
              vector-effect="non-scaling-stroke"
              class="z first"
            />
            <path
              d="M32 19.8h-4l4-4.8h-4"
              vector-effect="non-scaling-stroke"
              class="z second"
            />
            <path
              d="M32 19.8h-4l4-4.8h-4"
              vector-effect="non-scaling-stroke"
              class="z third"
            />
          </g>
          <g transform="translate(62 5)">
            <rect
              width="14"
              height="14"
              fill="#FFF"
              stroke="#8F95A1"
              stroke-width="2"
              rx="1"
            />
            <path
              fill="#8F95A1"
              d="M4 11V3h2.7826087C8.55900621 3 10 3.63803681 10 5.57668712c0 1.87730061-1.45341615 2.71165644-3.16770186 2.71165644H5.82608696V11H4zm1.82608696-4.14723926h.89440993c1.00621118 0 1.49068323-.4417178 1.49068323-1.27607362 0-.83435583-.54658385-1.1411043-1.54037267-1.1411043h-.84472049v2.41717792z"
            />
            <path
              stroke="#8F95A1"
              stroke-linecap="round"
              stroke-width="2"
              d="M7 44V14"
            />
            <path
              stroke="#8F95A1"
              stroke-linecap="round"
              stroke-width="2"
              d="M4 44h6"
            />
          </g>
          <g
            stroke="#8F95A1"
            class="bus"
          >
            <path
              fill="#FFF"
              stroke-width="2"
              d="M8 47v5.0018986C8 52.557758 8.44329156 53 8.9973917 53h5.0052166C14.5524523 53 15 52.5525401 15 52.0018986V47H8z"
            />
            <path
              fill="#FFF"
              stroke-width="2"
              d="M4 47h46V2.00138927C50 1.44769406 49.5521337 1 48.9986107 1H5.00138927C4.44769406 1 4 1.44786626 4 2.00138927V47z"
            />
            <path
              fill="#666"
              d="M5.5 30.5h43v1h-43z"
            />
            <path
              fill="#666"
              d="M5.5 8.5h43v1h-43z"
            />
            <path
              fill="#FFF"
              stroke-width="2"
              d="M39 47v5.0018986C39 52.557758 39.4432916 53 39.9973917 53h5.0052166C45.5524523 53 46 52.5525401 46 52.0018986V47h-7z"
            />
            <path
              fill="#666"
              d="M21.5 39.5h11v1.07843137h-11z"
              class="mouth"
            />
            <path
              stroke-width="2"
              d="M50 11v12h1.5c.8338694 0 1.5-.668687 1.5-1.5029194v-8.9941612C53 11.6734596 52.327589 11 51.5 11H50z"
            />
            <path
              stroke-width="2"
              d="M4 23V11H2.5c-.83386937 0-1.5.668687-1.5 1.5029194v8.9941612C1 22.3265404 1.67241097 23 2.5 23H4z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="bevel"
              stroke-width="2"
              d="M9 38c.33333333 2 1.3333333 3 3 3s2.6666667-1 3-3"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="bevel"
              stroke-width="2"
              d="M39 38c.3333333 2 1.3333333 3 3 3s2.6666667-1 3-3"
            />
          </g>
        </g>
      </svg>
    </div>
    <h1 class="title-3">
      {{ $t('no.trips.scheduled') }}
    </h1>
    <p>{{ $t('no.trips.scheduled.description') }}</p>
  </div>
  <!-- All possible modals -->
  <Modal v-model="cancelBookingOpen">
    <cancel-booking
      slot="modal-panel"
      v-model="cancelBookingOpen"
      :trip="selectedTrip"
      :user-bookings="userBookings"
      @submit="submitTripCancel"
    />
  </Modal>
  <Modal
    v-model="pendingBookingOpen"
  >
    <pending-booking
      slot="modal-panel"
      v-model="pendingBookingOpen"
    />
  </Modal>
  <Modal
    v-model="bookingModalOpen"
  >
    <booking
      slot="modal-panel"
      v-model="bookingModalOpen"
      :trip="selectedTrip"
      @submit="submitBookTrip"
    />
  </Modal>
  <Modal v-model="ticketModalOpen">
    <ticket
      slot="modal-panel"
      v-model="ticketModalOpen"
      :trip="selectedTrip"
    />
  </Modal>
  <Modal v-model="stateModalOpen">
    <Component
      :is="modalState.type"
      slot="modal-panel"
      v-model="stateModalOpen"
      :title="modalState.title"
      :body="modalState.body"
      :close="modalState.close"
    />
  </Modal>
</section>
