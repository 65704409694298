
<section v-if="sortedTrips">
  <div
    v-if="sortedTrips.length === 0"
    class="empty-state tickets-bounce"
  >
    <div class="icon-wrap">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38 60"
        width="38px"
        class="ticket-sec"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#8F95A1"
          stroke-width="2"
        >
          <g class="bottom">
            <path d="M37 20.8999819V59H1V20.8999819C3.28224032 20.4367116 5 18.4189579 5 16s-1.71775968-4.4367116-4-4.8999819V1h36v10.1000181c-2.2822403.4632703-4 2.481024-4 4.8999819s1.7177597 4.4367116 4 4.8999819z" />
            <path
              stroke-dasharray="4 2"
              d="M8 16h22"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 36.0167558l-5.8778525 4.0734141 2.0576908-6.8489241-5.69040346-4.3314157 7.14957536-.1594538L19 22l2.3609898 6.7503763 7.1495754.1594538-5.6904035 4.3314157 2.0576908 6.8489241z"
            />
            <path d="M8 51h22" />
            <path d="M8 46h22" />
          </g>
          <g class="top">
            <path d="M37 20.8999819V59H1V20.8999819C3.28224032 20.4367116 5 18.4189579 5 16s-1.71775968-4.4367116-4-4.8999819V1h36v10.1000181c-2.2822403.4632703-4 2.481024-4 4.8999819s1.7177597 4.4367116 4 4.8999819z" />
            <path
              stroke-dasharray="4 2"
              d="M8 16h22"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 36.0167558l-5.8778525 4.0734141 2.0576908-6.8489241-5.69040346-4.3314157 7.14957536-.1594538L19 22l2.3609898 6.7503763 7.1495754.1594538-5.6904035 4.3314157 2.0576908 6.8489241z"
            />
            <path d="M8 51h22" />
            <path d="M8 46h22" />
          </g>
        </g>
      </svg>
    </div>
    <h1 class="title-3">
      {{ $t('you.have.no.tickets') }}
    </h1>
    <p>{{ $t('you.have.no.tickets.description') }}</p>
  </div>
  <table
    v-if="sortedTrips.length > 0"
    class="l-table l-table--alternating-rows reservation-history"
  >
    <thead class="l-table__header">
      <tr class="l-table__row">
        <th class="l-table__columnheader">
          {{ $t('route') }}
        </th>
        <th class="l-table__columnheader">
          {{ $t('departure') }}
        </th>
        <th class="l-table__columnheader">
          {{ $t('stops') }}
        </th>
        <th class="l-table__columnheader">
          {{ $t('ticketState') }}
        </th>
      </tr>
    </thead>
    <tbody class="l-table__body">
      <tr
        v-for="trip in sortedTrips"
        :key="trip.ts"
        class="l-table__row"
      >
        <td
          class="l-table__cell"
          :data-label="$t('route')"
        >
          <span
            aria-hidden="true"
            class="l-table__columnheader--mobile"
          >{{ $t('route') }}</span>
          <span
            class="h4"
          >{{ getOrigin(trip) }} <i class="arrow" />  {{ getDestiny(trip) }}</span>
        </td>
        <td
          class="l-table__cell"
          :data-label="$t('departure')"
        >
          <span
            aria-hidden="true"
            class="l-table__columnheader--mobile"
          >{{ $t('departure') }}</span>
          <time>{{ getDepartureTime(trip.departure) }}</time>
        </td>
        <td
          class="l-table__cell"
          :data-label="$t('stops')"
        >
          <span
            aria-hidden="true"
            class="l-table__columnheader--mobile"
          >{{ $t('stops') }}</span>
          <span>
            {{ getStops(trip) }}
          </span>
        </td>
        <td
          class="l-table__cell"
          :data-label="$t('state')"
        >
          <span
            aria-hidden="true"
            class="l-table__columnheader--mobile"
          >{{ $t('ticketState') }}</span>
          <strong
            :class="{
              'u-text-green': trip.state === 'confirmed',
              'u-text-gray': trip.state === 'canceled',
              'u-text-danger': trip.state === 'declined'
            }"
          >
            {{ $t("ticket.states." + trip.state) }}
          </strong>
        </td>
      </tr>
    </tbody>
  </table>
  <paginator-bar
    v-if="paginationData.totalPages > 1"
    :pagination="paginationData"
    @paginate="getBookings"
  />
</section>
