
<div class="modal card">
  <div class="card-row">
    <div class="card-row__text success-modal">
      <i class="icon">
        <svg
          class="success-animation"
          width="90"
          height="102"
          viewBox="0 0 90 102"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            transform="translate(1 1)"
            stroke="#009DE0"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              class="success-animation-circle"
              stroke-width="10"
              cx="44"
              cy="50"
              r="27"
            />
            <path
              class="check"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M33 50.578l8.911 9.542L55 39"
            />
            <path
              class="lines"
              d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </i>
      <h1 class="success-modal__header">
        {{ title }}
      </h1>
      <p class="success-modal__message">
        {{ body }}
      </p>
      <div class="success-modal__actions">
        <button
          class="btn btn--primary"
          @click.prevent="isModalOpen = false"
        >
          {{ close }}
        </button>
      </div>
    </div>
  </div>
</div>
