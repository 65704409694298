<template>
  <div>
    <section>
      <h1>{{ $t('create.stop') }}</h1>
      <section>
        <label
          class="h4"
          for="station-name"
        >{{ $t('stop.name') }}</label>
        <p>{{ $t('stop.name.info') }}</p>
        <input
          id="station-name"
          v-model="stop.name"
          :placeholder="$t('stop.name')"
          type="text"
          class="modal-input"
          name="station-name"
          data-filled="false"
        >
      </section>
      <section>
        <label
          class="h4"
          for="stop-address"
        >
          {{ $t('stop.address.label') }}
        </label>
        <p>{{ $t('stop.address.info') }}</p>
        <input
          id="stop-address"
          v-model="stop.address"
          :placeholder="$t('stop.address.label')"
          class="modal-input"
          type="text"
        >
      </section>
      <section>
        <strong class="pre-header">{{ $t('stop.location') }}</strong>
        <p>{{ $t('stop.location.info') }}</p>
        <gmap-map
          :center="stop.location"
          :zoom="16"
          style="width: 600px; height: 300px;"
        >
          <gmap-marker
            :position="stop.location"
            :clickable="true"
            :draggable="true"
            @dragend="getMarkerPosition($event.latLng)"
          />
        </gmap-map>
      </section>
    </section>
    <div class="btn--group">
      <button
        class="btn"
        @click="isModalOpen = false"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="btn btn--primary"
        @click="$emit('submit', stop)"
      >
        {{ $t('create.stop') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateStop',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      stop: {
        name: '',
        location: {
          lat: 38.738009,
          lng: -9.137707
        }
      },
      open: false
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getMarkerPosition (event) {
      const location = { lat: event.lat(), lng: event.lng() }
      this.stop.location = location
    }
  },
  i18n: {
    messages: {
      pt: {
        stop: {
          address: {
            label: 'Endereço da paragem',
            info: 'O endereço completo da localização da paragem'
          }
        }
      },
      en: {
        stop: {
          address: {
            label: 'Address of the stop',
            info: 'The full address for this stop'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.vue-map-container {
  width: 100%;
}
</style>
